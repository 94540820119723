import '@ant-design/compatible/assets/index.css';

import { Button, Input, InputNumber, Modal, Radio, Select, message } from 'antd';

import { Form } from '@ant-design/compatible';
import React from 'react'
import { UserAddOutlined } from '@ant-design/icons';
import apiHandler from "../../../../api/api";
import { getNextFriday } from '../../../../helpers'
import * as dayjs from 'dayjs'

const { Option } = Select;

function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {

    validateEmail = (rule, value, callback) => {
      if (value && this.props.staff.some(s => s.email === value)) {
        callback('Email already exists');
      } else {
        callback();
      }
    }
    validatePhotoURL = (rule, value, callback) => {
      if (value && value !== '' && !isValidHttpUrl(value)) {
        callback('Please enter a valid URL');
      } else {
        callback();
      }
    }

    render() {
      const { visible, onCancel, onCreate, form, isAddingStaffMember } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          open={visible}
          title="Add Staff Member"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={isAddingStaffMember}
        >
          <Form layout="vertical">
            <Form.Item label="Firstname">
              {getFieldDecorator('firstname', {
                rules: [{ required: true, message: 'Please input the firstname' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Surname">
              {getFieldDecorator('surname', {
                rules: [{ required: true, message: 'Please input the surname' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  { 
                    required: true, 
                    message: 'Please input the email',
                  },
                  {
                    type: "email",
                    message: 'Please input a valid email',   
                  },
                  {
                    validator: this.validateEmail,
                  }
              ]})(<Input />)}
            </Form.Item>
            <Form.Item label="Role">
              {getFieldDecorator('role', {
                initialValue: "user"
              })(
                <Select>
                  <Option value="user">User</Option>
                  <Option value="supuser">Superuser</Option>
                  <Option value="jbuser">Job Book User</Option>
                  <Option value="aduser">Admin</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Entitlement">
              {getFieldDecorator('entitlement', {
                initialValue: 0
              })(
                  <InputNumber
                    min={0}
                    max={365}
                    style={{ width: "100%" }}
                    inputStyle="text-align: left"
                  />
              )}
            </Form.Item>
            <Form.Item label="Charge Out Rate">
              {getFieldDecorator('charge_out_rate', {
                initialValue: 0
              })(
                <InputNumber
                  min={0}
                  max={10000}
                  formatter={(value) =>
                    `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  style={{ width: "100%" }}
                  inputStyle="text-align: left"
                />
              )}
            </Form.Item>
            <Form.Item label="Mileage Rate">
              {getFieldDecorator('mileage_rate', {
                initialValue: 0
              })(
                <InputNumber
                  min={0}
                  max={10000}
                  formatter={(value) =>
                    `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  style={{ width: "100%" }}
                  inputStyle="text-align: left"
                />
              )}
            </Form.Item>
            <Form.Item label="Photo URL">
              {getFieldDecorator('photo_url', {
                rules: [
                  {
                    validator: this.validatePhotoURL,
                  }
              ],
              })(<Input type="textarea" />)}
            </Form.Item>
            <Form.Item label="Team Leader" className="collection-create-form_last-form-item">
              {getFieldDecorator('team_leader', {
                initialValue: 'no',
              })(
                <Radio.Group>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

class AddStaffMemberModal extends React.Component {
  state = {
    visible: false,
    isAddingStaffMember: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleAddStaffMember = () => {
    this.setState({ isAddingStaffMember: true });

    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        this.setState({
          isAddingStaffMember: false,
        });
        return;
      }
      const staffMemberToInsert = {
        firstname: values.firstname.trim(),
        surname: values.surname.trim(),
        email: values.email.trim(),
        role: values.role,
        entitlement: values.entitlement,
        charge_out_rate: values.charge_out_rate,
        mileage_rate: values.mileage_rate,
        photo_url:
          values.photo_url === undefined || values.photo_url.trim() === ""
          ? null
          : values.photo_url.trim(),
        team_leader: values.team_leader,
      };
      apiHandler.endpoints.staff
        .create({ staff_member: staffMemberToInsert })
        .then((result) => {
          this.formRef.props.form.resetFields();
          const staff_id = result.data[0]
          const prevWeekEnding = getNextFriday(dayjs()).subtract(1, 'weeks').isoWeekday(5)
          const weekEnding = dayjs(prevWeekEnding, "DD/MM/YYYY").format(
            "MMMM DD, YYYY H:mm"
          )
          const timesheet = {
            week_ending: weekEnding,
            staff_id: staff_id,
            time: [{
              date: weekEnding,
              week_ending: weekEnding,
              hours: 0.25,
              comment: "Blank Timesheet",
              staff_id: staff_id,
              row_id: 0,
              stage_id: 'OT',
              charge_out_rate: staffMemberToInsert.charge_out_rate,
              is_temp: false,
              submit_date: dayjs().format("MMMM DD, YYYY H:mm"),
            }]
          }
          apiHandler.endpoints.timerecords
            .create(timesheet)
            .then((res) => {
              this.setState(
                {
                  isAddingStaffMember: false,
                  visible: false,
                  staffMember: null,
                },
                () => {
                  message.success(`Staff member added successfully!`);
                  this.props.loadStaff();
                }
              );
            })
            .catch(err => console.log(err))
        })
        .catch((err) => {
          message.error(`Couldn't add staff member`);
          this.setState({
            isAddingStaffMember: false,
          });
        });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <div style={{ display: "inline", marginRight: 10 }}>
        <Button type="primary" icon={<UserAddOutlined />} onClick={this.showModal}>
          Add Staff Member
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleAddStaffMember}
          staff={this.props.staff}
          isAddingStaffMember={this.state.isAddingStaffMember}
        />
      </div>
    );
  }
}

export default AddStaffMemberModal