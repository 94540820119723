import {
  SAVE_RESOURCE_SHEET,
  RESTORE_ADMIN_STAFF_RESOURCE_SHEET,
  RESET_RESOURCE_SHEET
} from './ResourceSheetActionTypes';

// INSERT INTO planning (row_id, staff_id, job_no, category, project_lead, week_starting, days, stage_id, comment, is_temp, submit_date) VALUES
// (0, 'ID', '8443', 'Retail', 'ID', '2020-01-20T00:00:00Z', 0.5, '0-3', 'Some comment', true, '2020-04-06T00:00:00Z'),
// (1, 'ID', '9179', 'Healthcare', 'ID', '2020-01-20T00:00:00Z', 4, '10', null, true, '2020-04-06T00:00:00Z'),
// (1, 'ID', '9179', 'Healthcare', 'ID', '2020-01-27T00:00:00Z', 1.5, '4', null, true, '2020-04-06T00:00:00Z'),
// (2, 'ID', 'P6399', 'Ecclesiastical', 'ID', '2020-01-27T00:00:00Z', 2.5, '5', null, true, '2020-04-06T00:00:00Z'),
// (3, 'ID', '10181', 'Commercial', 'ID', '2020-02-03T00:00:00Z', 1.5, '6-7', null, true, '2020-04-06T00:00:00Z'),
// (4, 'ID', '9341', 'Commercial', 'ID', '2020-02-03T00:00:00Z', 1.5, '9', null, true, '2020-04-06T00:00:00Z')

// Save resource sheet locally to redux
export const saveResourceSheet = resourceSheet => dispatch => {
  return dispatch({
    type: SAVE_RESOURCE_SHEET,
    payload: resourceSheet
  });
}

// Restore a staff member's resource sheet on the admin page
export const restoreAdminStaffResourceSheet = resourceSheet => dispatch => {
  return dispatch({
    type: RESTORE_ADMIN_STAFF_RESOURCE_SHEET,
    payload: resourceSheet
  });
}

// Reset resource sheet on submission
export const resetResourceSheet = () => dispatch => {
  return dispatch({
    type: RESET_RESOURCE_SHEET
  });
}