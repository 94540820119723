import CAJobTag from 'components/CAJobTag';
import React from 'react';
import { Select } from 'antd';

const MAX_SUGGESTIONS = 50;

function isWordInString(word, string) {
  return string.toLowerCase().search(word.replace(/[^\w\s]/gi, '').toLowerCase());
}

// Teach Autosuggest how to calculate suggestions for any given input value.
function getSuggestions(value, suggestions) {
  let words = value.trim().toLowerCase().split(" ");
  let count = 0;

  let jobsMatched = [];
  suggestions.forEach(job => {
    if (count === MAX_SUGGESTIONS) { return };
    let numMatches = 0;
    for (let word of words) {
      if (isWordInString(word, job.title) >= 0) {
        numMatches++
      }
    }
    if (numMatches === words.length) {
      jobsMatched.push(job);
      count++;
    }
  })
  return jobsMatched;
}

class CAAutoComplete extends React.Component {
  state = {
    result: [],
    value: ''
  }

  handleSearch = (value, suggestions) => {
    let result = getSuggestions(value, suggestions);
    let jobOptions;
    if (value !== '') {
      jobOptions = result.map(job => (this.formatJobOption(job)))
    }
    this.setState({ result, value, jobOptions });
  }

  formatJobOption = (job) => {
    return job && job.title ? {
      value: job.title,
      label: (
        <div>
          <CAJobTag 
            job_no={job.title.substr(0, job.title.indexOf(" "))}
            agreed_fee={job.agreed_fee} 
          />
          <span>
            {job.title.substr(job.title.indexOf(' ')+1)}
          </span>
        </div>
      )
    } : {}
  }

  render() {
    const { options, onSelect, selectedJob, allowClear, ...rest } = this.props;
    const selectedJobFormatted = this.formatJobOption(selectedJob);
  
    return (
      <Select
        value={selectedJobFormatted}
        options={this.state.jobOptions}
        allowClear={allowClear}
        showSearch={true}
        showArrow={false}
        onSearch={(value) => this.handleSearch(value, options)}
        notFoundContent={this.state.value !== '' && "Job Not Found"}
        {...rest}
      />
    );
  }
}

CAAutoComplete.defaultProps = {
  allowClear: true
}

export default CAAutoComplete;
