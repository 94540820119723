export const RESTORE_TIMESHEET_PENDING = 'RESTORE_TIMESHEET_PENDING';
export const RESTORE_TIMESHEET_SUCCESS = 'RESTORE_TIMESHEET_SUCCESS';
export const RESTORE_TIMESHEET_FAILED = 'RESTORE_TIMESHEET_FAILED';

export const SAVE_TIMESHEET_PENDING = 'SAVE_TIMESHEET_PENDING';
export const SAVE_TIMESHEET_SUCCESS = 'SAVE_TIMESHEET_SUCCESS';
export const SAVE_TIMESHEET_FAILED = 'SAVE_TIMESHEET_FAILED';

export const SAVE_TIME = 'SAVE_TIME';
export const SAVE_EXPENSES = 'SAVE_EXPENSES';

export const SUBMIT_TIMESHEET_PENDING = 'SUBMIT_TIMESHEET_PENDING';
export const SUBMIT_TIMESHEET_SUCCESS = 'SUBMIT_TIMESHEET_SUCCESS';
export const SUBMIT_TIMESHEET_FAILED = 'SUBMIT_TIMESHEET_FAILED';

export const RESTORE_ADMIN_STAFF_TIMESHEET = 'RESTORE_ADMIN_STAFF_TIMESHEET';
export const RESET_TIMESHEET = 'RESET_TIMESHEET';
