import {
  SAVE_RESOURCE_SHEET,
  RESTORE_RESOURCE_SHEET_PENDING,
  RESTORE_RESOURCE_SHEET_SUCCESS,
  RESTORE_RESOURCE_SHEET_FAILED
} from 'actions/ResourceSheetActionTypes';

import {
  INITIAL_RESOURCE_SHEET
} from '../constants';

const resourceSheetReducer = (state=INITIAL_RESOURCE_SHEET, action={}) => {
  switch(action.type) {
    case SAVE_RESOURCE_SHEET:
      return { ...state, resourceSheet: action.payload }
    case RESTORE_RESOURCE_SHEET_PENDING:
      return { ...state }
    case RESTORE_RESOURCE_SHEET_SUCCESS:
      return { ...state, resourceSheet: action.payload }
    case RESTORE_RESOURCE_SHEET_FAILED:
      return { ...state, error: action.payload }
    default:
      return state;
  }
}

export default resourceSheetReducer
