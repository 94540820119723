import "./StaffTable.css";

import { Button, Input, Modal, Table, message, Space, Dropdown } from "antd";
import {
    ExclamationCircleOutlined,
    SearchOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { Form, InputNumber, Popconfirm, Typography } from "antd";
import { useState } from "react";

import RatesTable from "./RatesTable";

import CAAvatar from "components/CAAvatar";
import React from "react";
import apiHandler from "../../../../api/api";
import { userCanViewCORates, getNextFriday } from "../../../../helpers";
import { withRouter } from "react-router-dom";

const { confirm } = Modal;

class StaffTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            isUpdatingRate: false,
        };
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
            />
        ),
        onFilter: (value, record) => {
            return `${record[dataIndex].firstname} ${record[dataIndex].surname}`
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    updateStaffActive = (record) => {
        const { staff_id, firstname, surname } = record.person;
        const active = this.props.active;
        const loadStaff = this.props.loadStaff;
        const status = active ? "leaver" : "active";
        const note = active
            ? "They will be moved to the leavers list, but no timerecords will be removed."
            : "They will be moved back to the staff list.";
        confirm({
            icon: <ExclamationCircleOutlined />,
            title: `Mark as ${status.toUpperCase()}`,
            content: (
                <div>
                    Are you sure you want to mark{" "}
                    <strong>
                        {firstname} {surname}
                    </strong>{" "}
                    as {status}? {note}
                </div>
            ),
            onOk() {
                apiHandler.endpoints.staff
                    .update({
                        staff_id: staff_id,
                        active: !active,
                    })
                    .then((_) => {
                        loadStaff();
                    })
                    .catch((err) => {
                        message.error(`Couldn't update ${firstname}`);
                    });
            },
        });
    };

    sendEmailReminder = (record) => {
        let person = record.person;
        var email = person.email;
        var subject = "CA-TMS: Timesheet Reminder";
        let startWeek = getNextFriday().subtract(person.weeksBehind, "weeks");

        let weeksBehind = "";
        for (var i = 0; i < person.weeksBehind; i++) {
            let curWeek = startWeek.clone();
            weeksBehind +=
                "- Week Ending " + curWeek.add(i, "week").format("DD/MM/YYYY");

            if (i === person.weeksBehind - 1) {
                weeksBehind += "\n";
            } else {
                weeksBehind += ",\n";
            }
        }

        let weekStr = person.weeksBehind > 1 ? "weeks" : "week";

        var emailBody = encodeURIComponent(
            `Dear ${person.firstname},\n\nYou are ${person.weeksBehind} ${weekStr} behind on timesheets:\n\n`
        );
        emailBody += encodeURIComponent(weeksBehind);
        emailBody += encodeURIComponent("\nRegards,\n\nAccounts");

        document.location =
            "mailto:" + email + "?subject=" + subject + "&body=" + emailBody;
    };

    handleEmployeeClick = (staff_id) => {
        const pathname = this.props.location.pathname;
        this.props.history.push(pathname + "/" + staff_id, {
            staff_id: staff_id,
        });
    };

    handleAddRates = (records) => {
        this.setState({ isUpdatingRate: true });
        apiHandler.endpoints.rates
            .create({
                rates: records,
            })
            .then((res) => {
                this.props.loadStaff();
                this.setState({ isUpdatingRate: false });
            })
            .catch((err) => {
                this.setState({ isUpdatingRate: false });
            });
    };

    handleDeleteRate = (record) => {
        this.setState({ isUpdatingRate: true });
        apiHandler.endpoints.rates
            .delete(record)
            .then((res) => {
                this.props.loadStaff();
                this.setState({ isUpdatingRate: false });
            })
            .catch((err) => {
                this.setState({ isUpdatingRate: false });
            });
    };

    render() {
        const { staff, loading, authUser, ...rest } = this.props;
        const canViewCORates = userCanViewCORates(authUser)
          
        const columns = [
            {
                title: "Name",
                dataIndex: "person",
                key: "person",
                width: 225,
                ...this.getColumnSearchProps("person"),
                defaultSortOrder: "ascend",
                sorter: (a, b) => {
                    return a.person.surname[0] > b.person.surname[0];
                },
                render: (person) => {
                    return (
                        person !== undefined && (
                            <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1, marginLeft: 10 }}>
                                    <p
                                        className="clickable-link grow"
                                        onClick={() =>
                                            this.handleEmployeeClick(
                                                person.staff_id
                                            )
                                        }
                                        style={{ margin: 0 }}
                                    >
                                        <CAAvatar
                                            avatarSrc={person.photo}
                                            initials={`${person.firstname[0]}${person.surname[0]}`}
                                            size={46}
                                        />
                                    </p>
                                </div>
                                <div style={{ width: 137, marginTop: 2.5 }}>
                                    <p
                                        className="clickable-link"
                                        onClick={() =>
                                            this.handleEmployeeClick(
                                                person.staff_id
                                            )
                                        }
                                        style={{ margin: 0 }}
                                    >
                                        {`${person.firstname} ${person.surname}`}
                                    </p>
                                    <p
                                        style={{
                                            padding: 0,
                                            margin: 0,
                                            position: "relative",
                                            bottom: 2,
                                            color: "#a1aab2",
                                        }}
                                    >
                                        {person.description}
                                    </p>
                                </div>
                            </div>
                        )
                    );
                },
            },
            {
                title: "Timesheets",
                dataIndex: "timesheets",
                key: "timesheets",
                sorter: (a, b) => {
                    return a.person.weeksBehind - b.person.weeksBehind;
                },
                children: [
                    {
                        title: "Status",
                        dataIndex: "status",
                        key: "status",
                        width: 225,
                        render: (text) => {
                            return (
                                <div style={{ textAlign: "center" }}>
                                    {text}
                                </div>
                            );
                        },
                    },
                    {
                        title: "Recent",
                        dataIndex: "recent",
                        key: "recent",
                        width: 225,
                        render: (text) => {
                            return (
                                <div style={{ textAlign: "center" }}>
                                    {text}
                                </div>
                            );
                        },
                    },
                ],
            },
            ...(canViewCORates ? [{
                title: "Rate",
                dataIndex: "rate",
                key: "rate",
                width: 100,
                render: (text) => {
                    if (!text) {
                        return "";
                    }
                    return `£${Number(text).toFixed(2)}`;
                },
            }] : []),
            {
                title: "Team Leader",
                dataIndex: "teamLeader",
                key: "teamLeader",
                width: 225,
                sorter: (a, b) => {
                    return a.teamLeader - b.teamLeader;
                },
                render: (team_leader) => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {team_leader ? "Yes" : "No"}
                        </div>
                    );
                },
            },
            {
                title: "Actions",
                key: "actions",
                render: (text, record) => (
                    <>
                        {this.props.canMarkLeavers && (
                            <span style={{ marginLeft: 10 }}>
                                <button
                                    className="clickable-link"
                                    onClick={() =>
                                        this.updateStaffActive(record)
                                    }
                                >
                                    Mark as{" "}
                                    {this.props.active ? "leaver" : "active"}
                                </button>
                            </span>
                        )}
                        <span style={{ marginLeft: 20 }}>
                            <button
                                className="clickable-link"
                                onClick={() => this.sendEmailReminder(record)}
                            >
                                Send timesheet reminder
                            </button>
                        </span>
                    </>
                ),
            },
        ]

        const expandedRowRender = (record) => {
            return (
                <RatesTable
                    record={record}
                    loading={this.state.isUpdatingRate}
                    handleAdd={this.handleAddRates}
                    handleDelete={this.handleDeleteRate}
                />
            );
        };

        return (
            <Table
                bordered
                columns={columns}
                dataSource={staff}
                pagination={false}
                size={"middle"}
                loading={loading && { tip: "Loading staff..." }}
                expandable={canViewCORates && {
                    expandedRowRender,
                }}
                {...rest}
            />
        );
    }
}

export default withRouter(StaffTable);
