export const RESTORE_RESOURCE_SHEET_PENDING = 'RESTORE_RESOURCE_SHEET_PENDING';
export const RESTORE_RESOURCE_SHEET_SUCCESS = 'RESTORE_RESOURCE_SHEET_SUCCESS';
export const RESTORE_RESOURCE_SHEET_FAILED = 'RESTORE_RESOURCE_SHEET_FAILED';

export const SAVE_RESOURCE_SHEET_PENDING = 'SAVE_RESOURCE_SHEET_PENDING';
export const SAVE_RESOURCE_SHEET_SUCCESS = 'SAVE_RESOURCE_SHEET_SUCCESS';
export const SAVE_RESOURCE_SHEET_FAILED = 'SAVE_RESOURCE_SHEET_FAILED';

export const SAVE_RESOURCE_SHEET = 'SAVE_RESOURCE_SHEET';

export const SUBMIT_RESOURCE_SHEET_PENDING = 'SUBMIT_RESOURCE_SHEET_PENDING';
export const SUBMIT_RESOURCE_SHEET_SUCCESS = 'SUBMIT_RESOURCE_SHEET_SUCCESS';
export const SUBMIT_RESOURCE_SHEET_FAILED = 'SUBMIT_RESOURCE_SHEET_FAILED';

export const RESTORE_ADMIN_STAFF_RESOURCE_SHEET = 'RESTORE_ADMIN_STAFF_RESOURCE_SHEET';
export const RESET_RESOURCE_SHEET = 'RESET_RESOURCE_SHEET';
