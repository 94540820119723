import {
  REQUEST_STAGES_PENDING,
  REQUEST_STAGES_SUCCESS,
  REQUEST_STAGES_FAILED
} from 'actions/StagesActionTypes';

const INITIAL_STAGES_STATE = {
  stages: [],
  error: ''
}

const stagesReducer = (state=INITIAL_STAGES_STATE, action={}) => {
  switch(action.type) {
    case REQUEST_STAGES_PENDING:
      return { ...state }
    case REQUEST_STAGES_SUCCESS:
      return { ...state, stages: action.payload }
    case REQUEST_STAGES_FAILED:
      return { ...state, error: action.payload }
    default:
      return state;
  }
}

export default stagesReducer
