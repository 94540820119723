import React from "react";
import { Row, Col, DatePicker, Space, Table, Select, Card, Spin } from "antd";
import "./Dashboard.css";
import HoursTable from "../JobBook/Job/HoursTable";
import CAEmpty from "components/CAEmpty";
import { isEmpty, formatJobs } from "helpers";
import CAAutoComplete from "components/CAAutoComplete";

import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "components/Session/withAuthorization";
import apiHandler from "../../api/api";
import { ROUTES } from "../../constants";
import { isDirector } from "../../constants/roles";
import { Redirect } from "react-router-dom";

import CAJobTag from "components/CAJobTag";
import CABarChart from "../../components/CABarChart";
import CAPieChart from "../../components/CAPieChart";

import dayjs from "dayjs";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { Column } = Table;

const dayOffsets = [7, 14, 30, 90, 365];
const defaultDayOffset = dayOffsets[1];
const rangePresets = dayOffsets.map((offset) => ({
    label: `Last ${offset} Days`,
    value: [dayjs().add(-offset, "d"), dayjs()],
}));

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topJobsChartData: [],
            loadingTopJobs: false,
            range: defaultDayOffset,
            feesOnly: undefined,
            loading: false,
            jobHours: [],
            selectedJob: null,
        };
    }

    onRangeChange = (dates, dateStrings) => {
        const daysDiff = dates[1].diff(dates[0], "day");
        this.setState({ range: daysDiff }, () =>
            this.handleRequestTopJobs(this.state.feesOnly)
        );
    };

    handleRequestTopJobs = (feesOnly = undefined) => {
        this.setState({ loadingTopJobs: true });
        apiHandler.endpoints.topJobs
            .getAll({
                range: this.state.range,
                fees_only: feesOnly,
            })
            .then((res) => {
                const topJobsChartData = res.data.map((job) => {
                    const combined = `${job.job_no} ${job.title}`;
                    return {
                        x: combined,
                        y: parseFloat(job.totalhours).toFixed(2),
                        jobNo: job.job_no,
                        title: job.title,
                        hasFee: job.totalfee !== null,
                    };
                });
                this.setState({
                    loadingTopJobs: false,
                    topJobsChartData: topJobsChartData,
                });
            })
            .catch((err) => console.log(err));
    };

    componentDidMount = () => {
        this.handleRequestTopJobs();
    };

    handleRowClick = ({ jobNo, title, totalHours }) => {
        this.props.history.push(ROUTES.PROJECTS + "/" + jobNo, {
            job_no: jobNo,
            title: title,
            total_hours: totalHours,
        });
    };

    handleFeeSelectChange = (value) => {
        let feesOnly;
        switch (value) {
            case "fee":
                feesOnly = 1;
                break;
            case "no_fee":
                feesOnly = 0;
                break;
            default:
                feesOnly = null;
        }
        this.setState({ feesOnly }, () => this.handleRequestTopJobs(feesOnly));
    };

    handleJobSelect = (jobTitle) => {
        this.setState({ loading: true });
        if (jobTitle === undefined) {
            this.setState({
                selectedJob: null,
                loading: false,
                jobHours: [],
            });
            return;
        }
        const jobNo = jobTitle.split(" ")[0];
        const filteredJob = this.props.jobOptions.filter(
            (opt) => opt.title === jobTitle
        );
        const job = {
            title: jobTitle,
            agreed_fee:
                filteredJob.length && filteredJob[0].agreed_fee ? true : false,
        };
        apiHandler.endpoints.job
            .getOne({ id: jobNo })
            .then((res) => {
                const jobHours = res.data.hours;
                this.setState({
                    jobHours: jobHours,
                    selectedJob: job,
                    loading: false,
                });
            })
            .catch((err) => this.setState({ loading: false }));
    };

    render() {
        if (!isDirector(this.props.authUser)) {
            return <Redirect exact from="/" to={ROUTES.TIMESHEETS} />;
        }
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="gutter-box-dash">
                            <Card title="Top Projects" bordered={false}>
                                <Space direction="vertical">
                                    <Space wrap>
                                        <RangePicker
                                            allowClear={false}
                                            presets={rangePresets}
                                            onChange={this.onRangeChange}
                                            format={"DD/MM/YYYY"}
                                            defaultValue={rangePresets[1].value}
                                        />
                                        <Select
                                            defaultValue="All"
                                            style={{ width: 125 }}
                                            onChange={
                                                this.handleFeeSelectChange
                                            }
                                            options={[
                                                {
                                                    value: "all",
                                                    label: "All",
                                                },
                                                {
                                                    value: "fee",
                                                    label: "With Fees",
                                                },
                                                {
                                                    value: "no_fee",
                                                    label: "Without Fees",
                                                },
                                            ]}
                                        />
                                    </Space>
                                </Space>
                                <Spin spinning={!this.state.topJobsChartData.length}>
                                    <CABarChart
                                        chartData={this.state.topJobsChartData}
                                        history={this.props.history}
                                    />
                                </Spin>
                            </Card>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="gutter-box-dash">
                            <Card title="Project Totals" bordered={false}>
                                <Row gutter={24}>
                                    <Col xs={24}>
                                        <CAAutoComplete
                                            className="job-entry"
                                            size={"large"}
                                            options={this.props.jobOptions}
                                            allowClear={false}
                                            placeholder={"Search for project"}
                                            selectedJob={this.state.selectedJob}
                                            onSelect={(val) =>
                                                this.handleJobSelect(val)
                                            }
                                            onChange={(val) =>
                                                this.handleJobSelect(val)
                                            }
                                            dropdownStyle={{ fontSize: 13.5 }}
                                            style={{
                                                width: "100%",
                                                fontSize: 12,
                                                marginBottom: 16,
                                            }}
                                        />
                                        {this.state.jobHours.length === 0 ? (
                                            <Spin
                                                tip="Loading"
                                                size="small"
                                                spinning={this.state.loading}
                                            >
                                                <CAEmpty text="No time found for this job" />
                                            </Spin>
                                        ) : (
                                            <HoursTable
                                                rows={this.state.jobHours}
                                                loading={this.state.loading}
                                                scrollY={400}
                                            />
                                        )}
                                    </Col>
                                </Row>
                                {/* <Col xs={{ span: 12 }} lg={{ span: 10 }}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={{ span: 16 }} lg={{ span: 24 }}>
                                            <div className="gutter-box-dash">
                                                <Card title="Sector Analysis" bordered={false}>
                                                    <CAPieChart />
                                                </Card>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 16 }} lg={{ span: 24 }}>
                                            <div className="gutter-box-dash">
                                                <Card title="Sector Analysis" bordered={false}>
                                                    <CAPieChart />
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col> */}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authUser: state.session.authUser,
    sectors: state.sectors.sectors,
    jobOptions: formatJobs(state.jobs.jobs),
});

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
    connect(mapStateToProps, null)
)(Dashboard);
