import "./Employee.css";

import * as ResourceSheetActionCreators from "actions/ResourceSheetActionCreator";
import * as StaffActionCreators from "actions/StaffActionCreator";
import * as TimesheetActionCreators from "actions/TimesheetActionCreator";
import * as dayjs from "dayjs";

import {
    Button,
    DatePicker,
    Descriptions,
    Spin,
    Tabs,
    message,
    Table,
} from "antd";
import {
    ClockCircleOutlined,
    PoundOutlined,
    HomeOutlined,
    LeftOutlined,
    MailOutlined,
    RightOutlined,
    UploadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {
    formatJobs,
    formatStages,
    formatTimerecords,
    getNextFriday,
} from "../../../../helpers";

import CAAvatar from "components/CAAvatar";
import CAExpensesTable from "containers/Timesheets/CAExpensesTable";
import CATimeTable from "containers/Timesheets/CATimeTable";
import { DATE_FORMAT } from "../../../../constants";
import { MutedText } from "../../../../theme/globalStyle";
import React from "react";
import apiHandler from "../../../../api/api";
import { compose } from "recompose";
import { connect } from "react-redux";
import { requestTimesheetData } from "actions";
import withAuthorization from "components/Session/withAuthorization";
import { withFirebase } from "../../../../components/Firebase";
import { withRouter } from "react-router";

const ButtonGroup = Button.Group;
const TabPane = Tabs.TabPane;

dayjs.locale("en");

class Employee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: null,
            loading: false,
            updatingTimesheet: false,
            weekEnding: getNextFriday(),
            resetPasswordLoading: false,
        };
    }

    componentDidMount() {
        let { staff_id } = this.props.location.state;

        if (
            staff_id !== undefined &&
            this.props.staff.length &&
            this.props.staff.filter((s) => s.staff_id === staff_id).length === 0
        ) {
            this.props.history.push("/404");
            return;
        } else {
            if (!this.props.staff.length) {
                this.props.getStaff();
            }
            this.getTimerecordsByWeek(getNextFriday());
        }
    }

    // Obtain records based on week ending
    getTimerecordsByWeek = (weekEnding) => {
        this.setState({
            loading: true,
        });

        let { staff_id } = this.props.location.state;
        let id =
            staff_id + "/" + dayjs(weekEnding).format("MMMM DD, YYYY H:mm");

        weekEnding = dayjs(weekEnding).format(DATE_FORMAT);

        apiHandler.endpoints.staff.getOne({ id: staff_id }).then((employee) => {
            apiHandler.endpoints.timerecordsByStaff
                .getOne({ id: id })
                .then((timerecords) => {
                    apiHandler.endpoints.expensesByStaff
                        .getOne({ id: id })
                        .then((expenses) => {
                            const jobs = this.props.jobs;
                            let restoredTimesheet = {
                                time: formatTimerecords(
                                    timerecords.data,
                                    weekEnding,
                                    jobs
                                ),
                                expenses: expenses.data,
                            };
                            this.props.restoreAdminStaffTimesheet(
                                restoredTimesheet
                            );

                            this.setState({
                                employee: employee.data,
                                loading: false,
                            });
                        })
                        .catch((err) => console.log(err));
                });
        });
    };

    onWeekEndingChange = (date, _) => {
        let weekEnding = date.startOf("week").add(6, "day");
        this.setState(
            {
                weekEnding: dayjs(weekEnding),
            },
            () => {
                this.getTimerecordsByWeek(weekEnding);
            }
        );
    };

    handleWeekChange = (value) => {
        let selectedWeek = this.state.weekEnding
            ? dayjs(this.state.weekEnding)
            : dayjs(this.props.weekEnding);
        let newWeekEnding;

        if (value < 0) {
            newWeekEnding = selectedWeek.subtract(1, "week");
        } else if (value > 0) {
            newWeekEnding = selectedWeek.add(1, "week");
        } else {
            if (
                dayjs(selectedWeek).format(DATE_FORMAT) ===
                dayjs(this.props.weekEnding).format(DATE_FORMAT)
            ) {
                return;
            }
            newWeekEnding = dayjs(this.props.weekEnding);
        }
        this.setState(
            {
                weekEnding: newWeekEnding,
            },
            () => {
                this.getTimerecordsByWeek(newWeekEnding);
            }
        );
    };

    handleSaveTime = (time) => {
        this.props.saveTime(time);
    };

    handleSaveExpenses = (expenses) => {
        this.props.saveExpenses(expenses);
    };

    validateTimesheet = (timesheet) => {
        let filteredTimesheet = timesheet;
        filteredTimesheet.time = timesheet.time.filter((rec) => rec.tot !== 0);
        filteredTimesheet.expenses = timesheet.expenses.filter(
            (rec) => rec.job !== undefined || rec.code !== undefined
        );
        return filteredTimesheet;
    };

    handleUpdateTimesheet = () => {
        window.scrollTo(0, 0);

        this.setState({
            updatingTimesheet: true,
        });
        let { weekEnding } = this.state;
        let { adminStaffTimesheet, submitTimesheet } = this.props;

        const staff_id = this.props.match.params.staff_id;

        adminStaffTimesheet.weekEnding = weekEnding;
        adminStaffTimesheet.user = {
            staff_id: staff_id,
        };

        adminStaffTimesheet = this.validateTimesheet(adminStaffTimesheet);

        submitTimesheet(adminStaffTimesheet)
            .then((_) => {
                this.setState({ updatingTimesheet: false }, () => {
                    message.destroy();
                    message.success("Timesheet updated");
                    return true;
                });
            })
            .catch((err) => {
                message.error(`Couldn't update timesheet`);
                this.setState({
                    updatingTimesheet: false,
                });
                return false;
            });
    };

    handleSendPasswordResetEmail = (employee) => {
        const email = employee.email;
        this.setState({ resetPasswordLoading: true });
        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                message.success(`Password reset email sent`);
                this.setState({ resetPasswordLoading: false });
            })
            .catch((error) => {
                message.error(`Couldn't send password reset email`);
                this.setState({ resetPasswordLoading: false });
            });
    };

    handleTabChange = (key) => {
        // const staff_id = this.props.match.params.staff_id;
        // switch (key) {
        //   case "Holidays":
        //     this.props.getHolidays(staff_id)
        //       .then(res => console.log(res))
        //     break
        //   default:
        //     break;
        // }
    };

    render() {
        const {
            employee,
            loading,
            weekEnding,
            updatingTimesheet,
            resetPasswordLoading,
        } = this.state;

        let { adminStaffTimesheet, authUser } = this.props;

        let mailLink = employee && `mailto:${employee.email}`;

        let photo = employee && employee.photo;
        let initials = employee
            ? `${employee.firstname[0]}${employee.surname[0]}`
            : "";

        let selectedWeeksBehind = getNextFriday(dayjs().startOf("day"))
            .startOf("day")
            .diff(dayjs(weekEnding).startOf("day"), "week");

        let canEditTimesheet =
            authUser.role.toUpperCase() === "ADUSER" ||
            authUser.role.toUpperCase() === "JBUSER";

        let operations = canEditTimesheet &&
            adminStaffTimesheet &&
            (adminStaffTimesheet.time.length ||
                adminStaffTimesheet.expenses.length) && (
                <span>
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={this.handleUpdateTimesheet}
                    >
                        Update Timesheet
                    </Button>
                </span>
            );

        return (
            <Spin spinning={updatingTimesheet} tip="Updating timesheet...">
                <div
                    style={{
                        backgroundColor: "white",
                        marginBottom: 130,
                        padding: 20,
                        boxShadow:
                            "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                    }}
                >
                    <Tabs
                        onChange={(key) => this.handleTabChange(key)}
                        animated={false}
                    >
                        <TabPane
                            tab={
                                <span>
                                    <ClockCircleOutlined />
                                    Timesheets
                                </span>
                            }
                            key="Timesheets"
                        >
                            <div
                                className="user-details"
                                style={{
                                    marginTop: 25,
                                    marginLeft: 10,
                                    display: "flex",
                                }}
                            >
                                <div className="user-left">
                                    <CAAvatar
                                        initials={initials}
                                        avatarSrc={photo}
                                        size={58}
                                    />
                                </div>
                                <div
                                    className="user-right"
                                    style={{ marginLeft: 14 }}
                                >
                                    {employee && (
                                        <>
                                            <h2
                                                style={{
                                                    margin: 0,
                                                    display: "inline",
                                                }}
                                            >
                                                {`${employee.firstname} ${employee.surname}`}
                                                , Week Ending:
                                                <DatePicker
                                                    picker="week"
                                                    format={DATE_FORMAT}
                                                    onChange={
                                                        this.onWeekEndingChange
                                                    }
                                                    value={
                                                        weekEnding ===
                                                            undefined ||
                                                        weekEnding === null
                                                            ? null
                                                            : dayjs(weekEnding)
                                                    }
                                                    placeholder="Select week"
                                                    allowClear={false}
                                                    style={{
                                                        marginLeft: 10,
                                                        width: 150,
                                                        position: "relative",
                                                        bottom: 1,
                                                    }}
                                                />
                                            </h2>
                                            <ButtonGroup
                                                style={{
                                                    marginLeft: 10,
                                                    position: "relative",
                                                    bottom: 1,
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<LeftOutlined />}
                                                    onClick={() =>
                                                        this.handleWeekChange(
                                                            -1
                                                        )
                                                    }
                                                    style={{
                                                        width: 34,
                                                        height: 28,
                                                    }}
                                                />
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<RightOutlined />}
                                                    onClick={() =>
                                                        this.handleWeekChange(1)
                                                    }
                                                    style={{
                                                        width: 34,
                                                        height: 28,
                                                    }}
                                                />
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    icon={<HomeOutlined />}
                                                    onClick={() =>
                                                        this.handleWeekChange(0)
                                                    }
                                                    style={{
                                                        width: 34,
                                                        height: 28,
                                                    }}
                                                />
                                            </ButtonGroup>
                                            <MutedText
                                                style={{
                                                    fontSize: "1.1em",
                                                    marginLeft: 12,
                                                    display: "inline",
                                                }}
                                            >
                                                {selectedWeeksBehind >= 0 &&
                                                    (selectedWeeksBehind === 0
                                                        ? "This Week"
                                                        : selectedWeeksBehind ===
                                                          1
                                                        ? "Last Week"
                                                        : `${selectedWeeksBehind} weeks ago`)}
                                            </MutedText>
                                            <MutedText
                                                style={{
                                                    position: "relative",
                                                    bottom: 1,
                                                    marginBottom: 12,
                                                }}
                                            >
                                                CAA.QA-133 (AD6)
                                            </MutedText>
                                        </>
                                    )}
                                </div>
                            </div>
                            <Spin spinning={loading}>
                                <Tabs
                                    tabBarExtraContent={operations}
                                    animated={false}
                                    style={{ padding: "0px 12px" }}
                                >
                                    <TabPane
                                        tab={
                                            <span>
                                                <ClockCircleOutlined />
                                                Time
                                            </span>
                                        }
                                        key="1"
                                    >
                                        <CATimeTable
                                            editable={canEditTimesheet}
                                            jobOptions={this.props.jobOptions}
                                            stages={this.props.formattedStages}
                                            timesheet={adminStaffTimesheet}
                                            saveTime={this.handleSaveTime}
                                            week={weekEnding}
                                        />
                                    </TabPane>
                                    <TabPane
                                        tab={
                                            <span>
                                                <PoundOutlined />
                                                Expenses
                                            </span>
                                        }
                                        key="2"
                                    >
                                        <CAExpensesTable
                                            editable={canEditTimesheet}
                                            jobOptions={this.props.jobOptions}
                                            stages={this.props.formattedStages}
                                            timesheet={adminStaffTimesheet}
                                            saveExpenses={
                                                this.handleSaveExpenses
                                            }
                                            week={weekEnding}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Spin>
                        </TabPane>
                        <TabPane
                            tab={
                                <span>
                                    <UserOutlined />
                                    Profile
                                </span>
                            }
                            key="Profile"
                        >
                            {employee && (
                                <>
                                    <Descriptions
                                        bordered
                                        border
                                        size="small"
                                        column={1}
                                    >
                                        <Descriptions.Item label="Name">
                                            {employee.firstname}{" "}
                                            {employee.surname}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Email">
                                            <a href={mailLink}>
                                                {employee.email}
                                            </a>{" "}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Entitlement">
                                            {employee.entitlement} days
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Role">
                                            {employee.description} (
                                            {employee.role})
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Team Leader">
                                            {employee.team_leader
                                                ? "Yes"
                                                : "No"}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Mileage Rate">
                                            £
                                            {Number(
                                                employee.mileage_rate
                                            ).toFixed(2)}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="User ID">
                                            {employee.staff_id}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    {canEditTimesheet && (
                                        <Button
                                            loading={resetPasswordLoading}
                                            onClick={() =>
                                                this.handleSendPasswordResetEmail(
                                                    employee
                                                )
                                            }
                                            style={{ marginTop: 16 }}
                                        >
                                            <MailOutlined />
                                            Send password reset email
                                        </Button>
                                    )}
                                </>
                            )}
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
        );
    }
}

Employee.defaultProps = {
    weekEnding: dayjs().day(5),
};

const mapStateToProps = (state) => ({
    authUser: state.session.authUser,
    formattedStages: formatStages(state.stages.stages),
    jobs: state.jobs.jobs,
    staff: state.staff.staff,
    jobOptions: formatJobs(state.jobs.jobs),
    adminStaffTimesheet: state.timesheet.adminStaffTimesheet,
    adminStaffResourceSheet: state.resourceSheet.adminStaffResourceSheet,
});

const mapDispatchToProps = {
    saveTime: TimesheetActionCreators.saveTime,
    saveExpenses: TimesheetActionCreators.saveExpenses,
    restoreAdminStaffTimesheet:
        TimesheetActionCreators.restoreAdminStaffTimesheet,
    restoreAdminStaffResourceSheet:
        ResourceSheetActionCreators.restoreAdminStaffResourceSheet,
    submitTimesheet: TimesheetActionCreators.submitTimesheet,
    requestTimesheetData: requestTimesheetData,
    getStaff: StaffActionCreators.getStaff,
};

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirebase(withRouter(Employee)));
