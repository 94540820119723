import "animate.css/animate.min.css";
import "./CALanding.css";

import * as ROUTES from "../../constants/routes";

import { Avatar, Button, Col, Divider, Row } from "antd";

import CAAvatar from "../CAAvatar";
import CALandingStakeholder from "../CALandingStakeholder";
import CATyper from "../CATyper";
import { EditOutlined } from '@ant-design/icons';
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { SocialIcon } from "react-social-icons";
import arch from "../../assets/arch.png";
import caLogo from "../../assets/ca-logo.png";
import feesExample from "../../assets/fees-example.png";
import logo from "../../assets/logo-light.png";
import scrollToComponent from "react-scroll-to-component";
import timesheetExample from "../../assets/timesheet-example.png";

class CALanding extends React.Component {
  toSignIn = () => {
    this.props.history.push(ROUTES.SIGN_IN);
  };
  render() {
    return (
      <div className="landing">
        <Button
          onClick={this.toSignIn}
          type="primary"
          ghost
          className="sign-in-btn-landing"
        >
          Sign In
        </Button>
        <section className="intro">
          <div className="intro-inner">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce={true}
              offset={200}
            >
              <img src={logo} className="logo-landing" alt="C+A" />
            </ScrollAnimation>
            <h1 style={{ fontSize: 32 }}>
              Cassidy and Ashton Time Management System
            </h1>
            <CATyper text={"Automating the entry of timesheets"} />
          </div>
          <a
            href="#about"
            onClick={() =>
              scrollToComponent(this.aboutProject, {
                offset: 0,
                align: "top",
                duration: 750,
              })
            }
          >
            <span></span>
          </a>
        </section>
        <section
          className="about-project"
          ref={(section) => {
            this.aboutProject = section;
          }}
        >
          <div className="about-project-inner">
            <div className="max-wrapper">
              <Row>
                <Col xs={24} lg={7}>
                  <a href="https://www.cassidyashton.co.uk">
                    <img src={caLogo} className="logo-about" alt="C+A" />
                  </a>
                </Col>
                <Col xs={24} lg={17}>
                  <p className="about-project-desc">
                    This project was developed for Cassidy and Ashton, a
                    multi-disciplinary and award-winning practice of Chartered
                    Architects, Chartered Building Surveyors, Town Planners and
                    Construction Advisers with over 50+ people.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        <section className="features">
          <Row className="feature-row">
            <Col lg={24} xl={10} className="feature-left">
              <div className="inner">
                <h1>Timesheets</h1>
                <p style={{ fontWeight: 600, fontSize: 22 }}>
                  Weekly timesheets made easy
                </p>
                <p>
                  Typing the job description or job number will search a
                  database of over 10,000+ jobs.
                </p>
                <p>
                  Users can select an{" "}
                  <a href="https://www.architecture.com/-/media/gathercontent/riba-plan-of-work/additional-documents/ribaplanofwork2013overviewfinalpdf.pdf">
                    RIBA
                  </a>{" "}
                  stage or non-chargeable code for each row.
                </p>
                <p>Weekly expenses can also be completed.</p>
                <p>Administrators can view and update submitted timesheets.</p>
              </div>
            </Col>
            <Col lg={24} xl={14} className="feature-right">
              <div className="feature-right-inner">
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={0.8}
                  animateOnce={true}
                  offset={50}
                >
                  <img
                    src={timesheetExample}
                    className="image"
                    alt="Timesheet"
                  />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
          <hr className="feature-divider" />
          <Row>
            <Col lg={24} xl={10} className="feature-left">
              <div className="inner">
                <h1>Job Dashboards</h1>
                <p style={{ fontWeight: 600, fontSize: 22 }}>
                  Enhancing business intelligence
                </p>
                <p>
                  Once data has been collected for a job, directors can view the
                  progress of the job over time against an agreed fee.
                </p>
                <p>
                  Directors also have access to a breakdown of job progress
                  according to fees for each RIBA stage.
                </p>
              </div>
            </Col>
            <Col lg={24} xl={14} className="feature-right">
              <div className="feature-right-inner">
                <ScrollAnimation
                  animateIn="fadeInRight"
                  duration={0.8}
                  animateOnce={true}
                  offset={50}
                >
                  <img src={feesExample} className="image" alt="Fees" />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </section>
        <section className="stakeholders">
          <h1 style={{ marginBottom: 30 }}>Project Stakeholders</h1>
          <div className="stakeholders-wrapper">
            <Row className="stakeholder-row">
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="David Parkinson"
                  jobRole="Project Manager"
                  src="https://www.pngkey.com/png/detail/230-2301779_best-classified-apps-default-user-profile.png"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Alistair Baines"
                  jobRole="Chairman"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Alistair-Baines-1024x684.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Chris Taylor"
                  jobRole="Director"
                  src="https://vault.ecloud.co.uk/cassidy/./Chris-Taylor.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Lawrence McBurney"
                  jobRole="Director"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Lawrence-McBurney-1024x684.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Alban Cassidy"
                  jobRole="Director"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Alban-Cassidy-1024x684.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Peter Harris"
                  jobRole="Director"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Peter-Harris-1024x684.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Phil Gornall"
                  jobRole="Director"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Phil-Gornall-1024x684.jpg"
                />
              </Col>
              <Col xs={24} sm={12} md={8} xl={6} className="stakeholder">
                <CALandingStakeholder
                  name="Carl Myerscough"
                  jobRole="Associate"
                  src="https://vault.ecloud.co.uk/cassidy/2019/10/Carl-Myerscough-1024x684.jpg"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="testimonials">
          <h1>Testimonials</h1>
          <div className="testimonial-inner">
            <Row gutter={24}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: 40 }}>
                <div style={{ margin: "0px auto 16px", width: 100 }}>
                  <CAAvatar
                    avatarSrc={
                      "https://vault.ecloud.co.uk/cassidy/2019/10/Lawrence-McBurney-1024x684.jpg"
                    }
                    size={100}
                    className="grow"
                  />
                </div>
                <p style={{ maxWidth: 600, margin: "20px auto" }}>
                  "Since the new time management system has been up and running
                  not only have I been able to produce my own weekly time sheets
                  &amp; expenses much easier but I have been able to assess
                  staff time and book costs against fee levels for individual
                  jobs. As a Director this is invaluable in helping to run an
                  efficient business, particularly as an architectural practice.
                  This automated process whilst helping solve a problem also
                  adds to our environmental credentials with a paperless system
                  now in place. A very professional tool that we can easily
                  evolve over time as the business needs change."
                </p>
                <h2 style={{ fontWeight: 600 }}>Lawrence McBurney</h2>
                <p className="role">Director, Architect</p>
              </Col>
              <Divider>
                <Avatar icon={<EditOutlined />} />
              </Divider>
              <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ padding: 40 }}>
                <div style={{ margin: "0px auto 16px", width: 100 }}>
                  <CAAvatar
                    avatarSrc={
                      "https://vault.ecloud.co.uk/cassidy/2019/10/Carl-Myerscough-1024x684.jpg"
                    }
                    size={100}
                    className="grow"
                  />
                </div>
                <p style={{ maxWidth: 600, margin: "20px auto" }}>
                  "The TMS system installed here at C+A has automated a
                  procedure that previously relied upon searching elsewhere for
                  job numbers, photocopying numerous copies, wasted paper, and
                  took a long time to complete. Harry listened to our comments
                  on the initial version and then made further improvements to
                  create a system that is clear, reliable, quick and user
                  friendly. Harry’s software saves paper and time and makes it
                  far easier for the staff to record their time spent on each
                  project."
                </p>
                <h2 style={{ fontWeight: 600 }}>Carl Myerscough</h2>
                <p className="role">
                  Associate, Senior Architectural Technologist
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section className="architecture">
          <div className="header-arch">
            <h1 style={{ marginBottom: 12 }}>System Architecture</h1>
            <p>
              A full-stack web application deployed on{" "}
              <a href="https://www.heroku.com/">Heroku</a>
            </p>
          </div>
          <div style={{ margin: "0 auto" }}>
            <img src={arch} alt="Architecture Diagram" />
          </div>
        </section>
        <section className="about-me">
          <h1>About the Developer</h1>
          <div className="about-me-inner">
            <div className="about-me-left">
              <img src="https://avatars.githubusercontent.com/u/11751531?v=4" alt="about-developer" />
            </div>
            <div className="about-me-right">
              <div className="about-me-right-inner">
                <div
                  className="about-me-right-left-inner"
                  style={{ width: "30%", float: "left" }}
                >
                  <h2 style={{ fontSize: 24, fontWeight: 400 }}>
                    Harry Baines
                  </h2>
                </div>
                <div
                  className="about-me-right-right-inner"
                  style={{ width: "30%", float: "right" }}
                >
                  <SocialIcon
                    className="social-icon"
                    url="https://www.linkedin.com/in/harry-baines-400609137"
                    style={{ height: 35, width: 35 }}
                  />
                  <SocialIcon
                    className="social-icon"
                    url="https://www.github.com/harrybaines"
                    style={{ height: 35, width: 35 }}
                  />
                  <SocialIcon
                    className="social-icon"
                    url="mailto:harryb0905@googlemail.com"
                    style={{ height: 35, width: 35 }}
                  />
                  <SocialIcon
                    className="social-icon"
                    url="http://twitter.com/harryb0905"
                    style={{ height: 35, width: 35 }}
                  />
                </div>
              </div>
              <div style={{ clear: "both" }}>
                <p>
                  Harry is a Data Scientist with an interest
                  in machine learning and backend software engineering. He has completed an
                  undergraduate degree in Computer Science at Lancaster
                  achieving a first class degree and a masters in Data Science achieving
                  distinction. He has completed several online AI, machine learning and
                  software development courses.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="footer">
          <p>Copyright &copy; 2018-2023 Harry Baines</p>
          <p style={{ margin: 0, fontSize: 12, marginTop: 5 }}>
            Fonts by{" "}
            <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
          </p>
        </section>
      </div>
    );
  }
}

export default CALanding;
