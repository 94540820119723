import "./CATimeTable.css";

import {
  DATE_FORMAT,
  DAYS,
  STAGE_CODE_CATEGORIES,
  TIMESHEET_TIME_COLS,
} from "../../../constants";
import { InputNumber, Tag, message } from "antd";
import { calculateRowTotals, getNextId, roundQuarter } from "../../../helpers";

import CAAutoComplete from "components/CAAutoComplete";
import CACommentModal from "components/CACommentModal";
import CAEmpty from "components/CAEmpty";
import CAJobTag from "components/CAJobTag";
import CASelect from "components/CASelect";
import { PlusOutlined } from '@ant-design/icons';
import React from "react";
import { connect } from "react-redux";
import dayjs from 'dayjs';

class CATimeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOpen: false,
      jobId: 0,
    };
  }

  handleAdd = () => {
    let newTime = this.props.timesheet.time;
    let newId = getNextId(newTime);
    newTime.push({
      id: newId,
      tot: 0.0,
    });
    this.props.saveTime(newTime);
  };

  handleDelete = (row) => {
    const records = [...this.props.timesheet.time];
    if (records.length > 1) {
      let newTime = records.filter((item, rowId) => rowId !== row);
      this.props.saveTime(newTime);
    }
  };

  onEditJob = (row, val) => {
    let records = this.props.timesheet.time;
    if (val === undefined) {
      records[row] = {
        id: records[row].id,
        tot: 0,
      };
    } else {
      let job = val.props.value;
      records[row].job = job;
    }
    this.props.saveTime(records);
  };

  onEditCode = (row, val) => {
    let records = this.props.timesheet.time;
    if (val === undefined) {
      records[row] = {
        id: records[row].id,
        tot: 0,
      };
    } else {
      records[row].stage = val.value;
    }
    // Delete job key associated with possible non-chargeable code
    if (!this.isChargeableCode(records[row].stage)) {
      delete records[row].job;
    }
    this.props.saveTime(records);
  };

  onEditHours = (row, day, val) => {
    let records = this.props.timesheet.time;
    let record = records[row];
    let canEnterHours =
      (record.job !== undefined && this.isChargeableCode(record.stage)) ||
      (record.stage !== undefined && !this.isChargeableCode(record.stage));

    if (!canEnterHours) {
      message.error("Can't enter hours without job and stage or code");
      return;
    }

    if (val !== undefined && !isNaN(val)) {
      let dayText = TIMESHEET_TIME_COLS[day].toLowerCase();
      let updatedHours = roundQuarter(Number(val));
      records[row][dayText] = updatedHours;

      if (Number(this.calculateDailyTotal(day)) > 24) {
        delete records[row][dayText];
        return;
      }

      calculateRowTotals(records, this.props.saveTime);

      if (Number(updatedHours) === 0.0) {
        delete records[row][dayText];
      }
    } else {
      calculateRowTotals(records, this.props.saveTime);
    }
  };

  onSaveComment = (row, val) => {
    let records = this.props.timesheet.time;
    records[row].comment = val;
    this.props.saveTime(records);
  };

  calculateDailyTotal = (day) => {
    let records = this.props.timesheet.time;
    let dayText = TIMESHEET_TIME_COLS[day].toLowerCase();
    let dailyTotal = 0;
    records.forEach((record) => {
      let value = record[dayText];
      if (value !== undefined) {
        dailyTotal += Number(value);
      }
    });
    return Number(dailyTotal).toFixed(2);
  };

  calculateWeeklyTotal = () => {
    let records = this.props.timesheet.time;
    let weeklyTotal = 0;
    records.forEach((record, i) => {
      let tot = Number(record.tot);
      if (tot !== undefined) {
        weeklyTotal += tot;
      }
    });
    return Number(weeklyTotal).toFixed(2);
  };

  renderTableHeader() {
    const { week } = this.props;

    return TIMESHEET_TIME_COLS.map((key, i) => {
      let width = i >= 2 ? 80 : i === 0 ? 580 : 260;
      let leftAlign = i === 0;

      width = i >= TIMESHEET_TIME_COLS.length - 2 ? 40 : width;

      let dailyTotal = this.calculateDailyTotal(i);
      let weeklyTotal = this.calculateWeeklyTotal();

      let ind = DAYS.indexOf(key.toLowerCase());
      let dateNum =
        ind >= 1
          ? dayjs(week, DATE_FORMAT)
              .subtract(5 - ind, "day")
              .format("Do")
          : null;

      return (
        <th
          key={i}
          style={{
            width: width,
            maxWidth: width,
            textAlign: leftAlign && "left",
            paddingLeft: leftAlign ? 18 : 0,
            marginRight: 10
          }}
        >
          <span
            style={{
              fontSize: i >= 2 && 12.5,
              fontWeight: i >= 2 && 400,
            }}
          >
            {key} {dateNum}
          </span>
          <br />
          {i >= 2 && i < TIMESHEET_TIME_COLS.length - 2 && dailyTotal}
          {i === TIMESHEET_TIME_COLS.length - 2 && weeklyTotal}
        </th>
      );
    });
  }

  isChargeableCode = (stage) => {
    return this.props.stageOptions.filter(
      (s) => `${s.stage_id} ${s.description}` === stage && s.chargeable
    ).length;
  };

  isJobDisabled = (job) => {
    return job.stage !== undefined && !this.isChargeableCode(job.stage);
  }

  renderTableData = () => {
    const { timesheet, editable } = this.props;
    let { jobOpen, jobId } = this.state;

    return timesheet.time.map((record, index) => {
      const { id } = record;
      let weekdays = TIMESHEET_TIME_COLS.map((col, i) => {
        let value = record[col.toLowerCase()];
        if (i > 1 && i < TIMESHEET_TIME_COLS.length - 2) {
          return (
            <td
              key={i}
              style={{
                borderRight: i === 7 && "1px solid rgb(180,180,180)",
                width: 85,
                opacity: jobOpen && jobId !== id ? 0.2 : 1,
                textAlign: "center",
                backgroundColor: i === 2 && "rgb(245,245,245)",
                padding: 1,
              }}
            >
              {editable ? (
                <InputNumber
                  size="large"
                  min={0}
                  max={18}
                  step={0.25}
                  precision={2}
                  value={value}
                  onChange={(val) => this.onEditHours(index, i, val)}
                  autoComplete="new-password"
                  style={{
                    width: "100%",
                    borderRadius: 0,
                    border: "none",
                    backgroundColor: i === 2 && "rgb(245,245,245)",
                    fontSize: 13,
                  }}
                />
              ) : (
                <span style={{ fontSize: 13 }}>
                  {value !== undefined && Number(value).toFixed(2)}
                </span>
              )}
            </td>
          );
        }
        return null;
      });
        
      const filteredJob = this.props.jobOptions.filter((opt) => opt.title === record.job);
      let jobDisabled = this.isJobDisabled(record);

      let feeExistsForRecord = null;
      if (filteredJob.length) {
        feeExistsForRecord = filteredJob[0].agreed_fee ? true : false;
      }

      let formattedJob = record !== undefined && record.job !== undefined && (
        <div style={{ 
          cursor: editable && "pointer", 
          fontSize: 13, 
          padding: 11,
          position: 'absolute',
          top: 0,
          left: 0,
          width: 580
        }}>
          <CAJobTag
            job_no={record.job.substr(0, record.job.indexOf(" "))}
            agreed_fee={feeExistsForRecord}
          />
          <span>{record.job.substr(record.job.indexOf(" ") + 1)}</span>
        </div>
      );

      let job = {
        title: record.job,
        agreed_fee: feeExistsForRecord
      }

      return (
        <tr key={id} style={{ userSelect: "none" }}>
          <td
            style={{
              width: 580,
              maxWidth: 580,
              backgroundColor: jobDisabled && "rgb(245,245,245)",
              textOverflow: !editable && "ellipsis",
              whiteSpace: !editable && "nowrap",
              overflow: !editable && "hidden",
              position: 'relative',
            }}
          >
            {editable ? (
              <CAAutoComplete
                className="job-entry"
                size={"large"}
                options={this.props.jobOptions}
                onSelect={(_, val) => this.onEditJob(index, val)}
                disabled={jobDisabled}
                onBlur={() => this.setState({ jobOpen: false, jobId: id })}
                onFocus={() => this.setState({ jobOpen: true, jobId: id })}
                onChange={(id, val) => {
                  this.onEditJob(index, val);
                  this.setState({ jobOpen: false, jobId: id });
                }}
                onInputKeyDown={(e) =>
                  e.key === "Enter" &&
                  this.setState({ jobOpen: false, jobId: id })
                }
                selectedJob={record.job !== undefined ? job : null}
                dropdownStyle={{
                  fontSize: 13.5,
                }}
                style={{
                  width: '100%',
                  fontSize: 12,
                  zIndex: jobOpen && jobId === id && 1000,
                  opacity: jobOpen && jobId !== id ? 0.2 : 1,
                  transition: "all 0.075s ease-in-out",
                  boxShadow:
                    jobOpen &&
                    jobId === id &&
                    "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px",
                }}
              />
            ) : (
              <span style={{ paddingLeft: 10 }}>
                { formattedJob }
              </span>
            )}
          </td>
          <td
            style={{
              width: 265,
              maxWidth: 265,
              opacity: jobOpen && jobId !== id ? 0.2 : 1,
            }}
          >
            {editable ? (
              <CASelect
                options={this.props.stages}
                categories={
                  record.job !== undefined
                    ? [STAGE_CODE_CATEGORIES[0]]
                    : [STAGE_CODE_CATEGORIES[1]]
                }
                value={record.stage}
                onChange={(_, val) => this.onEditCode(index, val)}
                dropdownStyle={{ fontSize: 13 }}
                size={"large"}
                style={{ width: 279, fontSize: 13 }}
              />
            ) : (
              record.stage && (
                <span style={{ paddingLeft: 10 }}>
                  <Tag
                    color={
                      this.props.stages.filter(
                        (v) => v.label === record.stage
                      )[0].color
                    }
                    style={{
                      cursor: "pointer",
                      minWidth: 36,
                      textAlign: "center",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>
                      {record.stage.substr(0, record.stage.indexOf(" "))}{" "}
                    </span>
                  </Tag>
                  <span style={{ fontSize: 12 }}>
                    {record.stage.substr(record.stage.indexOf(" ") + 1)}
                  </span>
                </span>
              )
            )}
          </td>
          {weekdays}
          <td
            style={{
              width: 85,
              maxWidth: 85,
              textAlign: "center",
              fontWeight: "bold",
              opacity: jobOpen && jobId !== id ? 0.2 : 1,
            }}
          >
            {Number(record.tot).toFixed(2)}
          </td>
          <td
            style={{
              width: 40,
              maxWidth: 40,
              textAlign: "center",
              fontSize: ".9em",
              opacity: jobOpen && jobId !== id ? 0.2 : 1,
            }}
          >
            <CACommentModal
              editable={editable}
              row={index}
              onSaveComment={this.onSaveComment}
              comment={record.comment}
            />
          </td>
        </tr>
      );
    });
  };

  render() {
    const { timesheet, editable } = this.props;

    let isEmpty =
      timesheet === null || (timesheet !== null && timesheet.time === null)
        ? true
        : false;
    return (
      <div className="ca-table-container">
        {isEmpty ? (
          <CAEmpty text="No time found for this week" />
        ) : (
          <table className="editable-table">
            <tbody>
              <tr>
                {this.renderTableHeader()}
              </tr>
              {this.renderTableData()}
              {editable && (
                <tr key="add-row" className="add-row" onClick={this.handleAdd}>
                  <td
                    style={{
                      paddingLeft: 15,
                      borderLeft: "1px solid rgb(239,239,239)",
                    }}
                  >
                    <PlusOutlined />{" "}
                    <span style={{ paddingLeft: 5 }}>
                      New
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

CATimeTable.defaultProps = {
  timesheet: {
    time: [],
    expenses: [],
  },
};

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  stageOptions: state.stages.stages,
  weekEnding: state.weekEnding.weekEnding,
});

export default connect(mapStateToProps, null)(CATimeTable);
