import React, { useRef, useLayoutEffect } from "react";
import { Table, Typography } from "antd";
import dayjs from "dayjs";
import { withRouter } from "react-router-dom";
import './HoursTable.css';

function HoursTable({ rows, loading, scrollY=false }) {
    const tableRef = useRef(null);

    useLayoutEffect(() => {
        const tableContainer =
            tableRef.current.querySelector(".ant-table-body") || null;
        if (tableContainer) {
            tableContainer.scrollLeft =
                (tableContainer.scrollWidth - tableContainer.offsetWidth) + 300;
        }
    }, [rows]);

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 175,
            fixed: "left",
            defaultSortOrder: "ascend",
            sorter: (a, b) => {
                return a.surname[0] > b.surname[0];
            },
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
            fixed: "right",
            width: 100,
            align: "right",
            sorter: (a, b) => {
                return a.total - b.total;
            },
        },
    ];

    rows = rows ? rows : [];

    const monthCols = rows
        .map((row) => row.date_month)
        .reduce((acc, date) => {
            const monthYear = dayjs(date).locale("en");
            if (!acc.find((d) => d.isSame(monthYear, "month"))) {
                acc.push(monthYear);
            }
            return acc;
        }, [])
        .sort((a, b) => a.valueOf() - b.valueOf())
        .map((row) => {
            const key = row.format("MMM YY");
            return {
                title: key,
                dataIndex: key,
                key: key,
                width: 90,
                align: "right",
            };
        });

    columns.splice(1, 0, ...monthCols);

    let monthTotals = [];
    let overallTotal = 0;
    let tableRows = rows
        .filter(
            (staff, index, self) =>
                index === self.findIndex((s) => s.staff_id === staff.staff_id)
        )
        .map((row, i) => {
            const monthlyTotals = rows.reduce((acc, curr) => {
                if (curr.staff_id === row.staff_id) {
                    const date = dayjs(curr.date_month);
                    const monthYear = date
                        .locale("en")
                        .format("MMM YY");
                    const monthlyTotal = Number(curr.monthly_total);
                    acc[monthYear] = (monthlyTotal === 0) ? "" : monthlyTotal.toFixed(2);
                    monthTotals[monthYear] = (monthTotals[monthYear] || 0) + monthlyTotal;
                }
                return acc;
            }, {});
            overallTotal += Number(row.total_hours);
            return {
                ...row,
                key: i.toString(),
                name: `${row.firstname} ${row.surname}`,
                total: Number(row.total_hours).toFixed(2),
                ...monthlyTotals,
            };
        });

        
    let sortedMonthTotals = Object.entries(monthTotals)
        .sort((a, b) => dayjs(a[0], 'MMM YY').isBefore(dayjs(b[0], 'MMM YY')) ? -1 : 1)
        .map(([_, value]) => value.toFixed(2));

    sortedMonthTotals.push(overallTotal.toFixed(2));

    return (
        <Table
            ref={tableRef}
            loading={loading}
            columns={columns}
            dataSource={tableRows}
            size="small"
            style={{ width: "100%" }}
            scroll={{
                x: 200,
                y: scrollY
            }}
            pagination={false}
            sticky={true}
            summary={(pageData) => {
                let totalHours = 0;
                pageData.forEach(({ total }) => {
                    totalHours += Number(total);
                });
                return (
                    <Table.Summary fixed>
                        <Table.Summary.Row style={{ fontWeight: "bold" }}>
                            <Table.Summary.Cell index={0}>
                                Total
                            </Table.Summary.Cell>
                            {sortedMonthTotals.map((total, i) => (
                                <Table.Summary.Cell index={i+1}>
                                    {total}
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    </Table.Summary>
                );
            }}
        />
    );
}

export default withRouter(HoursTable);
