import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatFee } from "helpers";

class CAStageChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    height: 350,
                    type: "line",
                    id: "areachart-2",
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                grid: {
                    padding: {
                        right: 30,
                        left: 20,
                    },
                    row: {
                        colors: ["#f3f3f3", "transparent"],
                        opacity: 0.5,
                    },
                },
                markers: {
                    size: 3,
                },
                xaxis: {
                    type: "datetime",
                    title: {
                        text: "Time",
                    },
                },
            },
            series: [
                {
                    name: "Value",
                },
            ],
        };
    }

    render() {
        const { height, chartData } = this.props;
        let { options, series } = this.state;
        series[0].data = chartData.data;

        const yAnnots = this.props.chartData.yAnnots;

        let maxValue = chartData.maxValue;

        options = {
            ...options,
            ...{
                labels: chartData.labels,
                annotations: yAnnots && {
                    yaxis: yAnnots.map((annot) => {
                        return {
                            y: annot.value,
                            borderColor: annot.color,
                            label: {
                                borderWidth: 5,
                                borderColor: annot.color,
                                style: {
                                    color: "#fff",
                                    background: annot.color,
                                },
                                text: `${annot.label}: ${formatFee(
                                    annot.value
                                )}`,
                            },
                        };
                    }),
                },
                yaxis: {
                    max: maxValue,
                    tickAmount: 10,
                    min: 0,
                    labels: {
                      formatter: (val) => formatFee(Math.round(val)),
                    },
                },
            },
        };

        return (
            <div id="chart">
                <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    height={height}
                />
            </div>
        );
    }
}

CAStageChart.defaultProps = {
    yAnnotVal: 0,
};

export default CAStageChart;
