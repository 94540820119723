import API from './index.js'
import { BASE_URL } from '../constants';

const apiHandler = new API({ url: BASE_URL })

apiHandler.createEntity({ name: 'timerecords', path: 'timerecords' })
apiHandler.createEntity({ name: 'timerecordsByRange', path: 'timerecords/export' })
apiHandler.createEntity({ name: 'timerecordsByStaff', path: 'timerecords/staff' })
apiHandler.createEntity({ name: 'tempTimerecords', path: 'timerecords/temp' })
apiHandler.createEntity({ name: 'leaveDates', path: 'timerecords/leave' })
apiHandler.createEntity({ name: 'weekEndings', path: 'timerecords/weeks' })

apiHandler.createEntity({ name: 'expenses', path: 'expenses' })
apiHandler.createEntity({ name: 'expensesCodes', path: 'expenses/codes' })
apiHandler.createEntity({ name: 'expensesByStaff', path: 'expenses/staff' })
apiHandler.createEntity({ name: 'expensesByRange', path: 'expenses/export' })

apiHandler.createEntity({ name: 'job', path: 'jobs/job' })
apiHandler.createEntity({ name: 'jobs', path: 'jobs' })
apiHandler.createEntity({ name: 'topJobs', path: 'jobs/top' })
apiHandler.createEntity({ name: 'recentJobs', path: 'jobs/staff' })
apiHandler.createEntity({ name: 'staff', path: 'staff' })
apiHandler.createEntity({ name: 'rates', path: 'rates' })
apiHandler.createEntity({ name: 'projectLeads', path: 'staff/projectLeads' })

apiHandler.createEntity({ name: 'sectors', path: 'sectors' })
apiHandler.createEntity({ name: 'roles', path: 'roles' })
apiHandler.createEntity({ name: 'stages', path: 'stages' })
apiHandler.createEntity({ name: 'bookfigure', path: 'bookfigure' })
apiHandler.createEntity({ name: 'bookfigureStages', path: 'bookfigure/stages' })
apiHandler.createEntity({ name: 'fees', path: 'fees' })

export default apiHandler;
