import React, { useState } from "react";
import { Form, Input, Button, Typography, message, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import tmsLogo from "../../assets/logo-light.png";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ROUTES } from "../../constants";

const { Title } = Typography;

const INITIAL_STATE = {
  isLoading: false,
};

const CASignIn = ({ firebase, history }) => {
  const [resetPassword, setResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    if (resetPassword) {
      setIsLoading(true);
      firebase
        .doPasswordReset(values.email)
        .then(() => {
          notification.info({
            message: "Reset Email Sent",
            description: "Click the link in the email to reset your password.",
          });
          setIsLoading(false);
          setResetPassword(false);
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: error.toString(),
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      firebase
        .doSignInWithEmailAndPassword(values.email, values.password)
        .then(() => {
          setIsLoading(false);
          history.push(ROUTES.DASHBOARD);
        })
        .catch((error) => {
          notification.error({
            message: "Error logging in",
            description: error.toString(),
          });
          setIsLoading(false);
        });
    }
  };

  const handleResetPassword = () => {
    setResetPassword(true);
  };

  const handleBackToLogin = () => {
    setResetPassword(false);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Left Column - Login Form */}
      <div style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {resetPassword ? (
          <div style={{ width: "100%", maxWidth: 500, margin: "0 75px" }}>
            <Title level={2} style={{ marginBottom: 32 }}>
              Reset Password
            </Title>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 32 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  size="large"
                  loading={isLoading}
                >
                  Reset Password
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <a href="#" onClick={handleBackToLogin}>
                  Back to Login
                </a>
              </div>
            </Form>
          </div>
        ) : (
          <div style={{ width: "100%", maxWidth: 500, margin: "0 75px" }}>
            <Title level={2} style={{ marginBottom: 32 }}>
              Welcome Back
            </Title>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your password!" }]}
              >
                <Input.Password prefix={<LockOutlined />} placeholder="Password" size="large" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 32 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  size="large"
                  loading={isLoading}
                >
                  Log in
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                Forgot your password?{" "}
                <a href="#" onClick={handleResetPassword}>
                  Reset Password
                </a>
              </div>
            </Form>
          </div>
        )}
      </div>

      {/* Right Column - App Logo */}
      <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f5f5f5" }}>
        <img src={tmsLogo} className="logo-about" alt="C+A" style={{ width: 225 }} />
      </div>
    </div>
  );
};

export default compose(withRouter, withFirebase)(CASignIn);
