import apiHandler from '../api/api';
import {
  REQUEST_STAGES_PENDING,
  REQUEST_STAGES_SUCCESS,
  REQUEST_STAGES_FAILED
} from './StagesActionTypes';

export const getStages = () => dispatch => {
  dispatch({ type: REQUEST_STAGES_PENDING });
  return apiHandler.endpoints.stages.getAll()
    .then(stages => dispatch({ type: REQUEST_STAGES_SUCCESS, payload: stages.data }))
    .catch(error => dispatch({ type: REQUEST_STAGES_FAILED, payload: error }))
}
