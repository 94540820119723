import CAAvatar from '../CAAvatar';
import React from 'react';

const CALandingStakeholder = ({ name, jobRole, src }) => {
  return (
    <div>
      <div style={{ width: '33.333%', float: 'left' }}>
        <CAAvatar
          avatarSrc={src}
          size={100}
          className="grow"
        />
      </div>
      <div className="stakeholder-details" style={{ width: '66.666%', float: 'left', textAlign: 'left', paddingLeft: 80 }}>
        <h2>
          {name}
        </h2>
        <p style={{ margin: 0 }}>
          {jobRole}
        </p>
      </div>
    </div>
  )
}

export default CALandingStakeholder