import React from 'react';
import { Empty } from 'antd';

const CAEmpty = ({ text }) => (
  <Empty
    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
    imageStyle={{
      height: 80,
    }}
    style={{
      margin: '25px 0px'
    }}
    description={
      <span>
        {text}
      </span>
    }
  />
)

CAEmpty.defaultProps = {
  text: 'No data'
}

export default CAEmpty