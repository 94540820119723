import apiHandler from '../api/api';
import {
  REQUEST_EXPENSES_CODES_PENDING,
  REQUEST_EXPENSES_CODES_SUCCESS,
  REQUEST_EXPENSES_CODES_FAILED
} from './ExpensesActionTypes';

export const getExpensesCodes = () => dispatch => {
  dispatch({ type: REQUEST_EXPENSES_CODES_PENDING });
  return apiHandler.endpoints.expensesCodes.getAll()
    .then(codes => dispatch({ type: REQUEST_EXPENSES_CODES_SUCCESS, payload: codes.data }))
    .catch(error => dispatch({ type: REQUEST_EXPENSES_CODES_FAILED, payload: error }))
}
