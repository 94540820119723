import apiHandler from '../api/api';
import {
  REQUEST_RECENT_JOBS_PENDING,
  REQUEST_RECENT_JOBS_SUCCESS,
  REQUEST_RECENT_JOBS_FAILED
} from './RecentJobsActionTypes';

export const getRecentJobs = staff_id => dispatch => {
  dispatch({ type: REQUEST_RECENT_JOBS_PENDING });
  return apiHandler.endpoints.recentJobs.getOne({ id: staff_id })
    .then(recentJobs => dispatch({ type: REQUEST_RECENT_JOBS_SUCCESS, payload: recentJobs.data }))
    .catch(error => dispatch({ type: REQUEST_RECENT_JOBS_FAILED, payload: error }))
}
