import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    this.auth = getAuth(app);
  }

  doCreateUserWithEmailAndPassword = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
    } catch (err) {
      console.error(err);
    }
  }

  doSignInWithEmailAndPassword = async (email, password) => {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  doSignOut = () => signOut(this.auth);

  doPasswordReset = async email => {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (err) {
      console.error(err);
    }
  }
}

export default Firebase;