import apiHandler from 'api/api';
import {
  REQUEST_WEEK_ENDINGS_PENDING,
  REQUEST_WEEK_ENDINGS_SUCCESS,
  REQUEST_WEEK_ENDINGS_FAILED,
  ADD_WEEK_ENDING
} from './WeekEndingsActionTypes';
import dayjs from "dayjs";
import { restoreTimesheet } from './TimesheetActionCreator';
import { TIMESHEET_START_WEEK_ENDING } from '../constants';

// Get all unique week endings from db for date display
export const getWeekEndings = staff_id => dispatch => {
  dispatch({ type: REQUEST_WEEK_ENDINGS_PENDING });
  return apiHandler.endpoints.weekEndings.getOne({ id: staff_id })
    .then(weeks => dispatch({ type: REQUEST_WEEK_ENDINGS_SUCCESS, payload: weeks.data }))
    .catch(error => dispatch({ type: REQUEST_WEEK_ENDINGS_FAILED, payload: error }))
}

// For submitting timesheets
export const getNextWeekEnding = staff_id => dispatch => {
  return dispatch(getWeekEndings(staff_id))
    .then(weekEndings => {
      weekEndings = weekEndings.payload;
      let weekEnding

      if (weekEndings.length) {
        let sqlDate = weekEndings[weekEndings.length-1].week_ending;
        weekEnding = dayjs(new Date(sqlDate.replace(' ', 'T'))).add(1, 'week').format('DD/MM/YYYY');
      } else {
        weekEnding = dayjs(TIMESHEET_START_WEEK_ENDING).format('DD/MM/YYYY');
      }

      dispatch(addWeekEnding(weekEnding));
      dispatch(restoreTimesheet({
        staff_id: staff_id,
        week_ending: weekEnding
      }))

      return dispatch({ type: REQUEST_WEEK_ENDINGS_SUCCESS, payload: weekEndings })
    })
    .catch(error => dispatch({ type: REQUEST_WEEK_ENDINGS_FAILED, payload: error }))
}

export const addWeekEnding = weekEnding => ({
  type: ADD_WEEK_ENDING,
  payload: weekEnding
});
