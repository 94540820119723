import "./Job.css";

import * as JobsActionCreators from "actions/JobsActionCreator";
import * as ROLES from "../../../constants/roles";
import * as StaffActionCreators from "actions/StaffActionCreator";
import * as dayjs from 'dayjs'
import { DATE_FORMAT } from "../../../constants";
import {
    AreaChartOutlined,
    ClockCircleFilled,
    PoundOutlined,
    InfoCircleOutlined,
    PictureOutlined,
} from "@ant-design/icons";
import {
    Col,
    Descriptions,
    Divider,
    Row,
    Skeleton,
    Spin,
    Tabs,
    Tag,
} from "antd";
import { formatFee, isEmpty, sortStages, roundUpNearest10k } from "helpers";

import CAEditJobModal from "../CAEditJobModal";
import CAEditableFeesList from "./CAEditableFeesList";
import CAEmpty from "components/CAEmpty";
import CAJobTag from "components/CAJobTag";
import CALineChart from "./CALineChart";
import CAMap from "components/CAMap";
import CAStageChart from "./CAStageChart";
import { MutedText } from "theme/globalStyle";
import React from "react";
import _ from "underscore";
import apiHandler from "../../../api/api";
import cloneDeep from "lodash/cloneDeep";
import { compose } from "recompose";
import { connect } from "react-redux";
import withAuthorization from "components/Session/withAuthorization";
import { withRouter } from "react-router";
import { formatSector } from "../../../helpers";
import HoursTable from "./HoursTable";
import { CA_COLOR } from "../../../constants";

const { TabPane } = Tabs;

class Job extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            overallChartData: null,
            stageChartData: null,
            editJobModalVisible: false,
            coords: {
                lat: null,
                long: null,
            },
            job: {},
            jobHours: null,
            jobCopy: {},
            fees: [],
        };
    }

    componentDidMount() {
        this.props.getStaff().then((res) => {
            this.fetchJob();
        });
    }

    handleRequestJobs = () => {
        this.setState({
            loading: true,
        });
        this.props.getJobs().then((res) => {
            this.fetchJob();
            this.setState({
                loading: false,
                editJobModalVisible: false,
            });
        });
    };

    fetchJob = () => {
        this.setState({
            loading: true,
        });

        let job = this.props.location.state;
        let jobHours = [];

        if (job === undefined) {
            this.props.history.push("/404");
            return;
        }

        apiHandler.endpoints.job.getOne({ id: job.job_no }).then((res) => {
            job = res.data.job;
            const jobHours = res.data.hours.map((row) => {
                return {
                    ...row,
                    photo: this.props.staff.find(
                        (s) => s.staff_id === row.staff_id
                    ).photo,
                };
            });

            if (job.postcode) {
                fetch(`https://api.postcodes.io/postcodes/${job.postcode}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.result) {
                            this.setState({
                                coords: {
                                    lat: data.result.latitude,
                                    long: data.result.longitude,
                                },
                            });
                        }
                    });
            }

            // Calculate overall book figure
            apiHandler.endpoints.bookfigure
                .getOne({ id: job.job_no })
                .then((res) => {
                    let totals = res.data;
                    let y = 0;

                    const agreedFee = Number(job.agreed_fee);
                    let cumTotals = totals.map((d) => (y += Number(d.total)));
                    let labels = totals.map(
                        (tot) => `${dayjs(tot["date"]).format("DD MMMM YYYY")}`
                    );

                    let highest = cumTotals.slice(-1)[0];
                    let maxValue =
                        agreedFee === 0 || highest > agreedFee
                            ? roundUpNearest10k(highest)
                            : roundUpNearest10k(agreedFee);

                    const agreedFeeAnnot = {
                        label: "Agreed Fee",
                        value: agreedFee,
                        color: "rgb(56,158,13)",
                    };

                    this.setState({
                        overallChartData:
                            cumTotals.length && labels.length
                                ? {
                                      data: cumTotals,
                                      labels: labels,
                                      yAnnots: [
                                          agreedFee && agreedFeeAnnot,
                                          {
                                              label: "Spent",
                                              value:
                                                  cumTotals[
                                                      cumTotals.length - 1
                                                  ],
                                              color: CA_COLOR,
                                          },
                                      ],
                                      maxValue: maxValue,
                                  }
                                : null,
                        loading: false,
                        job: job,
                        jobHours: jobHours,
                    });
                })
                .catch((err) => {
                    this.setState({ loading: false });
                });

            this.setState({ jobHours: jobHours });
        });

        // Get fees per stage
        apiHandler.endpoints.fees
            .getOne({ id: job.job_no })
            .then((res) => {
                let fees = res.data;
                apiHandler.endpoints.bookfigureStages
                    .getOne({ id: job.job_no })
                    .then((res) => {
                        let stageTotals = res.data;
                        let stages = _.groupBy(stageTotals, "stage_id");
                        let result = [];

                        for (let [stage, row] of Object.entries(stages)) {
                            let y = 0;
                            let cumTotals = row.map(
                                (d) => (y += Number(d.total))
                            );
                            let labels = row.map(
                                (tot) =>
                                    `${dayjs(tot["date"]).format(
                                        "DD MMMM YYYY"
                                    )}`
                            );
                            let highest = cumTotals.slice(-1)[0];
                            let stageFeeVal = 0;
                            if (fees) {
                                const stageFee = fees.find(
                                    (row) => row.stage_id === stage
                                );
                                stageFeeVal =
                                    stageFee !== undefined
                                        ? Number(stageFee.fee)
                                        : 0;
                            }

                            let maxValue =
                                stageFeeVal === 0 || highest > stageFeeVal
                                    ? roundUpNearest10k(highest)
                                    : roundUpNearest10k(stageFeeVal);

                            const agreedFeeAnnot = {
                                label: "Agreed Fee",
                                value: stageFeeVal,
                                color: "rgb(56,158,13)",
                            };
                            const yAnnot = [
                                stageFeeVal && agreedFeeAnnot,
                                {
                                    label: "Spent",
                                    value: highest,
                                    color: CA_COLOR,
                                },
                            ];

                            result.push({
                                stage: stage,
                                data: cumTotals,
                                labels: labels,
                                maxValue: maxValue,
                                yAnnots: yAnnot,
                            });
                        }

                        result.sort((a, b) =>
                            a.stage > b.stage ? 1 : b.stage > a.stage ? -1 : 0
                        );

                        let feesCopy = {};
                        for (const record of fees) {
                            feesCopy[record.stage_id] = record.fee;
                        }

                        var agreed_fee = fees.reduce((prev, cur) => {
                            return prev + parseFloat(cur.fee);
                        }, 0);
                        job.agreed_fee = agreed_fee;

                        this.setState({
                            stageChartData: result.length ? result : null,
                            loading: false,
                            job,
                            jobCopy: cloneDeep(job),
                            fees,
                            feesCopy,
                            stageTotals,
                        });
                    })
                    .catch((err) => {
                        this.setState({ loading: false });
                    });
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    };

    handleEditJobModalCancel = () => {
        this.setState({
            editJobModalVisible: false,
        });
    };

    // handleTabClick = (key, e) => {
    //     if (key === "Hours" && !this.state.jobHours.length) {
    //         this.fetchJob();
    //     }
    // }

    render() {
        const {
            loading,
            stageChartData,
            overallChartData,
            coords,
            job,
            jobCopy,
            fees,
            feesCopy,
        } = this.state;
        let { authUser, stages, staff, sectors } = this.props;
        let chargeables = stages.filter((s) => s.chargeable);

        let sectorName = formatSector(sectors, job);

        const spent = overallChartData
            ? overallChartData.data[overallChartData.data.length - 1]
            : 0;

        return (
            <div
                style={{
                    backgroundColor: "white",
                    position: "relative",
                    padding: 20,
                    boxShadow:
                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }}
            >
                {authUser && authUser.can_edit_job_book && (
                    <CAEditJobModal
                        buttonTitle={"Edit Job"}
                        modalTitle={"Edit Job"}
                        okText={"Update"}
                        style={{
                            marginBottom: 16,
                            position: "absolute",
                            top: 20,
                            right: 20,
                            zIndex: 9999,
                        }}
                        staff={staff}
                        job={job}
                        fees={feesCopy}
                        sectors={sectors}
                        fetchJobs={this.handleRequestJobs}
                        isEditingJob={loading}
                        stages={chargeables}
                        authUser={authUser}
                    />
                )}
                <Tabs
                    animated={false}
                    onTabClick={this.handleTabClick}
                    defaultActiveKey={
                        this.props.location.state
                            ? this.props.location.state.activeTab
                            : "1"
                    }
                >
                    <TabPane
                        tab={
                            <span>
                                <InfoCircleOutlined />
                                Job Details
                            </span>
                        }
                        key="1"
                    >
                        <Skeleton active loading={isEmpty(job) || loading}>
                            <Row gutter={24}>
                                <Col xs={24}>
                                    <Descriptions
                                        bordered
                                        size={"small"}
                                        column={1}
                                    >
                                        <Descriptions.Item label="Job No">
                                            <CAJobTag
                                                job_no={job.job_no}
                                                agreed_fee={!isEmpty(fees)}
                                            />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Title">
                                            {job.title}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Location">
                                            {job.location}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Client">
                                            {job.client}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Sector">
                                            {sectorName}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Address">
                                            {job.address}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Postcode">
                                            {job.postcode}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Date Added">
                                            {dayjs(job.date_added).format(DATE_FORMAT)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </Skeleton>
                    </TabPane>
                    {ROLES.isDirector(this.props.authUser) && (
                        <TabPane
                            tab={
                                <span>
                                    <ClockCircleFilled />
                                    Hours
                                </span>
                            }
                            key="Hours"
                        >
                            <Row gutter={[24, 24]}>
                                <Col sm={2}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Job No
                                    </MutedText>
                                    {job && (
                                        <CAJobTag
                                            job_no={job.job_no}
                                            agreed_fee={job.agreed_fee}
                                        />
                                    )}
                                </Col>
                                <Col sm={22}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Job Title
                                    </MutedText>
                                    <h1>{job && job.title}</h1>
                                </Col>
                                <Col sm={24}>
                                    <HoursTable
                                        rows={this.state.jobHours}
                                        loading={
                                            loading ||
                                            this.state.jobHours === null
                                        }
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    )}
                    {authUser && authUser.can_view_book_figure && (
                        <TabPane
                            tab={
                                <span>
                                    <AreaChartOutlined />
                                    Book Figure
                                </span>
                            }
                            key="3"
                        >
                            <Row gutter={[24, 24]}>
                                <Col sm={2}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Job No
                                    </MutedText>
                                    {job && (
                                        <CAJobTag
                                            job_no={job.job_no}
                                            agreed_fee={job.agreed_fee}
                                        />
                                    )}
                                </Col>
                                <Col sm={18}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Job Title
                                    </MutedText>
                                    <h1>{job && job.title}</h1>
                                </Col>
                                <Col sm={2}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Spent
                                    </MutedText>
                                    <h1 style={{ fontSize: "1.095em" }}>
                                        {formatFee(spent)}
                                    </h1>
                                </Col>
                                <Col sm={2}>
                                    <MutedText style={{ fontSize: ".95em" }}>
                                        Agreed Fee
                                    </MutedText>
                                    {job && job.agreed_fee ? (
                                        <h1 style={{ fontSize: "1.095em" }}>
                                            {formatFee(job.agreed_fee)}
                                        </h1>
                                    ) : (
                                        "Not Found"
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Spin size="large" spinning={loading}>
                                <Tabs type="card">
                                    <TabPane tab="Summary" key="3">
                                        <Row type="flex" justify="center">
                                            <Col xs={24}>
                                                {overallChartData &&
                                                !loading ? (
                                                    <CALineChart
                                                        chartData={
                                                            overallChartData
                                                        }
                                                        height="450"
                                                    />
                                                ) : null}
                                            </Col>
                                        </Row>
                                        {overallChartData === null &&
                                            !loading && (
                                                <CAEmpty text="No time records found for this job" />
                                            )}
                                    </TabPane>
                                    <TabPane tab="Stages" key="4">
                                        <Row
                                            type="flex"
                                            justify="center"
                                            gutter={48}
                                        >
                                            {stageChartData &&
                                                stages.length &&
                                                stageChartData.map((row, i) => {
                                                    let stage = chargeables.filter(
                                                        (s) =>
                                                            s.stage_id ===
                                                            row.stage
                                                    )[0];
                                                    let stageId =
                                                        stage.stage_id;

                                                    return stageChartData[i]
                                                        .data.length &&
                                                        row.maxValue &&
                                                        !loading ? (
                                                        <>
                                                            <Col xs={23}>
                                                                <div
                                                                    style={{
                                                                        marginBottom: 20,
                                                                    }}
                                                                >
                                                                    <Tag
                                                                        color={
                                                                            stage.color
                                                                        }
                                                                        style={{
                                                                            minWidth: 55,
                                                                            height: 26,
                                                                            textAlign:
                                                                                "center",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontWeight: 700,
                                                                                fontSize: 16,
                                                                                position:
                                                                                    "relative",
                                                                                top: 2,
                                                                            }}
                                                                        >
                                                                            {
                                                                                stageId
                                                                            }
                                                                        </span>
                                                                    </Tag>
                                                                    <span
                                                                        style={{
                                                                            fontSize: 18,
                                                                            position:
                                                                                "relative",
                                                                            top: 3,
                                                                        }}
                                                                    >
                                                                        {
                                                                            stage.description
                                                                        }
                                                                    </span>
                                                                </div>
                                                                {stageChartData !==
                                                                    null &&
                                                                stageChartData[
                                                                    i
                                                                ].data.length &&
                                                                !loading ? (
                                                                    <CAStageChart
                                                                        chartData={
                                                                            stageChartData[
                                                                                i
                                                                            ]
                                                                        }
                                                                        stage={
                                                                            stages[
                                                                                i
                                                                            ]
                                                                        }
                                                                        height="350"
                                                                    />
                                                                ) : (
                                                                    <CAEmpty text="No time records found for this stage" />
                                                                )}
                                                            </Col>
                                                            <Divider
                                                                style={{
                                                                    backgroundColor:
                                                                        i ===
                                                                            stageChartData.length -
                                                                                1 &&
                                                                        "white",
                                                                }}
                                                            />
                                                        </>
                                                    ) : null;
                                                })}
                                        </Row>
                                        {stageChartData === null &&
                                            !loading && (
                                                <CAEmpty text="No time records found for this job" />
                                            )}
                                    </TabPane>
                                </Tabs>
                            </Spin>
                        </TabPane>
                    )}
                    {authUser &&
                        (authUser.role === ROLES.SUPUSER ||
                            authUser.role === ROLES.ADUSER) && (
                            <TabPane
                                tab={
                                    <span>
                                        <PoundOutlined />
                                        Fees
                                    </span>
                                }
                                key="5"
                            >
                                <Row>
                                    <Col xs={24}>
                                        <CAEditableFeesList
                                            job={job}
                                            fees={fees}
                                            stages={stages}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                    <TabPane
                        tab={
                            <span>
                                <PictureOutlined />
                                Map
                            </span>
                        }
                        key="6"
                    >
                        <Row>
                            {job.postcode ? (
                                <CAMap coords={coords} />
                            ) : (
                                <CAEmpty text="No postcode found for this job" />
                            )}
                        </Row>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

Job.defaultProps = {
    stages: [],
};

const mapStateToProps = (state) => ({
    authUser: state.session.authUser,
    jobs: state.jobs.jobs,
    staff: state.staff.staff,
    stages: sortStages(state.stages.stages),
    sectors: state.sectors.sectors,
});

const mapDispatchToProps = {
    getJobs: JobsActionCreators.getJobs,
    getStaff: StaffActionCreators.getStaff,
};

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Job));
