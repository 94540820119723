import React from "react";
import ReactApexChart from "react-apexcharts";
import { ROUTES } from "../../constants";

class CABarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: {
                chart: {
                    height: 400,
                    type: "bar",
                    events: {
                        dataPointSelection: function(
                            event,
                            chartContext,
                            config
                        ) {
                            const clickedBar =
                                config.w.config.series[0].data[
                                    config.dataPointIndex
                                ];
                            const jobNo = clickedBar.jobNo;
                            const title = clickedBar.title;
                            const agreedFee = clickedBar.hasFee;
                            props.history.push(ROUTES.PROJECTS + "/" + jobNo, {
                                job_no: jobNo,
                                title: title,
                                agreed_fee: agreedFee,
                                activeTab: "Hours",
                            });
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        distributed: true,
                    },
                },
                dataLabels: {
                    formatter: function(val, opt) {
                        return val.toFixed(2);
                    },
                },
                xaxis: {
                    title: {
                        text: "Total Hours",
                    },
                    labels: {
                        formatter: function(value) {
                            return value;
                        },
                    },
                },
                yaxis: {
                    title: {
                        text: "Project",
                    },
                    labels: {
                        maxWidth: 350,
                    },
                },
                legend: {
                    show: false,
                },
            },
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.chartData !== this.props.chartData) {
            const feeIndexes = [];
            this.props.chartData.forEach((obj, index) => {
                if (obj.hasFee) {
                    feeIndexes.push(index);
                }
            });
            this.setState({
                options: {
                    ...this.state.options,
                    colors: [
                        function({ value, seriesIndex, dataPointIndex, w }) {
                            return feeIndexes.includes(dataPointIndex)
                                ? "#73d13d"
                                : "#ff4d4f";
                        },
                    ],
                },
                series: [
                    {
                        name: "Total Hours",
                        data: this.props.chartData,
                    },
                ],
            });
        }
    };

    render() {
        return (
            <div id="chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={400}
                />
            </div>
        );
    }
}

export default CABarChart;
