import {
    REQUEST_SECTORS_PENDING,
    REQUEST_SECTORS_SUCCESS,
    REQUEST_SECTORS_FAILED
  } from 'actions/SectorsActionTypes';
  
  const INITIAL_SECTORS_STATE = {
    sectors: [],
    error: ''
  }
  
  const sectorsReducer = (state=INITIAL_SECTORS_STATE, action={}) => {
    switch(action.type) {
      case REQUEST_SECTORS_PENDING:
        return { ...state }
      case REQUEST_SECTORS_SUCCESS:
        return { ...state, sectors: action.payload }
      case REQUEST_SECTORS_FAILED:
        return { ...state, error: action.payload }
      default:
        return state;
    }
  }
  
  export default sectorsReducer
  