import apiHandler from '../api/api';
import {
  REQUEST_JOBS_PENDING,
  REQUEST_JOBS_SUCCESS,
  REQUEST_JOBS_FAILED
} from './JobsActionTypes';

// Get all jobs from db
export const getJobs = () => dispatch => {
  dispatch({ type: REQUEST_JOBS_PENDING });
  return apiHandler.endpoints.jobs.getAll()
    .then(res => {
      let newJobs = res.data.map(job => {
        if (job.date_added !== null) {
          job.date_added = new Date(job.date_added.replace(' ', 'T')).toLocaleDateString("en-GB");
        }
        return job;
      })
      return dispatch({ type: REQUEST_JOBS_SUCCESS, payload: newJobs })
    })
    .catch(error => dispatch({ type: REQUEST_JOBS_FAILED, payload: error }))
}
