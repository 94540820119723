import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Modal,
  Steps,
  Tag,
  message,
  Select
} from "antd";

import React from "react";
import apiHandler from "../../../api/api";
import { sortStageObjects } from "../../../helpers";

const { Option } = Select;
const { Step } = Steps;

const CollectionCreateForm = Form.create()(
  // eslint-disable-next-line
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        formLayout: "vertical",
      };
    }

    render() {
      const {
        visible,
        onCancel,
        form,
        modalTitle,
        isAddingJob,
        nextNumber,
        stages,
        current,
        job,
        fees,
        sectors
      } = this.props;

      const { getFieldDecorator } = form;
      const { formLayout } = this.state;

      const detailsFormContent = (
        <Form layout={formLayout} autoComplete="off">
          <Form.Item label="Job No">
            <Tag
              color={"geekblue"}
              style={{ minWidth: 55, textAlign: "center" }}
            >
              <span style={{ fontWeight: 700 }}>{nextNumber} </span>
            </Tag>
          </Form.Item>
          <Form.Item label="Job Title">
            {getFieldDecorator("title", {
              rules: [
                {
                  required: true,
                  message: "Please input the job title!",
                },
              ],
              initialValue: job !== null && job.title ? job.title : "",
            })(
              <Input allowClear placeholder="Enter Title" autoComplete="off" />
            )}
          </Form.Item>
          <Form.Item label="Sector">
            {getFieldDecorator("sector", {
              initialValue: job !== null && job.sector ? job.sector : null,
            })(
              <Select>
                {sectors.map(sector => (
                  <Option value={sector.id}>
                    {sector.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Client">
            {getFieldDecorator("client", {
              initialValue: job !== null && job.client ? job.client : "",
            })(
              <Input allowClear placeholder="Enter Client" autoComplete="off" />
            )}
          </Form.Item>
          <Form.Item label="Location">
            {getFieldDecorator("location", {
              initialValue: job !== null && job.location ? job.location : "",
            })(
              <Input
                allowClear
                placeholder="Enter Location"
                autoComplete="off"
              />
            )}
          </Form.Item>
          <Form.Item label="Address">
            {getFieldDecorator("address", {
              initialValue: job !== null && job.address ? job.address : "",
            })(
              <Input
                allowClear
                placeholder="Enter Address"
                autoComplete="off"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Postcode"
            className="collection-create-form_last-form-item"
          >
            {getFieldDecorator("postcode", {
              initialValue: job !== null && job.postcode ? job.postcode : "",
            })(
              <Input
                allowClear
                placeholder="Enter Postcode"
                autoComplete="off"
              />
            )}
          </Form.Item>
        </Form>
      );

      const chargeables = stages.filter((s) => s.chargeable);

      const feesFormContent = (
        <Form>
          {chargeables.map((s, i) => (
            <Form.Item
              key={i}
              label={
                <span>
                  <Tag
                    color={s.color}
                    style={{ position: "relative", bottom: 1 }}
                  >
                    {s.stage_id}
                  </Tag>
                  {s.description}
                </span>
              }
              style={{ marginBottom: i === chargeables.length - 1 ? 20 : 5 }}
            >
              <span>
                {getFieldDecorator(s.stage_id, {
                  initialValue:
                    fees && fees[s.stage_id] !== undefined
                      ? fees[s.stage_id]
                      : undefined,
                })(
                  <InputNumber
                    min={0}
                    max={100000000}
                    formatter={(value) =>
                      `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    style={{ width: "100%" }}
                    inputStyle="text-align: left"
                  />
                )}
              </span>
            </Form.Item>
          ))}
        </Form>
      );

      let extraDetailsContent = [];
      for (var detail in job) {
        if (!["job_no", "title", "sector"].includes(detail)) {
          let val = job[detail] ? job[detail] : "-";
          detail = detail.charAt(0).toUpperCase() + detail.slice(1);
          extraDetailsContent.push({
            [detail]: val,
          });
        }
      }

      const stageFees =
        fees !== null
          ? sortStageObjects(
              Object.keys(fees).map((d, i) => {
                let fee = fees[d];
                return fee === undefined
                  ? null
                  : {
                      stage: chargeables.filter((c) => c.stage_id === d)[0],
                      fee: fees[d],
                    };
              })
            )
          : null;

      let sectorName = '-';
      if (job && job.sector) {
        sectorName = sectors.find(sector => sector.id === job.sector).name
      }

      const confirmContent = (
        <>
          <Descriptions
            bordered
            title="Job Details"
            column={1}
            border
            size={"small"}
          >
            <Descriptions.Item label="Job No">
              {job && <Tag color="geekblue">{job.job_no}</Tag>}
            </Descriptions.Item>
            <Descriptions.Item label="Title">
              {job && job.title}
            </Descriptions.Item>
            <Descriptions.Item label="Sector">
              {sectorName}
            </Descriptions.Item>
            {extraDetailsContent.map((d, i) => (
              <Descriptions.Item key={i} label={Object.keys(d)[0]}>
                {Object.values(d)[0]}{" "}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <Divider />
          {stageFees && stageFees.length ? (
            <Descriptions
              bordered
              title="Fees"
              column={1}
              border
              size={"small"}
            >
              {stageFees.map((d, i) => {
                return d.fee && (
                  <Descriptions.Item
                    key={i}
                    label={`${d.stage.stage_id} ${d.stage.description}`}
                  >
                    {`£${d.fee}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          ) : (
            "No Fees Entered"
          )}
        </>
      );

      const steps = [
        {
          title: "Job Details",
          content: detailsFormContent,
          icon: "solution",
        },
        {
          title: "Fees",
          content: feesFormContent,
          icon: "pound",
        },
        {
          title: "Confirm",
          content: confirmContent,
          icon: "check-circle",
        },
      ];

      return (
        <Modal
          visible={visible}
          title={<h4 style={{ margin: 0 }}>{modalTitle}</h4>}
          onCancel={onCancel}
          confirmLoading={isAddingJob}
          style={{ top: 40 }}
          footer={[
            current > 0 && (
              <Button
                key="prev-btn"
                style={{ marginLeft: 8 }}
                onClick={this.props.prev}
              >
                Previous
              </Button>
            ),
            current === 0 && (
              <Button
                key="cancel-btn"
                style={{ marginLeft: 8 }}
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
            ),
            current === 0 && (
              <Button
                key="next-details-btn"
                type="primary"
                onClick={this.props.onValidateJobDetails}
              >
                Next
              </Button>
            ),
            current === 1 && (
              <Button
                key="next-fees-btn"
                type="primary"
                onClick={this.props.onValidateFees}
              >
                Next
              </Button>
            ),
            current === steps.length - 1 && (
              <Button
                key="confirm-btn"
                type="primary"
                onClick={this.props.onAddJob}
                loading={isAddingJob}
              >
                Confirm
              </Button>
            ),
          ]}
        >
          <Steps current={current}>
            {steps.map((item) => (
              <Step
                key={item.title}
                title={item.title}
                icon={<LegacyIcon type={item.icon} />}
              />
            ))}
          </Steps>
          <Divider style={{ width: "100%", marginBottom: 16 }} />
          <div className="steps-content">{steps[current].content}</div>
        </Modal>
      );
    }
  }
);

class CAJobModal extends React.Component {
  state = {
    visible: false,
    isAddingJob: false,
    current: 0,
    job: null,
    fees: null,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false }, () => {
      this.formRef.props.form.resetFields();
    });
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  handleValidateFees = () => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) {
        this.setState({
          isAddingJob: false,
        });
        return false;
      }

      const feesToInsert = {};

      Object.keys(values).forEach((k) => {
        let stage = this.props.stages.filter((s) => s.stage_id === k);
        let val = values[k];
        if (stage.length && val !== undefined && val !== null) {
          feesToInsert[k] = val;
        }
      });

      this.setState({ fees: feesToInsert }, () => {
        this.next();
      });
    });
  };

  handleValidateJobDetails = () => {
    const form = this.formRef.props.form;

    form.validateFields((err, values) => {
      if (err) {
        this.setState({
          isAddingJob: false,
        });
        return false;
      }

      const jobToInsert = {
        job_no: this.props.nextNumber,
        title: values.title.trim(),
        sector:
          values.sector === undefined
            ? null
            : values.sector,
        client:
          values.client === undefined || values.client.trim() === ""
            ? null
            : values.client.trim(),
        location:
          values.location === undefined || values.location.trim() === ""
            ? null
            : values.location.trim(),
        address:
          values.address === undefined || values.address.trim() === ""
            ? null
            : values.address.trim(),
        postcode:
          values.postcode === undefined || values.postcode.trim() === ""
            ? null
            : values.postcode.toUpperCase(),
      };

      this.setState({ job: jobToInsert }, () => {
        this.next();
      });
    });
  };

  handleAddJob = () => {
    this.setState({ isAddingJob: true });

    const { nextNumber, authUser } = this.props;
    const { job, fees } = this.state;

    let formattedFees = Object.keys(fees).map((s) => {
      return {
        job_no: nextNumber,
        stage_id: s,
        fee: fees[s],
      };
    });

    apiHandler.endpoints.jobs
      .create({
        job: job,
        fees: formattedFees,
        requested_by: `${authUser.firstname} ${authUser.surname}`,
      })
      .then((_) => {
        this.formRef.props.form.resetFields();
        this.setState(
          {
            isAddingJob: false,
            visible: false,
            current: 0,
            job: null,
            fees: null,
          },
          () => {
            message.success(`${nextNumber} added successfully!`);
            this.props.requestJobs();
          }
        );
      })
      .catch((err) => {
        message.error(`Couldn't add job`);
        this.setState({
          isAddingJob: false,
        });
      });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  render() {
    const {
      buttonTitle,
      modalTitle,
      style,
      staff,
      stages,
      sectors,
      nextNumber,
    } = this.props;

    const { isAddingJob, current, job, fees } = this.state;
    return (
      <React.Fragment>
        <Button
          type="primary"
          icon={<EditOutlined />}
          style={style}
          onClick={this.showModal}
        >
          {buttonTitle}
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onAddJob={this.handleAddJob}
          onValidateJobDetails={this.handleValidateJobDetails}
          onValidateFees={this.handleValidateFees}
          modalTitle={modalTitle}
          staff={staff}
          stages={stages}
          sectors={sectors}
          nextNumber={nextNumber}
          isAddingJob={isAddingJob}
          current={current}
          prev={this.prev}
          job={job}
          fees={fees}
        />
      </React.Fragment>
    );
  }
}

export default CAJobModal;
