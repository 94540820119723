import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

const middleware = [thunk];
let store

if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  )
} else {
  store = createStore(rootReducer, applyMiddleware(...middleware));
}


export default store;
