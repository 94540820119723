import React from 'react';
import { Avatar } from 'antd';

const CAAvatar = ({ initials, avatarSrc, weeksBehind, size, boxShadow, ...rest }) => {
  if (!boxShadow) {
    boxShadow = '0 1px 6px 0px rgba(0,0,0,.2)'
  }
  if (avatarSrc) {
    return (
      <div
        style={{
          width: size,
          height: size,
          boxShadow: boxShadow,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          borderRadius: '50%',
          backgroundImage: `url(${avatarSrc})`,
          verticalAlign: 'middle',
          margin: 0
        }} 
        {...rest}
      />
    );
  } else {
    return (
      <Avatar
        style={{
          backgroundColor: 'rgb(25,130,218)',
          verticalAlign: 'middle',
          boxShadow: '0 1px 6px 0px rgba(0,0,0,.2)',
          fontSize: 17,
          margin: 0,
        }}
        size={size}
        {...rest}
      >
        {initials}
      </Avatar>
    );
  }
}

CAAvatar.defaultProps = {
  size: 42
}

export default CAAvatar;
