import { getStages } from './StagesActionCreator';
import { getExpensesCodes } from './ExpensesActionCreator';
import { getUser } from './UserActionCreator';
import { getJobs } from './JobsActionCreator';
import { restoreTimesheet } from './TimesheetActionCreator';
import { getWeekEndings, addWeekEnding } from './WeekEndingsActionCreator';
import { getRecentJobs } from './RecentJobsActionCreator';
import { getProjectLeads } from './ProjectLeadsActionCreator';
import { TIMESHEET_START_WEEK_ENDING } from '../constants';
import { getSectors } from './SectorsActionCreator';
import dayjs from "dayjs";

export const requestTimesheetData = (authUser, restore) => {
  return dispatch =>
    authUser ? Promise.all([
      dispatch(getUser(authUser.uid)),
      dispatch(getWeekEndings(authUser.uid)),
      dispatch(getStages()),
      dispatch(getSectors()),
      dispatch(getExpensesCodes()),
      dispatch(getJobs()),
      dispatch(getRecentJobs(authUser.uid)),
      dispatch(getProjectLeads())
    ]).then(([user, weekEndings]) => {

      weekEndings = weekEndings.payload;
      let weekEnding

      if (weekEndings.length) {
        let sqlDate = weekEndings[weekEndings.length-1].week_ending;
        weekEnding = dayjs(new Date(sqlDate.replace(' ', 'T'))).add(1, 'week').format('DD/MM/YYYY');
      } else {
        weekEnding = dayjs(TIMESHEET_START_WEEK_ENDING).format('DD/MM/YYYY');
      }

      dispatch(addWeekEnding(weekEnding));
      
      if (restore) {
        dispatch(restoreTimesheet({
          staff_id: authUser.uid,
          week_ending: weekEnding
        }))
        // dispatch(restoreResourceSheet({ 
        //   staff_id: authUser.uid,
        //   week_ending: weekEnding
        // }))
      }

      dispatch({
        type: 'AUTH_USER_SET',
        payload: {
          ...user.payload,
          photoURL: authUser.photoURL,
          email: authUser.email
        }
      })
  }) :
    dispatch({
      type: 'AUTH_USER_SET',
      payload: null
    })
}
