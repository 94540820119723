import { combineReducers } from 'redux';
import session from './session';
import recentJobs from './recentJobs';
import stages from './stages';
import sectors from './sectors';
import expensesCodes from './expensesCodes';
import staff from './staff';
import projectLeads from './projectLeads';
import jobs from './jobs';
import weekEnding from './weekEnding';
import weekEndings from './weekEndings';
import timesheet from './timesheet';
import resourceSheet from './resourceSheet';

const rootReducer = combineReducers({
  session: session,
  recentJobs: recentJobs,
  stages: stages,
  sectors: sectors,
  expensesCodes: expensesCodes,
  staff: staff,
  projectLeads: projectLeads,
  jobs: jobs,
  weekEnding: weekEnding,
  weekEndings: weekEndings,
  timesheet: timesheet,
  resourceSheet: resourceSheet
})

export default rootReducer
