import {
  REQUEST_PROJECT_LEADS_PENDING,
  REQUEST_PROJECT_LEADS_SUCCESS,
  REQUEST_PROJECT_LEADS_FAILED
} from 'actions/ProjectLeadsActionTypes';

const INITIAL_PROJECT_LEADS_STATE = {
  isPending: false,
  projectLeads: []
}

const projectLeadsReducer = (state=INITIAL_PROJECT_LEADS_STATE, action={}) => {
  switch(action.type) {
    case REQUEST_PROJECT_LEADS_PENDING:
      return { ...state, isPending: true }
    case REQUEST_PROJECT_LEADS_SUCCESS:
      return { ...state, projectLeads: action.payload, isPending: false }
    case REQUEST_PROJECT_LEADS_FAILED:
      return { ...state, error: action.payload, isPending: false }
    default:
      return state;
  }
}

export default projectLeadsReducer
