import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    padding: 0;
    margin: 0;
  }
`

export const theme = {
  primary: '#6e27c5'
}

export const theme1 = {
  primary: '#ff0198',
  secondary: '#01c1d6',
  danger: '#eb238e',
  light: '#f4f4f4',
  dark: '#222'
}

export const theme2 = {
  primary: '#6e27c5',
  secondary: '#ffb617',
  danger: '#f16623',
  light: '#f4f4f4',
  dark: '#222'
}

export const PageHeading = styled.h2`
  margin-bottom: 0px;
  font-size: 1.75em;
  font-weight: 400;
`

export const Heading1 = styled.h1`
  font-size: 1.95em;
  font-weight: 400;
`

export const Heading2 = styled.h1`
  margin-bottom: 0px;
  font-size: 1.75em;
  font-weight: 400;
`

export const Heading3 = styled.h1`
  margin-bottom: 0px;
  font-size: 1.4em;
`

export const Heading4 = styled.h1`
  margin-bottom: 16px;
  font-size: 1.18em;
  color: #495057;
  opacity: 0.6;
  font-weight: 400;
`

export const MutedText = styled.p`
  color: #a1aab2;
  font-weight: 300;
  margin: 0;
`
