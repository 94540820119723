import { Badge, Input, Modal, Popover } from 'antd';

import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const { TextArea } = Input;

const utilizeFocus = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}

  return {setFocus, ref} 
}

class CACommentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      value: this.props.comment
    }
    this.inputFocus = utilizeFocus()
  }

  showModal = () => {
    this.setState({
      visible: true,
    }, () => {
      this.inputFocus.setFocus();
    })
  };

  handleCommentChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  handleOk = () => {
    const { editable, row, onSaveComment } = this.props
    editable && onSaveComment(row, this.state.value);
    this.handleCancel()
  }

  handleCancel = () => {
    this.setState({
      visible: false
    }, () => {
      this.inputFocus.setFocus();
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.comment !== prevProps.comment) {
      this.setState({ value: this.props.comment });
    }
  }

  render() {
    const { value, visible } = this.state 
    const { editable, row } = this.props
    const showBadge = !!value;
    let content = (
      <Badge dot={showBadge} color={showBadge && "green"}>
        <button 
          style={{ 
            color: '#1790ff', 
            cursor: 'pointer', 
            border: 'none', 
            background: 'none',
            fontSize: 15
          }} 
          onClick={this.showModal}
        >
          <EditOutlined />
        </button>
      </Badge>
    )
    return (
      <React.Fragment>
        { value ? (
          <Popover content={value} title="Comment" overlayStyle={{ width: 130 }}>
            { content }
          </Popover>
        ) : (
          content
        )}
        <Modal
          key={row}
          title={editable ? "Add Comment" : "Comment"}
          open={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          { editable ? (
            <TextArea
              key={row}
              ref={this.inputFocus.ref}
              autosize={true}
              onChange={this.handleCommentChange}
              value={value}
              placeholder={"Enter comment here..."}
              onPressEnter={this.handleOk}
              autoSize={{
                minRows: 5
              }}
            />
          ) : (
            value ? value : 'No Comment'
          )}
        </Modal>
      </React.Fragment>
    )
  }
}

export default CACommentModal
