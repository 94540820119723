import React from "react";
import ReactApexChart from "react-apexcharts";

class CAPieChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [44, 55, 13, 43, 22],
            options: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ['Aviation', 'Commercial & Industrial', 'Ecclesiastical', 'Healthcare', 'Heritage', 'Housing & Communities', 'Retail & Leisure', 'Schools', 'Universities & Colleges'],
                responsive: [
                    {
                        breakpoint: 1200,
                        options: {
                            chart: {
                                width: 350,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
        };
    }
    render() {
        return (
            <div id="chart">
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="pie"
                    width={380}
                />
            </div>
        );
    }
}

export default CAPieChart;
