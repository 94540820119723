import {
  REQUEST_EXPENSES_CODES_PENDING,
  REQUEST_EXPENSES_CODES_SUCCESS,
  REQUEST_EXPENSES_CODES_FAILED
} from 'actions/ExpensesActionTypes';

const INITIAL_EXPENSES_CODES_STATE = {
  codes: [],
  error: ''
}

const expensesCodesReducer = (state=INITIAL_EXPENSES_CODES_STATE, action={}) => {
  switch(action.type) {
    case REQUEST_EXPENSES_CODES_PENDING:
      return { ...state }
    case REQUEST_EXPENSES_CODES_SUCCESS:
      return { ...state, codes: action.payload }
    case REQUEST_EXPENSES_CODES_FAILED:
      return { ...state, error: action.payload }
    default:
      return state;
  }
}

export default expensesCodesReducer
