import "./index.css";

import * as serviceWorker from "./serviceWorker";

import Firebase, { FirebaseContext } from "./components/Firebase";

import App from "./containers/App";
import { GlobalStyle } from "./theme/globalStyle";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Provider store={store}>
      <App />
      <GlobalStyle />
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

if (process.env.NODE_ENV === "development") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
