import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import CALanding from 'components/CALanding';
import CAPasswordForget from 'components/CAPasswordForget';
import CASignIn from 'components/CASignIn';
import AppWrapper from '../AppWrapper';
import HttpsRedirect from 'react-https-redirect';
import NotFound from 'components/NotFound';
import {
  ROUTES
} from '../../constants';
import React from 'react';
import { withAuthentication } from '../../components/Session';

const App = () => {
  return (
    <HttpsRedirect>
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.LANDING} component={CALanding} />
          <Route exact path={ROUTES.SIGN_IN} component={CASignIn} />
          <Route exact path={ROUTES.PASSWORD_FORGET} component={CAPasswordForget} />
          <Route path='/404' component={NotFound} />
          <AppWrapper />
        </Switch>
      </BrowserRouter>
    </HttpsRedirect>
  )
}

export default withAuthentication(App);
