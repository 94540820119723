import {
  Col,
  Descriptions,
  Row,
  Skeleton,
  Tag
} from 'antd';
import { formatFee, isEmpty } from '../../../../helpers';

import CAEmpty from 'components/CAEmpty';
import React from 'react';

class CAEditableFeesList extends React.Component {
  render() {
    const { job, stages, fees } = this.props;
    return ( 
      <Skeleton active loading={isEmpty(job) && fees.length === 0}>
        <Row gutter={24}>
          { fees.length ? (
            <Col xs={12}>
              <Descriptions bordered border size={'small'} column={1}>
                { fees.map((fee,i) => {
                  let stage = stages.find(s => s.stage_id === fee.stage_id)
                  let formattedStage = (
                    <>
                      <Tag
                        color={stage.color}
                        style={{
                          minWidth: 40,
                          height: 22,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {stage.stage_id}
                        </span>
                      </Tag>
                      <span style={{ position: 'relative', top: 1 }}>
                        {stage.description}
                      </span>
                    </>
                  )
                  return stage !== undefined && (
                    <Descriptions.Item 
                      key={i} 
                      label={formattedStage}
                    >
                      {formatFee(fees[i].fee)}
                    </Descriptions.Item>
                  )
                }) }
                <Descriptions.Item key='total' label={'Total'}>
                  {formatFee(job.agreed_fee)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          ) : (
            <Col xs={24}>
              <CAEmpty
                text='No fees found for this job'
              />
            </Col>
          ) }
        </Row>
      </Skeleton>
    )
  }
}

export default CAEditableFeesList