import "./Timesheets.css";

import * as ResourceSheetActionCreators from "actions/ResourceSheetActionCreator";
import * as TimesheetActionCreators from "actions/TimesheetActionCreator";
import * as WeekEndingActionCreators from "actions/WeekEndingsActionCreator";

import {
  Alert,
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Skeleton,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  ClockCircleOutlined,
  PoundOutlined,
  EditOutlined,
  HomeOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { DATE_FORMAT, ROUTES } from "../../constants";
import * as roles from "../../constants/roles";
import { Heading4, MutedText } from "theme/globalStyle";
import { Redirect, withRouter } from "react-router-dom";
import {
  appendFees,
  calculateTimesheetDateRange,
  formatJobs,
  formatStages,
  formatTimerecords,
  getWeeksDiff,
  getNextId,
} from "../../helpers";

import CADateDisplay from "components/CADateDisplay";
import CAExpensesTable from "./CAExpensesTable";
import CAJobTag from "components/CAJobTag";
import CATimeTable from "./CATimeTable";
import React from "react";
import apiHandler from "../../api/api";
import { compose } from "recompose";
import moment from "moment";
import { connect } from "react-redux";
import { extendMoment } from "moment-range";

import { getNextFriday } from "../../helpers";
import { requestTimesheetData } from "actions";
import withAuthorization from "components/Session/withAuthorization";

import dayjs from "dayjs";
import { filter } from "underscore";

const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;
const ButtonGroup = Button.Group;
extendMoment(moment);
message.config({
  top: 12,
  duration: 2,
  maxCount: 1,
});

class Timesheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weeksArray: [],
      activeTab: "1", // 3
      savingTimesheet: false,
      selectedWeek: null,
      loadingTimesheet: false,
      restoredTimesheet: null,
      submittingTimesheet: false,
    };
  }

  handleRecentJobClick = ({ job_no, title }) => {
    let { time, expenses } = this.props.timesheet;

    let { activeTab } = this.state;
    let rows = activeTab === "1" ? time : expenses;

    let modified = false;
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].job === undefined && rows[i].stage === undefined) {
        rows[i] = {
          id: getNextId(rows),
          job: `${job_no} ${title}`,
          tot: 0,
        };
        modified = true;
        break;
      }
    }
    if (!modified) {
      rows.push({
        id: getNextId(rows),
        job: `${job_no} ${title}`,
        tot: 0,
      });
    }

    activeTab === "1"
      ? this.props.saveTime(rows)
      : this.props.saveExpenses(rows);
    // message.success('Added to timesheet')
  };

  handleCodeClick = (data, chargeable) => {
    let { time, expenses } = this.props.timesheet;
    let { activeTab } = this.state;
    let rows = activeTab === 1 ? time : expenses;

    if (activeTab === "2" && !data.chargeable) {
      return;
    }

    // Time entry
    if (activeTab === "1") {
      const { stage_id, description } = data;
      let modified = false;
      for (var i = 0; i < rows.length; i++) {
        let row = rows[i];

        // For chargeable codes
        if (row.job !== undefined && row.stage === undefined && chargeable) {
          row.id = getNextId(rows);

          row.stage = data.label;
          row.tot = 0;
          modified = true;
          break;
        }

        // For non-chargeable codes
        if (row.job === undefined && row.stage === undefined && !chargeable) {
          row.id = getNextId(rows);

          row.stage = `${stage_id} ${description}`;
          row.tot = 0;
          modified = true;
          break;
        }
      }

      if (!modified && !chargeable) {
        rows.push({
          id: getNextId(rows),
          stage: `${stage_id} ${description}`,
          tot: 0,
        });
        // message.success('Added to timesheet')
      }
      this.props.saveTime(rows);
    } else if (activeTab === "2") {
      // Expenses entry
      const { code } = data;
      let modified = false;
      for (var j = 0; j < rows.length; i++) {
        let row = rows[j];
        if (row.job === undefined && row.code === undefined) {
          row.id = getNextId(rows);
          row.code = code;
          modified = true;
          break;
        }
      }

      if (!modified) {
        rows.push({
          id: getNextId(rows),
          code: code,
        });
      }
      this.props.saveExpenses(rows);
    }
  };

  onActiveTabChange = (tabId) => {
    this.setState({
      activeTab: tabId,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.weekEndings !== this.props.weekEndings) {
      let lastWeek = this.props.weekEndings[this.props.weekEndings.length - 1];
      const { weeksArray } = calculateTimesheetDateRange(
        this.props.weekEndings
      );
      let weeksBehind = -1;
      if (lastWeek !== undefined) {
        weeksBehind = getWeeksDiff(lastWeek.week_ending, getNextFriday());
      }
      this.setState({ weeksArray, weeksBehind });
    }
  }

  componentDidMount() {
    let lastWeek = this.props.weekEndings[this.props.weekEndings.length - 1];
    const { weeksArray } = calculateTimesheetDateRange(this.props.weekEndings);
    let weeksBehind = -1;
    if (lastWeek !== undefined) {
      weeksBehind = getWeeksDiff(lastWeek.week_ending, getNextFriday());
    }
    this.setState({ weeksArray, weeksBehind });
    // const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

    // window.onbeforeunload = e => {

    //   // If they have had their timesheet restored
    //   if (this.props.timesheet.initialAdminStaffTimesheet !== undefined) {
    //     const objectsEqual = (o1, o2) =>
    //       Object.keys(o1).length === Object.keys(o2).length
    //           && Object.keys(o1).every(p => o1[p] === o2[p]);

    //     console.log(objectsEqual(this.props.timesheet.time, this.props.timesheet.initialTime));

    //   } else {
    //     const totalTimeEntered = sumPropertyValue(this.props.timesheet.time, 'tot');

    //     // If they have entered hours
    //     if (totalTimeEntered !== 0) {
    //       var dialogText = 'You have unsaved changes for your timesheet. Do you want to quit?';
    //       e.returnValue = dialogText;
    //       return dialogText;
    //     }
    //   }
    // };
  }

  validateTimesheet = (timesheet) => {
    let filteredTimesheet = timesheet;
    filteredTimesheet.time = timesheet.time.filter((rec) => rec.tot !== 0);
    filteredTimesheet.expenses = timesheet.expenses.filter(
      (rec) => rec.job !== undefined || rec.code !== undefined
    );
    return filteredTimesheet;
  };

  handleSubmitTimesheet = () => {
    this.setState({
      submittingTimesheet: true,
    });
    let { authUser, timesheet, weekEnding, submitTimesheet } = this.props;
    timesheet.weekEnding = weekEnding;
    timesheet.user = authUser;

    timesheet = this.validateTimesheet(timesheet);

    submitTimesheet(timesheet)
      .then((_) => {
        authUser.uid = authUser.staff_id;
        this.props.requestTimesheetData(authUser, false).then((_) => {
          let { weeksArray, weeksBehind } = calculateTimesheetDateRange(
            this.props.weekEndings
          );
          this.props.getNextWeekEnding(authUser.staff_id);

          this.setState(
            {
              weeksArray,
              weeksBehind,
              selectedWeek: null,
              submittingTimesheet: false,
            },
            () => {
              message.destroy();
              message.success("Timesheet submitted!");
              return true;
            }
          );
        });
      })
      .catch((err) => {
        message.error(`Couldn't submit timesheet`);
        this.setState({
          submittingTimesheet: false,
        });
        return false;
      });
  };

  handleSaveTimesheet = () => {
    this.setState({
      savingTimesheet: true,
    });

    let { authUser, timesheet, weekEnding } = this.props;
    timesheet.weekEnding = weekEnding;
    timesheet.user = authUser;

    this.props
      .saveTimesheet(timesheet)
      .then((res) => {
        message.destroy();
        this.setState({
          savingTimesheet: false,
          timesheetSaved: true,
        });
        setTimeout(() => {
          this.setState({
            timesheetSaved: false,
          });
        }, 1500);
      })
      .catch((err) => {
        message.destroy();
        message.error(`Couldn't save timesheet`);
        this.setState({
          savingTimesheet: false,
        });
      });
  };

  handleCopyTimesheet = () => {
    this.setState({
      savingTimesheet: true,
    });

    const restoredTimesheet = this.state.restoredTimesheet;
    let { authUser, timesheet, weekEnding } = this.props;
    let filteredTime = timesheet.time.filter(
      (record) => record.job !== undefined && record.stage !== undefined
    );

    let curId = 0;
    for (let row of restoredTimesheet.time) {
      let newRow = { tot: 0 };
      for (let key of ["job", "stage", "comment"]) {
        if (row[key] !== undefined) {
          newRow[key] = row[key];
        }
      }
      if (filteredTime.length) {
        newRow.id = filteredTime[filteredTime.length - 1].id + 1;
      } else {
        newRow.id = curId;
        curId += 1;
      }
      filteredTime.push(newRow);
    }
    timesheet.time = filteredTime;
    timesheet.weekEnding = weekEnding;
    timesheet.user = authUser;

    this.props
      .saveTimesheet(timesheet)
      .then((res) => {
        this.setState({
          savingTimesheet: false,
          timesheetSaved: true,
        });
        setTimeout(() => {
          this.setState({
            timesheetSaved: false,
          });
        }, 1500);
      })
      .catch((err) => {
        this.setState({
          savingTimesheet: false,
        });
      });
  };

  handleSaveTime = (time) => {
    this.props.saveTime(time);
  };

  handleSaveExpenses = (expenses, addingReceipts) => {
    this.props.saveExpenses(expenses);
    // addingReceipts && this.handleSaveTimesheet()
  };

  handleSaveResourceSheet = (resourceSheet) => {
    this.props.saveResourceSheet(resourceSheet);
  };

  showConfirmSubmitTimesheet = () => {
    let { timesheet } = this.props;
    let weeklyTotal = timesheet.time.reduce((a, b) => a + Number(b.tot), 0);
    let that = this;

    if (!weeklyTotal) {
      message.error("No time entered!");
      return;
    }
    confirm({
      title: `Total: ${weeklyTotal} Hours`,
      content:
        "Are you sure you want to submit this timesheet? (rows with no hours will be ignored)",
      onOk() {
        that.handleSubmitTimesheet();
        window.scrollTo(0, 0);
      },
    });
  };

  getTimerecordsByWeek = (weekEnding) => {
    this.setState({
      loadingTimesheet: true,
    });

    let id =
      this.props.authUser.staff_id + "/" + weekEnding.format("YYYY-MM-DD");

    apiHandler.endpoints.timerecordsByStaff
      .getOne({ id: id })
      .then((timerecordsRes) => {
        apiHandler.endpoints.expensesByStaff
          .getOne({ id: id })
          .then((expensesRes) => {
            const weekEnding = this.state.selectedWeek;
            const jobs = this.props.jobs;

            let time = formatTimerecords(timerecordsRes.data, weekEnding, jobs);
            let expenses = expensesRes.data;
            expenses.forEach((row, i) => {
              if (row.job_no !== null) {
                expenses[i].job = `${row.job_no} ${row.title}`;
              }
            });

            let restoredTimesheet = {
              time: time.length ? time : null,
              expenses: expenses.length ? expenses : null,
            };
            this.setState({
              loadingTimesheet: false,
              restoredTimesheet,
            });
          })
          .catch((err) => {
            this.setState({
              loadingTimesheet: false,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loadingTimesheet: false,
        });
      });
  };

  handleWeekChange = (value) => {
    let selectedWeek = this.state.selectedWeek
      ? dayjs(this.state.selectedWeek, DATE_FORMAT)
      : dayjs(this.props.weekEnding, DATE_FORMAT);
    let newWeekEnding;

    if (value < 0) {
      newWeekEnding = selectedWeek.subtract(1, "week");
    } else if (value > 0) {
      newWeekEnding = selectedWeek.add(1, "week");
    } else {
      if (selectedWeek.isSame(dayjs(this.props.weekEnding, DATE_FORMAT))) {
        return;
      }
      newWeekEnding = dayjs(this.props.weekEnding, DATE_FORMAT);
    }
    this.setState(
      {
        selectedWeek: newWeekEnding.format(DATE_FORMAT),
      },
      () => {
        this.getTimerecordsByWeek(newWeekEnding);
      }
    );
  };

  handleJobNoClick = (job_no, title) => {
    this.props.history.push(ROUTES.PROJECTS + "/" + job_no, {
      job_no: job_no,
      title: title,
    });
  };

  onResourceInputNumFocus = (colIdx, rowIdx) => {
    this.setState({
      resourceInputNumCell: {
        colIdx,
        rowIdx,
      },
    });
  };

  render() {
    const {
      recentJobs,
      jobOptions,
      stages,
      formattedStages,
      authUser,
      weekEnding,
      expensesCodes,
    } = this.props;

    const {
      savingTimesheet,
      weeksBehind,
      weeksArray,
      selectedWeek,
      loadingTimesheet,
      restoredTimesheet,
      activeTab,
      submittingTimesheet,
      timesheetSaved,
    } = this.state;

    // Redirect if job book user (i.e. doesn't fill in timesheets)
    if (authUser && !authUser.can_enter_timesheets) {
      return <Redirect exact from="/" to={ROUTES.PROJECTS} />;
    }

    let fullname = authUser ? `${authUser.firstname} ${authUser.surname}` : "";

    let selectedStartWeek =
      selectedWeek === null || selectedWeek === weekEnding;

    let week = selectedWeek ? selectedWeek : weekEnding;

    let timesheet = restoredTimesheet;
    if (week === weekEnding) {
      timesheet = this.props.timesheet;
    }

    let finalWeek = weeksArray.length
      ? weeksArray[weeksArray.length - 1].weekEnding
      : week;

    let selectedWeeksBehind = dayjs(finalWeek)
      .startOf("day")
      .diff(dayjs(week, DATE_FORMAT).startOf("day"), "week");
    let operations;
    if (
      roles.isDirector(authUser) &&
      dayjs(week, DATE_FORMAT).isBefore(dayjs(weekEnding, DATE_FORMAT))
    ) {
      operations = (
        <Tooltip
          title="Timesheet copied!"
          trigger="click"
          open={timesheetSaved}
        >
          <Button
            onClick={this.handleCopyTimesheet}
            loading={savingTimesheet}
            icon={<CopyOutlined />}
          >
            Copy Timesheet
          </Button>
        </Tooltip>
      );
    } else if (selectedStartWeek) {
      operations = (
        <span>
          <Button
            onClick={this.handleSaveTimesheet}
            loading={savingTimesheet}
            icon={<EditOutlined />}
          >
            Save
          </Button>
          &nbsp;&nbsp;
          <Button
            type="primary"
            onClick={this.showConfirmSubmitTimesheet}
            icon={<UploadOutlined />}
          >
            Submit Timesheet
          </Button>
        </span>
      );
    }

    let timesheetDesc;
    if (weeksBehind > 1) {
      timesheetDesc = (
        <p>
          You are <strong>{weeksBehind} weeks </strong>behind.
        </p>
      );
    } else if (weeksBehind === 1) {
      timesheetDesc = <p>You are 1 week behind.</p>;
    } else if (weeksBehind <= 0) {
      timesheetDesc = <p>You are up to date!</p>;
    }

    let isThisWeek =
      weeksArray.length && !weeksArray[weeksArray.length - 1].complete;

    return (
      authUser && (
        <Spin spinning={submittingTimesheet} tip="Submitting timesheet...">
          <div style={{ margin: "0 auto" }}>
            <Row gutter={16} justify={"space-between"}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                <div className="gutter-box">
                  <h1 style={{ fontSize: "1.9em" }}>{fullname}</h1>
                  <span style={{ position: "relative", bottom: 8 }}>
                    {timesheetDesc}
                  </span>
                  <CADateDisplay
                    weeks={this.state.weeksArray}
                    weekEnding={weekEnding}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                <div className="gutter-box">
                  <Heading4 style={{ marginBottom: 18 }}>Recent Jobs</Heading4>
                  {recentJobs.length ? (
                    recentJobs.map((job) => {
                      const filteredJob = this.props.jobs.filter(
                        (opt) => opt.job_no === job.job_no
                      );
                      const feeExistsForRecord =
                        filteredJob.length && filteredJob[0].agreed_fee
                          ? true
                          : false;
                      return (
                        <div
                          key={job.job_no}
                          className="recent-job-item"
                          onClick={() =>
                            selectedStartWeek && this.handleRecentJobClick(job)
                          }
                        >
                          <CAJobTag
                            job_no={job.job_no}
                            agreed_fee={feeExistsForRecord}
                          />
                          <span>{job.title}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{ margin: "auto" }}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                <div
                  className="gutter-box"
                  style={{ padding: "22px 20px 22px 28px" }}
                >
                  {activeTab === "2" ? (
                    <>
                      <Heading4>Expenses Codes</Heading4>
                      <div className="expenses-container">
                        {expensesCodes.length ? (
                          expensesCodes.map((code, i) => {
                            return (
                              <div
                                key={i}
                                className="expenses-wrap"
                                // onClick={() =>
                                //   selectedStartWeek &&
                                //   this.handleCodeClick(code)
                                // }
                              >
                                <div className="expenses-code">
                                  {" "}
                                  {code.code}{" "}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div style={{ marginTop: 35 }}>
                            <Empty
                              description={<span>No expenses codes</span>}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <Heading4>Chargeable Time</Heading4>
                      <div
                        className="stages-container"
                        style={{ marginTop: 18 }}
                      >
                        {formattedStages.length ? (
                          formattedStages
                            .filter((s) => s.category === "Chargeable")
                            .map((s, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{ margin: "8px 0px" }}
                                  className="stage-wrap"
                                  // onClick={() =>
                                  //   selectedStartWeek &&
                                  //   this.handleCodeClick(s, true)
                                  // }
                                >
                                  <div className="left-stage-chargeable">
                                    <Tag
                                      color={s.color}
                                      style={{
                                        cursor: "pointer",
                                        minWidth: 36,
                                        textAlign: "center",
                                      }}
                                    >
                                      <span style={{ fontWeight: 700 }}>
                                        {s.label.substr(
                                          0,
                                          s.label.indexOf(" ")
                                        )}{" "}
                                      </span>
                                    </Tag>
                                  </div>
                                  <div className="right-stage-chargeable">
                                    <span style={{ fontSize: 12 }}>
                                      {s.label.substr(s.label.indexOf(" ") + 1)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div style={{ marginTop: 35 }}>
                            <Empty
                              description={<span>No chargeable codes</span>}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 6 }}>
                <div className="gutter-box">
                  <Heading4>Non-Chargeable Time</Heading4>
                  <div className="stages-container">
                    {stages.length ? (
                      stages.map((stage, i) => {
                        return (
                          !stage.chargeable && (
                            <div
                              key={i}
                              className="stage-wrap"
                              // onClick={() =>
                              //   selectedStartWeek &&
                              //   this.handleCodeClick(stage, false)
                              // }
                            >
                              <div className="left-stage-non-chargeable">
                                {" "}
                                {stage.stage_id}{" "}
                              </div>
                              <div className="right-stage-non-chargeable">
                                {" "}
                                {stage.description}{" "}
                              </div>
                            </div>
                          )
                        );
                      })
                    ) : (
                      <div style={{ marginTop: 35 }}>
                        <Empty
                          description={<span>No non-chargeable codes</span>}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {(weeksBehind > 0 || isThisWeek) &&
            authUser.can_enter_timesheets ? (
              <Row className="timesheet-container">
                <div
                  style={{
                    padding: "32px",
                    margin: "18px 0px 130px 0px",
                    minHeight: 500,
                  }}
                  className="gutter-box"
                >
                  <>
                    <Row>
                      <Col xs={12}>
                        {activeTab !== "3" ? (
                          <>
                            <h2 style={{ display: "inline" }}>
                              Timesheet Week Ending {week}
                            </h2>
                            <ButtonGroup
                              style={{
                                marginLeft: 16,
                                position: "relative",
                                bottom: 2,
                              }}
                            >
                              <Button
                                type="primary"
                                size="small"
                                icon={<LeftOutlined />}
                                onClick={() => this.handleWeekChange(-1)}
                                style={{ width: 34, height: 25 }}
                              />
                              <Button
                                type="primary"
                                size="small"
                                icon={<RightOutlined />}
                                onClick={() => this.handleWeekChange(1)}
                                style={{ width: 34, height: 25 }}
                              />
                              <Button
                                type="primary"
                                size="small"
                                icon={<HomeOutlined />}
                                onClick={() => this.handleWeekChange(0)}
                                style={{ width: 34, height: 25 }}
                              />
                            </ButtonGroup>
                            <MutedText
                              style={{
                                fontSize: "1.1em",
                                position: "relative",
                                left: 16,
                                bottom: 2,
                                display: "inline",
                              }}
                            >
                              {selectedWeeksBehind >= 0 &&
                                (selectedWeeksBehind === 0
                                  ? "This Week"
                                  : selectedWeeksBehind === 1
                                  ? "Last Week"
                                  : `${selectedWeeksBehind} weeks ago`)}
                            </MutedText>
                            <MutedText
                              style={{
                                position: "relative",
                                top: 5,
                                marginBottom: 12,
                              }}
                            >
                              CAA.QA-133 (AD6)
                            </MutedText>
                          </>
                        ) : (
                          <>
                            <h2 style={{ display: "inline", marginBottom: 50 }}>
                              Resource Sheet Week Starting 20/01/2020
                            </h2>
                            <ButtonGroup
                              style={{
                                marginLeft: 16,
                                position: "relative",
                                bottom: 2,
                              }}
                            >
                              <Button
                                type="primary"
                                size="small"
                                icon={<LeftOutlined />}
                                onClick={() => this.handleWeekChange(-1)}
                              />
                              <Button
                                type="primary"
                                size="small"
                                icon={<RightOutlined />}
                                onClick={() => this.handleWeekChange(1)}
                              />
                              <Button
                                type="primary"
                                size="small"
                                icon={<HomeOutlined />}
                                onClick={() => this.handleWeekChange(0)}
                              />
                            </ButtonGroup>
                            <MutedText
                              style={{
                                fontSize: "1.1em",
                                position: "relative",
                                left: 16,
                                bottom: 2,
                                display: "inline",
                              }}
                            >
                              {selectedWeeksBehind >= 0 &&
                                (selectedWeeksBehind === 0
                                  ? "This Week"
                                  : selectedWeeksBehind === 1
                                  ? "Last Week"
                                  : `${selectedWeeksBehind} weeks ago`)}
                            </MutedText>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Spin spinning={loadingTimesheet}>
                      <Skeleton active loading={jobOptions.length === 0}>
                        <Tabs
                          tabBarExtraContent={operations}
                          animated={false}
                          onChange={(tabId) => this.onActiveTabChange(tabId)}
                        >
                          <TabPane
                            tab={
                              <span>
                                <ClockCircleOutlined />
                                Time
                              </span>
                            }
                            key="1"
                          >
                            <CATimeTable
                              editable={selectedStartWeek}
                              jobOptions={jobOptions}
                              stages={formattedStages}
                              timesheet={timesheet}
                              week={week}
                              saveTime={this.handleSaveTime}
                            />
                          </TabPane>
                          <TabPane
                            tab={
                              <span>
                                <PoundOutlined />
                                Expenses
                                {/* <span style={{ fontSize: '0.75em', position: 'relative', bottom: 5, left: 2 }}>
                              (BETA)
                            </span> */}
                              </span>
                            }
                            key="2"
                          >
                            <CAExpensesTable
                              editable={selectedStartWeek}
                              jobOptions={jobOptions}
                              stages={formattedStages}
                              timesheet={timesheet}
                              weekEnding={week}
                              saveExpenses={this.handleSaveExpenses}
                            />
                          </TabPane>
                        </Tabs>
                      </Skeleton>
                    </Spin>
                  </>
                </div>
              </Row>
            ) : (
              <div
                style={{
                  margin: "15px 0px",
                  minHeight: 100,
                }}
                className="gutter-box"
              >
                <Row>
                  <Col xs={24}>
                    <Alert
                      message="Up To Date"
                      description="You are up to date!"
                      type="success"
                      showIcon
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Spin>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  stages: state.stages.stages,
  formattedStages: formatStages(state.stages.stages),
  expensesCodes: state.expensesCodes.codes,
  jobs: state.jobs.jobs,
  jobOptions: formatJobs(state.jobs.jobs),
  weekEndings: state.weekEndings.weekEndings,
  recentJobs: appendFees(state.recentJobs.recentJobs, state.jobs.jobs),
  weekEnding: state.weekEnding.weekEnding,
  timesheet: state.timesheet,
  resourceSheet: state.resourceSheet.resourceSheet,
});

const mapDispatchToProps = {
  saveTime: TimesheetActionCreators.saveTime,
  saveExpenses: TimesheetActionCreators.saveExpenses,
  saveTimesheet: TimesheetActionCreators.saveTimesheet,
  saveResourceSheet: ResourceSheetActionCreators.saveResourceSheet,
  submitTimesheet: TimesheetActionCreators.submitTimesheet,
  resetTimesheet: TimesheetActionCreators.resetTimesheet,
  requestTimesheetData: requestTimesheetData,
  getNextWeekEnding: WeekEndingActionCreators.getNextWeekEnding,
};

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Timesheets));
