import React from 'react';
import {
  Row,
  Col,
  Tabs
} from 'antd';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import withAuthorization from 'components/Session/withAuthorization';

import { Heading1 } from 'theme/globalStyle';
import StagesTable from './StagesTable';
import CALine from 'components/CALine';
import * as ROLES from '../../../constants/roles';

const { TabPane } = Tabs;

class AdminData extends React.Component {
  render() {
    return (
        <div style={{ padding: 24, background: '#fff', margin: '0 auto', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }} className="gutter-box">
          <Heading1>
            Data
          </Heading1>
          <CALine />
          <Tabs tabPosition='left'>
            <TabPane tab="Stages" key="3">
              <Row>
                <Col xs={24}>
                  <StagesTable />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Staff" key="1">
              <Row>
                <Col xs={24}>
                  Staff
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Jobs" key="2">
              Content of Tab 2
            </TabPane>
            
            <TabPane tab="Expenses Codes" key="4">
              Content of Tab 4
            </TabPane>
          </Tabs>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser
});

const authCondition = (authUser) => 
  !!authUser && authUser.role === ROLES.ADUSER;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, null)
)(AdminData);
