import {
  RESET_TIMESHEET,
  RESTORE_ADMIN_STAFF_TIMESHEET,
  RESTORE_TIMESHEET_FAILED,
  RESTORE_TIMESHEET_PENDING,
  RESTORE_TIMESHEET_SUCCESS,
  SAVE_EXPENSES,
  SAVE_TIME,
  SAVE_TIMESHEET_FAILED,
  SAVE_TIMESHEET_PENDING,
  SAVE_TIMESHEET_SUCCESS,
  SUBMIT_TIMESHEET_FAILED,
  SUBMIT_TIMESHEET_PENDING,
  SUBMIT_TIMESHEET_SUCCESS,
} from "./TimesheetActionTypes";

import { DAYS } from "../constants";
import apiHandler from "../api/api";
import dayjs from "dayjs";

const calculateDate = (weekDay, weekEnding) => {
  let weekEndingDayjs = dayjs(weekEnding, "DD/MM/YYYY");
  return weekEndingDayjs
    .subtract(5 - DAYS.indexOf(weekDay), "day")
    .format("YYYY-MM-DD");
};

// Format the timesheet
const formatTimesheet = (timesheet, isTemp) => {
  let newTimesheet = {
    staff_id: timesheet.user.staff_id,
    week_ending: dayjs(timesheet.weekEnding, "DD/MM/YYYY").format("YYYY-MM-DD"),
    time: [],
    expenses: [],
  };

  // Format timerecords
  timesheet.time.forEach((t, row) => {
    for (const [key, value] of Object.entries(t)) {
      if (DAYS.indexOf(key) >= 0) {
        let date = calculateDate(key, timesheet.weekEnding);
        newTimesheet.time.push({
          date: date,
          week_ending: dayjs(timesheet.weekEnding, "DD/MM/YYYY").format("YYYY-MM-DD"),
          hours: value,
          comment: t.comment === undefined ? null : t.comment,
          staff_id: timesheet.user.staff_id,
          job_no: t.job !== undefined ? t.job.split(" ")[0] : null,
          stage_id: t.stage !== undefined ? t.stage.split(" ")[0] : null,
          row_id: row,
          charge_out_rate: timesheet.user.charge_out_rate,
          is_temp: isTemp,
          submit_date: dayjs().format("YYYY-MM-DD"),
        });
      }
    }
  });

  // Format expenses
  timesheet.expenses.forEach((e, row) => {
    if (e.job !== undefined || e.code !== undefined) {
      newTimesheet.expenses.push({
        code: e.code !== undefined ? e.code : null,
        mileage: e.mileage !== undefined ? e.mileage : null,
        cost: e.cost !== undefined ? e.cost : 0,
        staff_id: timesheet.user.staff_id,
        is_temp: isTemp,
        date: e.date !== undefined ? e.date : null,
        job_no: e.job !== undefined ? e.job.split(" ")[0] : null,
        row_id: row,
        week_ending: dayjs(timesheet.weekEnding, "DD/MM/YYYY").format("YYYY-MM-DD"),
        receipts: e.receipts !== undefined ? e.receipts : null,
        comment: e.comment === undefined ? null : e.comment,
        submit_date: dayjs().format("YYYY-MM-DD"),
      });
    }
  });
  return newTimesheet;
};

// Save timesheet to database
export const saveTimesheet = (timesheet) => (dispatch) => {
  dispatch({ type: SAVE_TIMESHEET_PENDING });

  timesheet = formatTimesheet(timesheet, true);

  return apiHandler.endpoints.timerecords
    .create(timesheet)
    .then((res) => {
      return apiHandler.endpoints.expenses
        .create(timesheet)
        .then((res2) =>
          dispatch({ type: SAVE_TIMESHEET_SUCCESS, payload: res })
        )
        .catch((error) =>
          dispatch({ type: SAVE_TIMESHEET_FAILED, payload: error })
        );
    })
    .catch((error) =>
      dispatch({ type: SAVE_TIMESHEET_FAILED, payload: error })
    );
};

// Restore timesheet from database
export const restoreTimesheet = (params) => (dispatch) => {
  let week_ending = dayjs(params.week_ending, "DD/MM/YYYY").format("YYYY-MM-DD");

  let route = params.staff_id + "/" + week_ending;

  dispatch({ type: RESTORE_TIMESHEET_PENDING });
  apiHandler.endpoints.tempTimerecords
    .getOne({ id: route })
    .then((res) => {
      apiHandler.endpoints.expenses
        .getOne({ id: route })
        .then((res2) => {
          dispatch({
            type: RESTORE_TIMESHEET_SUCCESS,
            payload: {
              time: res.data,
              expenses: res2.data,
            },
          });
        })
        .catch((error) => {
          return dispatch({ type: RESTORE_TIMESHEET_FAILED, payload: error });
        });
    })
    .catch((error) =>
      dispatch({ type: RESTORE_TIMESHEET_FAILED, payload: error })
    );
};

// Submit timesheet to database
export const submitTimesheet = (timesheet) => (dispatch) => {
  dispatch({ type: SUBMIT_TIMESHEET_PENDING });

  timesheet = formatTimesheet(timesheet, false);

  return apiHandler.endpoints.timerecords
    .create(timesheet)
    .then((res) => {
      return apiHandler.endpoints.expenses
        .create(timesheet)
        .then((res2) =>
          dispatch({ type: SUBMIT_TIMESHEET_SUCCESS, payload: res })
        )
        .catch((error) =>
          dispatch({ type: SUBMIT_TIMESHEET_FAILED, payload: error })
        );
    })
    .catch((error) => {
      return dispatch({
        type: SUBMIT_TIMESHEET_FAILED,
        payload: error,
      });
    });
};

// Save time locally to redux
export const saveTime = (time) => (dispatch) => {
  return dispatch({
    type: SAVE_TIME,
    payload: time,
  });
};

// Save expenses locally to redux
export const saveExpenses = (expenses) => (dispatch) => {
  return dispatch({
    type: SAVE_EXPENSES,
    payload: expenses,
  });
};

// Restore a staff member's timesheet on the admin page
export const restoreAdminStaffTimesheet = (timesheet) => (dispatch) => {
  return dispatch({
    type: RESTORE_ADMIN_STAFF_TIMESHEET,
    payload: timesheet,
  });
};

// Reset timesheet on submission
export const resetTimesheet = () => (dispatch) => {
  return dispatch({
    type: RESET_TIMESHEET,
  });
};
