const INITIAL_SESSION_STATE = {
  authUser: null,
};

const sessionReducer = (state=INITIAL_SESSION_STATE, action) => {
  switch(action.type) {
    case 'AUTH_USER_SET' : {
      return { ...state, authUser: action.payload }
    }
    default:
    	return state;
  }
}

export default sessionReducer;
