import 'moment/locale/en-gb';
import './CADateDisplay.css'

import { ORDERED_DAYS, TIMESHEET_START_WEEK_ENDING } from '../../constants'

import { Calendar } from 'react-calendar-component';
import React from 'react';
import moment from 'moment';

import { getNextFriday } from "../../helpers";
import dayjs from 'dayjs';

let cx = require('classnames');

moment.updateLocale('en', {
  week: {
    dow: -1
  }
});

/**
 * Used on timesheets page to show overdue timesheets and timesheets completed.
 */
export default class CADateDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
    }
  }

  getDayState = (day) => {
    day = dayjs(day);
    let weeks = this.props.weeks;
    
    if (weeks && weeks.length) {
      let fullRange = moment.range(
        moment(TIMESHEET_START_WEEK_ENDING).subtract(6, 'days'),
        moment(weeks[weeks.length-1].weekEnding)
      );

      let isComplete = false;
      for (var i = 0; i < weeks.length; i++) {
        let week = weeks[i];

        let startDate = dayjs(week.weekEnding).subtract(6, 'day');
        let endDate = dayjs(week.weekEnding);

        if (week.complete) {
          if (day.isSame(startDate) || day.isSame(endDate) || (day.isAfter(startDate) && day.isBefore(endDate))) {
            isComplete = true;
            break;
          }
        }
      }

      let thisWeek = moment.range(
        moment().startOf('week'),
        moment().endOf('week').startOf('day')
      )

      if (isComplete) {
        return {
          className: 'Calendar-grid-item--complete-week',
          complete: isComplete
        }
      } else if (thisWeek.contains(day)) {
        return {
          className: 'Calendar-grid-item--current-week',
          complete: isComplete
        }
      } else if (fullRange.contains(day)) {
        return {
          className: 'Calendar-grid-item--incomplete-week',
          complete: isComplete
        }
      } else {
        return {
          className: null,
          complete: false
        }
      }
    } else {
      return {
        className: null,
        complete: false
      }
    }
  }

  isWeekend = (day) => {
    return day.weekday() >= 5;
  }

  render() {
    return (
      <div id="calendarWrapper">
        <div id="calendar">
          <Calendar
            onChangeMonth={date => this.setState({ date: date })}
            date={this.state.date}
            renderDay={({ day, classNames }) => {
              let { className, complete } = this.getDayState(day);
              return (
                <div
                  key={day.format()}
                  className={cx(
                    'Calendar-grid-item',
                    complete ? className : className ? className : null,
                    classNames
                  )}
                >
                  {day.format('D')}
                </div>
              );
            }}
            renderHeader={({ date, onPrevMonth, onNextMonth }) => (
              <div>
                <div className="Calendar-header">
                  <button onClick={onPrevMonth}>«</button>
                  <div className="Calendar-header-currentDate">
                    {date.format('MMMM YYYY')}
                  </div>
                  <button onClick={onNextMonth}>»</button>
                </div>
                <div className="Calendar-weekday">
                  { ORDERED_DAYS.map((day, key) => <div key={key} className={'Calendar-grid-item'}>{day}</div>) }
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}
