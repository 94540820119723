import "./AppWrapper.css";

import * as ROLES from "../../constants/roles";

import { Breadcrumb, Dropdown, Layout, Menu } from "antd";
import {
  ClockCircleOutlined,
  HomeOutlined,
  LogoutOutlined,
  TeamOutlined,
  UsergroupDeleteOutlined,
  MenuFoldOutlined,
  EditOutlined,
  ReadOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { Link, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { calculateTimesheetDateRange, formatJobs } from "../../helpers";

import BarLoader from "react-spinners/BarLoader";
import CAAvatar from "components/CAAvatar";
import { ROUTES } from "../../constants";
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import dashboardRoutes from "../../constants/dashboardRoutes";
import logoDark from "../../assets/logo-dark.png";
import logoLight from "../../assets/logo-light.png";
import { withAuthorization } from "../../components/Session";

const { Content, Footer, Sider, Header } = Layout;

class AppWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      contentMarginLeft: 220,
      path: props.location.pathname.split("/")[1],
      jobToSearch: null,
      searchWidth: 400,
      theme: "dark",
    };
  }

  toggleCollapse = () => {
    let collapsed = this.state.collapsed;
    let newContentMarginLeft = collapsed ? 220 : 0;
    this.setState({
      contentMarginLeft: newContentMarginLeft,
      collapsed: !collapsed,
    });
  };

  componentDidMount() {
    this.setState({ path: this.props.location.pathname.split("/")[1] });
    this.props.history.listen((location, _) => {
      this.setState({ path: location.pathname.split("/")[1] });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authUser !== this.props.authUser) {
      calculateTimesheetDateRange(this.props.weekEndings);
    }
  }

  toggleTheme = (darkOn) => {
    this.setState({
      theme: darkOn ? "dark" : "light",
    });
  };

  onJobChange = (val) => {
    this.setState({
      job: val,
    });
  };

  onJobSelect = (job) => {
    if (job) {
      let job_no = job.split(" ")[0];
      this.props.history.push(ROUTES.PROJECTS + "/" + job_no, {
        job_no: job_no,
        title: job.substr(job.indexOf(" ") + 1),
      });
    }
  };

  render() {
    let { authUser } = this.props;
    let photo = authUser && authUser.photo;
    let { theme } = this.state;

    theme = theme === "dark" ? "dark" : "light";

    let firstname = authUser ? authUser.firstname : "";
    let initials = authUser
      ? `${authUser.firstname[0]}${authUser.surname[0]}`
      : "";

    let splitted = this.props.location.pathname.split("/");
    let path = "/" + splitted[1];

    if (
      Object.values(ROUTES).indexOf(path) < 0 &&
      splitted[splitted.length - 1] !== "404"
    ) {
      return <Redirect to="/404" />;
    }

    let logoWrapper = (
      <div
        style={{
          borderBottom:
            theme === "light"
              ? "1px solid rgb(238,238,238)"
              : "1px solid rgb(60,60,60)",
          transition: "all 0.2s",
          backgroundColor: theme === "light" && "white",
        }}
      >
        <div
          style={{
            margin: "0 auto",
            alignItems: "center",
            display: "flex",
            height: 60,
            width: this.state.collapsed ? 40 : 84,
          }}
        >
          <img
            src={theme === "light" ? logoLight : logoDark}
            width="100%"
            alt="logo"
          />
        </div>
      </div>
    );

    if (!this.props.authUser) {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={logoLight}
            alt="logo"
            width="225"
            style={{ marginBottom: 25 }}
          />
          <BarLoader
            sizeUnit={"px"}
            width={225}
            height={6}
            color={"#123abc"}
            loading={true}
          />
        </div>
      );
    }

    let route = this.props.location.pathname.split("/")[1];
    let pathname = this.props.location.pathname;

    let id = "";
    if (["projects", "staff", "leavers"].includes(route)) {
      id = pathname.split("/")[2];
    }
    let displayId = "";
    if (
      ["staff", "leavers"].includes(route) &&
      id !== undefined &&
      this.props.staff.length
    ) {
      let staffMember = this.props.staff.find((s) => s.staff_id === id);
      displayId = `${staffMember.firstname} ${staffMember.surname}`;
    }

    const breadcrumbNameMap = {
      "/dashboard": "Dashboard",
      "/timesheets": "Timesheets",
      "/projects": "Projects",
      [`/projects/${id}`]: id,
      "/staff": "Staff",
      [`/staff/${id}`]: displayId,
      "/leavers": "Leavers",
      [`/leavers/${id}`]: displayId,
    };

    const { location } = this.props;
    const pathSnippets = location.pathname.split("/").filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      );
    });

    const homeCrumb = (
      <Breadcrumb.Item>
        <HomeOutlined />
      </Breadcrumb.Item>
    );
    const breadcrumbItems = [homeCrumb, ...extraBreadcrumbItems];

    return (
      <Layout
        style={{ minHeight: "100vh", marginBottom: -20 }}
        className="dashboard-wrapper"
        key={this.props.location}
      >
        <Sider
          breakpoint="xl"
          width="220"
          collapsedWidth="0"
          collapsible
          trigger={null}
          collapsed={this.state.collapsed}
          onBreakpoint={(broken) => {
            this.setState({
              contentMarginLeft: broken ? 0 : 220,
              collapsed: broken ? true : false,
            });
          }}
          onCollapse={(collapsed, type) => {
            this.setState({
              contentMarginLeft: collapsed ? 0 : 220,
              collapsed: true,
            });
          }}
          style={{
            backgroundColor: theme === "light" && "white",
            position: "fixed",
            top: 0,
            height: "100vh",
            boxShadow: "2px 0 6px rgba(0,21,41,.15)",
            zIndex: 3,
          }}
        >
          {theme === "light" && logoWrapper}
          <Menu
            theme={theme}
            mode="inline"
            selectedKeys={[this.state.path]}
          >
            {theme === "dark" && logoWrapper}
            <div style={{ margin: "0 auto" }}>
              <div
                className="user-details"
                style={{
                  padding: "18px 22px",
                  borderBottom:
                    theme === "light"
                      ? "1px solid rgb(238,238,238)"
                      : "1px solid rgb(60,60,60)",
                  display: "flex",
                }}
              >
                <CAAvatar
                  initials={initials}
                  avatarSrc={photo}
                  size={44} 
                />
                <div
                  className="user-right"
                  style={{ marginLeft: 12, position: "relative", top: 0 }}
                >
                  <p
                    style={{
                      fontWeight: theme === "dark" && "bold",
                      color:
                        theme === "light" ? "black" : "rgba(255,255,255,.8)",
                    }}
                  >
                    {firstname}
                  </p>
                  <p
                    style={{
                      color:
                        theme === "light" ? "#a1aab2" : "rgba(255,255,255,.7)",
                      marginTop: 18,
                      fontSize: "1em",
                    }}
                  >
                    {authUser && authUser.description}
                  </p>
                </div>
              </div>
            </div>
            <h4
              style={{
                color: "#98a6ad",
                marginBottom: 20,
                marginTop: 25,
                marginLeft: 15,
              }}
            >
              Menu
            </h4>
            {ROLES.isDirector(authUser) && (
              <Menu.Item key="dashboard" title={null}>
                <Link to={ROUTES.DASHBOARD}>
                  <DashboardOutlined />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
            )}
            {authUser && authUser.can_enter_timesheets && (
              <Menu.Item key="timesheets" title={null}>
                <Link to={ROUTES.TIMESHEETS}>
                  <ClockCircleOutlined />
                  <span>Timesheets</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="projects" title={null}>
              <Link to={ROUTES.PROJECTS}>
                <ReadOutlined />
                <span>Projects</span>
              </Link>
            </Menu.Item>
            {authUser &&
              (authUser.role === ROLES.ADUSER ||
                authUser.role === ROLES.SUPUSER ||
                authUser.role === ROLES.JBUSER) && (
                <div
                  style={{
                    borderTop:
                      theme === "light"
                        ? "1px solid rgb(238,238,238)"
                        : `1px solid rgb(60,60,60)`,
                  }}
                >
                  <h4
                    style={{
                      color: "#98a6ad",
                      marginBottom: 20,
                      marginTop: 25,
                      marginLeft: 15,
                    }}
                  >
                    Admin
                  </h4>
                </div>
              )}
            {authUser &&
              (authUser.role === ROLES.ADUSER ||
                authUser.role === ROLES.SUPUSER ||
                authUser.role === ROLES.JBUSER) && (
                <Menu.Item key="staff" title={null}>
                  <Link to={ROUTES.STAFF}>
                    <TeamOutlined />
                    <span>Staff</span>
                  </Link>
                </Menu.Item>
              )}
            {authUser &&
              (authUser.role === ROLES.ADUSER ||
                authUser.role === ROLES.SUPUSER ||
                authUser.role === ROLES.JBUSER) && (
                <Menu.Item key="leavers" title={null}>
                  <Link to={ROUTES.LEAVERS}>
                    <UsergroupDeleteOutlined />
                    <span>Leavers</span>
                  </Link>
                </Menu.Item>
              )}
          </Menu>
        </Sider>
        <Layout
          style={{
            marginLeft: this.state.contentMarginLeft,
            transition: "all 0.3s",
            backgroundColor: "rgb(237,245,249)",
          }}
        >
          <Header
            style={{
              background: "rgb(25,130,218)",
              boxShadow: "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
              color: "black",
              padding: 0,
              height: 60,
              top: 0,
              left: this.state.collapsed ? 0 : 220,
              right: 0,
              zIndex: 1,
              transition: "all 0.3s",
            }}
          >
            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggleCollapse,
              style: {
                display: "inline-block",
                color: "white",
                marginLeft: 15,
                marginRight: 5,
                position: "relative",
                transition: ".1s all linear",
              }
            })}
            <div
              style={{
                float: "right",
                height: 60,
                position: "relative",
                right: 30,
                color: "white",
              }}
            >
              <Dropdown
                overlay={
                  <Menu
                    style={{
                      padding: 0,
                      paddingBottom: 5,
                    }}
                  >
                    <div
                      style={{
                        padding: "10px 15px",
                        marginBottom: 5,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottom: "1px solid rgb(238,238,238)",
                      }}
                    >
                      Signed in as <strong>{firstname}</strong>
                    </div>
                    <Menu.Item key="1" onClick={this.props.firebase.doSignOut}>
                      <button className="sign-out-inline">
                        <LogoutOutlined />
                        <span style={{ position: "relative", left: 10 }}>
                          Sign Out
                        </span>
                      </button>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <button className="profile-icon">
                  <CAAvatar initials={initials} avatarSrc={photo} size={40} />
                </button>
              </Dropdown>
            </div>
          </Header>
          <Content
            key={this.props.location.pathname}
            style={{ margin: "22px 30px 50px 30px", zIndex: 0 }}
          >
            <div style={{ maxWidth: 1600, margin: "0 auto" }}>
              <Breadcrumb style={{ marginBottom: 20, marginTop: 0 }}>
                {breadcrumbItems}
              </Breadcrumb>
              <Switch>
                {dashboardRoutes.map((prop, key) => (
                  <Route
                    exact
                    path={prop.path}
                    key={key}
                    render={(props) => prop.component}
                  />
                ))}
              </Switch>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "left",
              backgroundColor: "white",
              boxShadow: "1 1px 2px 0.75px hsla(0,0%,53%,.3)",
              fontSize: "0.95em",
            }}
          >
            <div style={{ maxWidth: 1500, margin: "0 auto" }}>
              Developed by Harry Baines &copy; 2018-2023
              <p style={{ marginTop: 5, marginBottom: 0 }}>
                Fonts by{" "}
                <a
                  href="http://www.onlinewebfonts.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  oNline Web Fonts
                </a>
              </p>
            </div>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  jobOptions: formatJobs(state.jobs.jobs),
  staff: state.staff.staff,
  weekEndings: state.weekEndings.weekEndings,
});

const condition = (authUser) => !!authUser;

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withAuthorization(condition)
)(AppWrapper);
