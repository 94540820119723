import { DATE_FORMAT, DAYS, TIMESHEET_START_WEEK_ENDING } from "../constants";

import _ from "underscore";

import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";

// dayjs config
var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 6,
});
dayjs.extend(isoWeek);

export const userCanViewCORates = (authUser) => {
  return (
    authUser &&
    authUser.surname !== undefined &&
    authUser.surname.toLowerCase() === "parkinson"
  );
};

export const formatFee = (fee) => {
  fee = Number(fee);
  const formattedNumber =
    fee % 1 !== 0
      ? fee.toLocaleString("en-US", { minimumFractionDigits: 2 })
      : fee.toLocaleString("en-US");
  return `£${formattedNumber}`;
};

export const roundUpNearest10k = (val) => Math.ceil(val / 10000) * 10000;

export const formatJob = (job) => {
  return {
    title: `${job.job_no} ${job.title}`,
    agreed_fee: job.agreed_fee === null ? false : job.agreed_fee,
  };
};

export const formatJobs = (jobs) => jobs.map((job) => formatJob(job));

export const formatSector = (sectors, job) => {
  let sectorName = "";
  if (job && job.sector) {
    sectorName = sectors.find((sector) => sector.id === job.sector).name;
  }
  return sectorName;
};

export const extend = (obj, src) => {
  Object.keys(src).forEach(function(key) {
    obj[key] = src[key];
  });
  return obj;
};

export const roundQuarter = (num) =>
  Number((Math.round(num * 4) / 4).toFixed(2));

export const findNestedObj = (entireObj, keyToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (nestedKey, nestedValue) => {
    if (nestedKey === keyToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const formatTimerecords = (timerecords, weekEnding, jobs) => {
  let records = jobs.length
    ? timerecords
        .filter(
          (rec) => {
            return (
                dayjs(weekEnding, DATE_FORMAT).format(DATE_FORMAT) === dayjs(rec.week_ending).format(DATE_FORMAT)
            )
          }
        )
        .map((rec, i) => {
          let weekday = dayjs(rec.date)
            .format("ddd")
            .toLowerCase();
          let job =
            rec.job_no !== null
              ? jobs.filter((j) => j.job_no === rec.job_no)[0]
              : null;
          weekday =
            weekday === "sat" || weekday === "sun" ? "weekend" : weekday;

          let obj = {
            id: rec.row_id,
            [weekday]: Number(rec.hours),
            stage: `${rec.stage_id} ${rec.description}`,
            comment: rec.comment,
          };

          if (job !== null) {
            obj.job = `${job.job_no} ${job.title}`;
          }
          return obj;
        })
    : [
        {
          id: 0,
          tot: 0.0,
        },
      ];

  records.sort((a, b) => a.id - b.id);

  let recs = _.map(_.groupBy(records, "id"), (vals, _) => {
    let newObj = { tot: 0 };
    vals.map((val) => (newObj = extend(newObj, val)));

    Object.keys(newObj).forEach((key) => {
      if (DAYS.indexOf(key) >= 0) {
        newObj.tot += newObj[key];
      }
    });
    return newObj;
  });
  return recs;
};

export const appendFees = (recentJobs, jobs) =>
  recentJobs.map((recentJob) => {
    const jobFound = jobs.filter((job) => job.job_no === recentJob.job_no)[0];

    if (jobFound.length) {
      return {
        ...recentJob,
        agreed_fee: jobFound.agreed_fee,
      };
    } else {
      return {
        ...recentJob,
        agreed_fee: null,
      };
    }
  });

export const formatStages = (stages) =>
  stages
    .map((s) => ({
      label: `${s.stage_id} ${s.description}`,
      value: `${s.stage_id}`,
      category: s.chargeable ? "Chargeable" : "Non-Chargeable",
      color: s.color,
      bgColor: s.bg_color,
      textColor: s.text_color,
      borderColor: s.border_color,
    }))
    .sort((a, b) => {
      var aNum = parseInt(a.value);
      var bNum = parseInt(b.value);
      return aNum - bNum;
    });

export const sortStages = (stages) =>
  stages.sort((a, b) => {
    return parseInt(a.stage_id) - parseInt(b.stage_id);
  });

export const sortStageObjects = (stages) =>
  stages.sort((a, b) => {
    return parseInt(a.stage.stage_id) - parseInt(b.stage.stage_id);
  });

export const formatExpensesCodes = (codes) =>
  codes.map((c) => ({
    label: `${c.code}`,
    value: `${c.code}`,
    category: "Codes",
  }));

export const formatCategories = (categories) =>
  categories.map((c) => ({
    label: c,
    value: c,
    category: "Category",
  }));

// Use staff_id for value
export const formatProjectLeads = (projectLeads) =>
  projectLeads.map((pl) => ({
    label: `${pl.firstname.charAt(0)}. ${pl.surname}`,
    value: pl.staff_id,
    category: "Project Lead",
  }));

export const calculateRowTotals = (timerecords, saveTime) => {
  timerecords.forEach((record, i) => {
    let rowTotal = 0;
    Object.keys(record).forEach((key) => {
      if (DAYS.includes(key)) {
        rowTotal += Number(record[key]);
      }
    });
    timerecords[i].tot = Number(rowTotal.toFixed(2));
  });
  saveTime(timerecords);
  return timerecords;
};

export const getNextFriday = (date) => {
  const friday = 5;
  if (date === undefined) {
    date = dayjs();
  }
  // Get current weeks friday date
  if (date.isoWeekday() <= friday) {
    return date.isoWeekday(friday);
  } else {
    return date.add(1, "week").isoWeekday(friday);
  }
};

export const calculateTimesheetDateRange = (weekEndings) => {
  let thisWeek = getNextFriday(dayjs().startOf("day"));

  // Get completed week endings
  let completedWeeks = weekEndings.map((weekEnding) => {
    return dayjs(new Date(weekEnding.week_ending.replace(" ", "T"))).format(
      "DD/MM/YYYY"
    );
  });

  let weeksArray = [];

  let currentDate = dayjs(TIMESHEET_START_WEEK_ENDING);
  while (currentDate.isBefore(thisWeek) || currentDate.isSame(thisWeek)) {
    let isComplete =
      completedWeeks.length &&
      completedWeeks.includes(currentDate.format("DD/MM/YYYY"))
        ? true
        : false;
    weeksArray.push({
      weekEnding: currentDate,
      complete: isComplete,
    });
    currentDate = currentDate.add(1, "week");
  }

  let weeksBehind = weeksArray.filter(
    (week) => !week.complete && week.weekEnding.isBefore(dayjs())
  ).length;
  return {
    weeksArray,
    weeksBehind,
  };
};

export const getWeeksDiff = (from, to) => {
  return -dayjs(from)
    .startOf("day")
    .diff(to.startOf("day").subtract(1, "week"), "week");
};

export const getNextId = (rows) => {
  return _.max(rows, _.property("id")).id + 1;
};

export const isEmpty = (obj) => {
  return !obj || Object.keys(obj).length === 0;
};
