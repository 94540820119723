import React from 'react';
import Typing from 'react-typing-animation';
import './CATyper.css'
 
const CATyper = ({ text }) => {
  return (
    <Typing
      className="typist"
      speed={30}
    >
      {text}
    </Typing>
  );
}

export default CATyper