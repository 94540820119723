import '../CATimeTable/CATimeTable.css';
import './CAExpensesTable.css';

import { Checkbox, DatePicker, Empty, InputNumber } from 'antd';
import { DATE_FORMAT, TIMESHEET_EXPENSES_COLS } from '../../../constants';

import CAAutoComplete from 'components/CAAutoComplete';
import CACommentModal from 'components/CACommentModal';
import CAJobTag from 'components/CAJobTag';
import CASelect from 'components/CASelect';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { formatExpensesCodes } from '../../../helpers';

// dayjs.extend(customParseFormat);

class CAExpensesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobOpen: false,
      jobId: 0
    }
  }
  handleAdd = () => {
    let newRecords = this.props.timesheet.expenses;
    let newId = newRecords.length === 0 ? 1 : newRecords[newRecords.length-1].id + 1;
    newRecords.push({
      id: newId
    });
    this.props.saveExpenses(newRecords, false)
  }

  handleDelete = (row) => {
    const records = [...this.props.timesheet.expenses];
    if (records.length > 1) {
      let newRecords = records.filter(item => item.id !== row);
      this.props.saveExpenses(newRecords, false)
    }
  };

  onEditJob = (row, val) => {
    let records = this.props.timesheet.expenses;
    if (val === undefined) {
      delete records[row].job
    } else {
      let job = val.props.value;
      records[row].job = job;
    }
    this.props.saveExpenses(records, false);
  }

  onEditCode = (row, val) => {
    let records = this.props.timesheet.expenses;
    if (val === undefined) {
      delete records[row].code
    } else {
      records[row].code = val.value;
    }
    this.props.saveExpenses(records, false)
  }

  onEditDate = (row, val) => {
    let records = this.props.timesheet.expenses;
    records[row].date = val;
    this.props.saveExpenses(records, false)
  }

  onEditMileage = (row, val) => {
    let records = this.props.timesheet.expenses;

    if (Number(val) !== 0 && !isNaN(val)) {
      records[row].mileage = val;
      records[row].cost = this.props.authUser.mileage_rate * records[row].mileage;
    } else {
      delete records[row].mileage
      delete records[row].cost
    }

    this.props.saveExpenses(records, false)
  }

  onEditCost = (row, val) => {
    let records = this.props.timesheet.expenses;
    
    if (Number(val) !== 0 && !isNaN(val)) {
      records[row].cost = val;
      delete records[row].mileage
    } else {
      delete records[row].mileage
      delete records[row].cost
    }

    this.props.saveExpenses(records, false)
  }

  onEditReceipts = (row, e) => {
    let records = this.props.timesheet.expenses;
    records[row].receipts = e.target.checked;
    this.props.saveExpenses(records, false);
  }

  onSaveComment = (row, val) => {
    let records = this.props.timesheet.expenses;
    records[row].comment = val
    this.props.saveExpenses(records, false);
  }

  renderTableHeader() {
    return TIMESHEET_EXPENSES_COLS.map((item, index) => {
      let leftAlign = index < 2;
      return (
        <th 
          key={index} 
          style={{ 
            width: item.width, 
            textAlign: leftAlign && 'left', 
            paddingLeft: leftAlign ? 15 : 0,
            height: 50
          }}
        >
          { item.label === 'Comment' ? '' : item.label }
          {/* { item.label === 'Receipts' && (
            <span style={{ fontSize: '0.75em', position: 'relative', bottom: 5, left: 2 }}>
              (BETA)
            </span>
          )} */}
        </th>
      )
    })
  }

  renderTableData() {
    const { authUser, timesheet, jobOptions, expensesCodes, editable } = this.props;
    const { jobOpen, jobId } = this.state;

    const widths = TIMESHEET_EXPENSES_COLS.map(c => c.width)

    return timesheet.expenses.map((record, index) => {
      let { id, code, date, mileage, cost, receipts } = record

      if (mileage && mileage !== undefined) {
        cost = mileage * authUser.mileage_rate
      }
      let feeExistsForRecord = null

      let result = this.props.jobOptions.filter(opt => opt.title === record.job)
      if (result.length) {
        feeExistsForRecord = result[0].agreed_fee ? true : false
      }

      let formattedJob = record !== undefined && record.job !== undefined && (
        <span style={{ cursor: editable && 'pointer', fontSize: 13 }}>
          <CAJobTag
            job_no={record.job.substr(0, record.job.indexOf(' '))}
            agreed_fee={feeExistsForRecord}
          />
          <span>{record.job.substr(record.job.indexOf(' ')+1)}</span>
        </span>
      )

      let job = {
        title: record.job,
        agreed_fee: feeExistsForRecord
      }

      return (
        <tr key={id}>
          <td style={{ 
            minWidth: widths[0], 
            maxWidth: widths[0]+1 
          }}>
            { editable ? (
              <CAAutoComplete
                className="job-entry"
                size={"large"}   
                options={jobOptions}
                onSelect={(_, val) => this.onEditJob(index, val)}
                onBlur={() => this.setState({ jobOpen: false, jobId: id })}
                onFocus={() => this.setState({ jobOpen: true, jobId: id })}
                onChange={(id, val) => {
                  this.onEditJob(index, val)
                  this.setState({ jobOpen: false, jobId: id })
                }}
                onInputKeyDown={(e) => e.key === 'Enter' && this.setState({ jobOpen: false, jobId: id })}
                selectedJob={record.job !== undefined ? job : null}
                dropdownStyle={{ 
                  minWidth: widths[0], 
                  maxWidth: widths[0],
                  fontSize: 13.5
                }}       
                style={{
                  width: widths[0]-1,
                  zIndex: jobOpen && jobId === id && 2,
                  fontSize: 13,
                  opacity: jobOpen && jobId !== id ? 0.2 : 1,
                  transition: 'all .2s ease-in-out',
                  boxShadow: jobOpen && jobId === id && 'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px',
                }}
              />
            ) : (
              <span style={{ paddingLeft: 10, fontSize: 13 }}>
                { formattedJob }
              </span>
            )}
          </td>
          <td style={{ maxWidth: widths[1] }}>
            { editable ? (
              <CASelect
                options={expensesCodes}
                categories={['Codes']}
                value={code}
                onChange={(_, val) => this.onEditCode(index, val)}
                dropdownStyle={{ fontSize: 13 }}
                size={"large"}
                style={{
                  width: widths[1]-1,
                  fontSize: 13
                }}
                isJustText
              />
            ) : (
              code && (
                <span style={{ paddingLeft: 10 }}>
                  <span style={{ fontSize: 13 }}>
                    {code}
                  </span>
                </span>
              )
            )}
          </td>
          <td style={{ width: widths[2] }}>
            { editable ? (
              <DatePicker 
                format={DATE_FORMAT}
                onChange={(val) => this.onEditDate(index, val)}
                style={{ 
                  width: '100%'
                }}
                value={date === undefined || date === null ? null : dayjs(date)}
                placeholder={null}
              />
            ) : (
              <span style={{ marginLeft: 10, fontSize: 13 }}>
                {date !== null ? dayjs(date).format(DATE_FORMAT) : ''}
              </span>
            )}
          </td>
          <td 
            style={{ 
              width: widths[3], 
              opacity: jobOpen && jobId !== id ? 0.2 : 1,
              textAlign: 'center', 
              padding: 1
            }}
          >
            { editable ? (
              <InputNumber
                size="large"
                min={0}
                max={10000}
                precision={2}
                value={mileage}
                onChange={(val) => this.onEditMileage(index, val)}
                autoComplete="new-password"
                defaultValue={mileage}
                style={{
                  width: '100%',
                  borderRadius: 0,
                  border: 'none',
                  fontSize: 13
                }}
              />
            ) : (
              <span style={{ fontSize: 13 }}>
                { mileage !== undefined && Number(mileage).toFixed(2) }
              </span>
            )}
          </td>
          <td 
            style={{ 
              width: widths[4], 
              opacity: jobOpen && jobId !== id ? 0.2 : 1,
              textAlign: 'center', 
              padding: 1
            }}
          >
            { editable ? (
              <InputNumber
                size="large"
                min={0}
                max={10000}
                precision={2}
                value={cost !== undefined ? cost : null}
                onChange={(val) => this.onEditCost(index, val)}
                autoComplete="new-password"
                defaultValue={cost}
                style={{
                  width: '100%',
                  borderRadius: 0,
                  border: 'none',
                  fontSize: 13
                }}
              />
            ) : (
              <span style={{ fontSize: 13 }}>
                { cost !== undefined && Number(cost).toFixed(2) }
              </span>
            )}
          </td>
          {/* <td style={{ width: widths[4], maxWidth: widths[4], textAlign: 'center', fontWeight: 'bold' }}>
            { mileage > 0 ? Number(authUser.mileage_rate * mileage).toFixed(2) : 0.00 }
          </td> */}
          <td style={{ textAlign: 'center', width: widths[5] }}>
            <Checkbox
              checked={receipts}
              disabled={!editable}
              onChange={(val) => this.onEditReceipts(index, val)}
            />
          </td>
          <td style={{ width: widths[6], maxWidth: widths[6], textAlign: 'center', fontSize: '.9em', opacity: jobOpen && jobId !== id ? 0.2 : 1 }}>
            <CACommentModal
              editable={editable}
              row={index}
              onSaveComment={this.onSaveComment}
              comment={record.comment}
            />
          </td>
        </tr>
      )
    })
  }

  render() {
    const { timesheet, editable } = this.props;
    let isEmpty = timesheet === null || (timesheet !== null && timesheet.expenses === null) ? true : false

    return (
      <div className='ca-table-container'>
        { !isEmpty ? (
          <table className='editable-table'>
            <tbody>
              <tr>{this.renderTableHeader()}</tr>
              {this.renderTableData()}
              { editable && (
                <tr 
                  className="add-row"
                  onClick={this.handleAdd}     
                >
                  <td colspan="1" style={{ paddingLeft: 15 }}>
                    <PlusOutlined /> <span style={{ paddingLeft: 5 }}>New</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            imageStyle={{
              height: 80,
            }}
            description={
              <span>
                No expenses found for this week
              </span>
            }
          />
        )}
      </div>
    );
  }
}

CAExpensesTable.defaultProps = {
  timesheet: {
    expenses: [{
      id: 0
    }]
  }
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  weekEnding: state.weekEnding.weekEnding,
  expensesCodes: formatExpensesCodes(state.expensesCodes.codes)
});

export default connect(mapStateToProps, null)(CAExpensesTable);
