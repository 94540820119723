import React from "react";
import * as ROUTES from "./routes";

import Dashboard from "containers/Dashboard";
import Timesheets from "containers/Timesheets";
import JobBook from "containers/JobBook";
import Job from "containers/JobBook/Job";
import Staff from "containers/Admin/Staff";
import Employee from "containers/Admin/Staff/Employee";
import AdminData from "containers/Admin/AdminData";

const dashboardRoutes = [
  {
    path: ROUTES.DASHBOARD,
    name: "Dashboard",
    component: <Dashboard />,
  },
  {
    path: ROUTES.TIMESHEETS,
    name: "Timesheets",
    component: <Timesheets />,
  },
  {
    path: ROUTES.PROJECTS,
    name: "Job Book",
    component: <JobBook />,
  },
  {
    path: ROUTES.PROJECTS + "/:job_no",
    name: "Job Details",
    component: <Job />,
  },
  {
    path: ROUTES.STAFF,
    name: "Staff",
    component: <Staff heading={"Staff"} active={true} />,
  },
  {
    path: ROUTES.STAFF + "/:staff_id",
    name: "Admin Staff Details",
    component: <Employee />,
  },
  {
    path: ROUTES.LEAVERS,
    name: "Leavers",
    component: <Staff heading={"Leavers"} active={false} />,
  },
  {
    path: ROUTES.LEAVERS + "/:staff_id",
    name: "Leavers Details",
    component: <Employee />,
  },
  {
    path: ROUTES.ADMIN_DATA,
    name: "Data",
    component: <AdminData />,
  },
];

export default dashboardRoutes;
