import './CAMap.css';

import ReactMapboxGl, { Marker } from "react-mapbox-gl";

import React from 'react';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
});

const CAMap = ({ coords }) => {
  return (
    // eslint-disable-next-line
    <Map style="mapbox://styles/harryb0905/cjy5xnydc14gs1cli16klxg65"
      center={[coords.long, coords.lat]}
      zoom={[12]}
      animate={false}
      containerStyle={{
        height: "1250px",
        width: "100%",
        boxShadow: '0px 2px 10px 1px rgba(220,220,220,.75)'
      }}>
      <Marker coordinates={[coords.long, coords.lat]} anchor="bottom">
        <div className="mapMarkerStyle" />
        {coords.lang}
      </Marker>
    </Map>
  )
}

export default CAMap
