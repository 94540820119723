import React from 'react';
import { Result, Button } from 'antd';
import { ROUTES } from '../../constants';

const NotFound = (props) => ( 
	<Result 
		status={'404'}
		title={'404'}
    subTitle={'Sorry, the page you visited does not exist.'}
    extra={
			<Button 
				type="primary" 
				onClick={() => props.history.push(ROUTES.LANDING)}
			>
				Back Home
			</Button>
		}
	/>
)

export default NotFound