import Chart from "react-apexcharts";
import React from "react";
import { formatFee } from "helpers";

class CALineChart extends React.Component {
    constructor(props) {
        super(props);

        let maxValue = 25000;
        let yAnnot = maxValue + 0.2 * maxValue;
        let yMax = maxValue + 0.5 * maxValue;

        this.state = {
            options: {
                colors: ["#0D78F9", "#F44336"],
                chart: {
                    height: 350,
                    type: "line",
                    fontFamily: "Europa-Regular",
                    id: "areachart-2",
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                        },
                        autoSelected: "zoom",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                grid: {
                    padding: {
                        right: 30,
                        left: 20,
                    },
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                markers: {
                    size: 3,
                },
                xaxis: {
                    type: "datetime",
                    title: {
                        text: "Time",
                    },
                },
                yaxis: {
                    max: yMax,
                    min: 0,
                    title: {
                        text: "Book Figure (£)",
                    },
                },
            },
            series: [
                {
                    name: "Value",
                    data: [],
                },
            ],
        };
    }

    render() {
        let { options, series } = this.state;
        series[0].data = this.props.chartData.data;

        let maxValue = this.props.chartData.maxValue;

        const yAnnots = this.props.chartData.yAnnots;

        options = {
            ...options,
            ...{
                labels: this.props.chartData.labels,
                annotations: yAnnots && {
                    yaxis: yAnnots.map((annot) => {
                        return {
                            y: annot.value,
                            borderColor: annot.color,
                            label: {
                                borderWidth: 5,
                                borderColor: annot.color,
                                style: {
                                    color: "#fff",
                                    background: annot.color,
                                },
                                text: `${annot.label}: ${formatFee(
                                    annot.value
                                )}`,
                            },
                        };
                    }),
                },
                yaxis: {
                    max: maxValue,
                    min: 0,
                    tickAmount: 10,
                    labels: {
                        formatter: (val) => formatFee(Math.round(val)),
                    },
                },
            },
        };

        return (
            <div id="chart">
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height={this.props.height}
                />
            </div>
        );
    }
}

export default CALineChart;
