import {
  DAYS,
  INITIAL_TIMESHEET,
  NUM_EXPENSES_START_ROWS,
  NUM_TIMESHEET_START_ROWS
} from '../constants';
import {
  RESET_TIMESHEET,
  RESTORE_ADMIN_STAFF_TIMESHEET,
  RESTORE_TIMESHEET_SUCCESS,
  SAVE_EXPENSES,
  SAVE_TIME
} from 'actions/TimesheetActionTypes';

import {
  calculateRowTotals
} from '../helpers';
import dayjs from "dayjs";
import { saveTime } from 'actions/TimesheetActionCreator';

const timesheetReducer = (state=INITIAL_TIMESHEET, action={}) => {
  switch(action.type) {
    case SAVE_TIME:
      return { ...state, time: action.payload }
    case SAVE_EXPENSES:
      return { ...state, expenses: action.payload }
    case RESET_TIMESHEET:
      return {
        ...state,
        time: new Array(NUM_TIMESHEET_START_ROWS).fill().map((u,i) => ({ id: i, tot: 0.00 })),
        expenses: new Array(NUM_EXPENSES_START_ROWS).fill().map((u,i) => ({ id: i }))
      }
    case RESTORE_ADMIN_STAFF_TIMESHEET:
      let formattedTime = action.payload.time.map(t => {
        let obj = {
          ...t
        }
        if (Object.keys(t).indexOf('sat') >= 0) {
          obj.weekend = t['sat']
          delete obj.sat
        } else if (Object.keys(t).indexOf('sun') >= 0) {
          obj.weekend = t['sun']
          delete obj.sun
        }
        return obj
      })

      let formattedExpenses = action.payload.expenses.map(e => {
        let obj = {
          ...e
        }
        if (e.job_no !== null) {
          obj.job = `${e.job_no} ${e.title}`
          delete obj.job_no
          delete obj.title
        }
        return obj
      })

      action.payload.time = formattedTime
      action.payload.expenses = formattedExpenses
      return { ...state, adminStaffTimesheet: action.payload, initialAdminStaffTimesheet: action.payload }
    case RESTORE_TIMESHEET_SUCCESS:

      // Format timerecords
      let time = action.payload.time;
      let timerecords = [];

      if (time.length) {
        const unique = [...new Set(time.map(item => item.row_id))];
        unique.forEach(val => timerecords.push({ id: val }))

        time.forEach(rec => {
          let weekday = DAYS[dayjs(rec.date).day()]
          let id = timerecords.map(obj => obj.id).indexOf(rec.row_id)

          timerecords[id].id = rec.row_id

          if (rec.job_no !== null) {
            timerecords[id].job = `${rec.job_no} ${rec.title}`
          }

          if (rec.stage_id !== null) {
            timerecords[id].stage = `${rec.stage_id} ${rec.description}`
          }
          timerecords[id][weekday] = Number(rec.hours).toFixed(2);
          timerecords[id].comment = rec.comment
        })

        timerecords = calculateRowTotals(timerecords, saveTime);
        for (var i=timerecords.length; i < NUM_TIMESHEET_START_ROWS; i++) {
          timerecords.push({
            id: i,
            tot: 0.00
          })
        }
      } else {
        timerecords = new Array(NUM_TIMESHEET_START_ROWS).fill().map((u,i) => ({ id: i, tot: 0.00 }))
      }

      // Format expenses
      let expenses = action.payload.expenses;
      let expensesRecs = [];
      
      if (expenses.length) {
        expensesRecs = expenses.map((rec,id) => {
          let obj = {
            id: rec.row_id
          }

          if (rec.job_no !== null) {
            obj.job = `${rec.job_no} ${rec.title}`
          }

          if (rec.code !== null) {
            obj.code = rec.code
          }

          return {
            ...obj,
            comment: rec.comment,
            date: rec.date,
            mileage: rec.mileage,
            cost: rec.cost,
            receipts: rec.receipts
          }
        })

        for (i=expensesRecs.length; i < NUM_EXPENSES_START_ROWS; i++) {
          expensesRecs.push({
            id: i
          })
        }
      } else {
        expensesRecs = new Array(NUM_EXPENSES_START_ROWS).fill().map((u,i) => ({ id: i }))
      }

      return {
        ...state,
        time: timerecords,
        expenses: expensesRecs,
        initialTime: timerecords,
        initialExpenses: expensesRecs
      }

    default:
      return state
  }
}

export default timesheetReducer
