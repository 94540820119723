import React from 'react';
import { Tag } from 'antd';

const CAJobTag = ({ job_no, agreed_fee, style }) => 
  <Tag 
    color={agreed_fee ? 'green' : 'red'}
    style={{
      cursor: 'pointer',
      minWidth: 55,
      textAlign: 'center',
      ...style
    }}
  >
    <span style={{ fontWeight: 700 }}>{job_no}</span>
  </Tag>

export default CAJobTag;