import "./Jobs.css";

import * as JobsActionCreators from "../../actions/JobsActionCreator";

import { Button, Divider, Input, Spin, Table, message } from "antd";
import { DATE_FORMAT, ROUTES } from "../../constants";
import { formatJobs, sortStages, formatSector } from "../../helpers";
import { Heading1 } from "theme/globalStyle";

import CAAddJobModal from "./CAAddJobModal";
import CAJobTag from "components/CAJobTag";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React from "react";
import apiHandler from "../../api/api";
import { compose } from "recompose";
import { connect } from "react-redux";
import withAuthorization from "components/Session/withAuthorization";
import { withRouter } from "react-router-dom";
import dayjs from "dayjs";

const { Search } = Input;
class JobBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      searchText: "",
      addJobModalVisible: false,
      selectedRow: null,
      loading: false,
      dataSource: [],
      csvData: [],
      exporting: false,
      filename: null,
    };
    this.csvLink = React.createRef();
  }

  handleChange = (_, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      searchText: "",
    });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  canEditJobBook = (authUser) => {
    return authUser && authUser.can_edit_job_book;
  };

  handleJobSearch = (input) => {
    this.setState({
      searchText: input,
    });
  };

  handleRowClick = ({ job_no, title, agreed_fee }) => {
    this.props.history.push(ROUTES.PROJECTS + "/" + job_no, {
      job_no: job_no,
      title: title,
      agreed_fee: agreed_fee,
    });
  };

  componentDidMount() {
    this.handleRequestJobs();
  }

  getColumnSearchProps = (dataIndex, agreed_fee) => {
    return {
      render: (text, record) => {
        let title = text;
        if (dataIndex === "job") {
          title = record.title;
        } else if (dataIndex === "client" && record.client === null) {
          title = "";
        }
        // console.log(dataIndex, text, record, title, this.state.searchText)
        return (
          <span>
            {dataIndex === "job" && (
              <CAJobTag job_no={record.job_no} agreed_fee={record.agreed_fee} />
            )}
            {title}
            {/* <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={title}
            /> */}
          </span>
        );
      },
    };
  };

  handleRequestJobs = () => {
    this.setState({
      loading: true,
    });
    this.props.getJobs().then((res) => {
      const jobs = this.props.jobs.map((job, key) => {
        return {
          ...job,
          job: `${job.job_no} ${job.title}`,
          sector: formatSector(this.props.sectors, job),
          agreed_fee: job.agreed_fee === null ? false : true,
          key: key,
        };
      });
      this.setState({
        loading: false,
        addJobModalVisible: false,
        dataSource: jobs,
      });
    });
  };

  handleJobNumberRequest = () => {
    this.setState({
      addJobModalVisible: true,
    });
  };

  exportJobsToCSV = () => {
    this.setState({
      exporting: true,
    });
    let filename = `Job book, ${dayjs().format(DATE_FORMAT)}.csv`;
    apiHandler.endpoints.jobs
      .getAll()
      .then((res) => {
        let csvData = [
          [
            "Job No",
            "Title",
            "Client",
            "Location",
            "Sector",
            "Address",
            "Postcode",
            "Date Added",
          ],
        ];
        res.data.forEach((j) => {
          csvData.push([
            j.job_no,
            j.title,
            j.client,
            j.location,
            formatSector(this.props.sectors, j),
            j.address,
            j.postcode,
            j.date_added ? dayjs(j.date_added).format("DD/MM/YYYY") : "",
          ]);
        });
        this.setState({ csvData, filename, exporting: false }, () => {
          if (csvData.length !== 1) {
            this.csvLink.current.link.click();
            message.success(`Created ${filename}`);
          } else {
            message.info("No data to export");
          }
        });
      })
      .catch((err) => {
        message.error("Error exporting to CSV");
        this.setState({
          exporting: false,
        });
      });
  };

  render() {
    let { sortedInfo, addJobModalVisible, loading, filename, exporting } =
      this.state;

    const { authUser, staff, jobs, sectors, stages } = this.props;
    sortedInfo = sortedInfo || {};

    const columns = [
      {
        title: "Job Title",
        dataIndex: "job",
        key: "job",
        sortOrder: sortedInfo.columnKey === "job" && sortedInfo.order,
        sorter: (a, b) => {
          return (
            (a === null) - (b === null) ||
            +(a.title > b.title) ||
            -(a.title < b.title)
          );
        },
        ...this.getColumnSearchProps("job", "agreed_fee"),
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        width: 150,
        sortOrder: sortedInfo.columnKey === "location" && sortedInfo.order,
        sorter: (a, b) => {
          return (
            (a === null) - (b === null) ||
            +(a.location > b.location) ||
            -(a.location < b.location)
          );
        },
        ...this.getColumnSearchProps("location"),
      },
      {
        title: "Client",
        dataIndex: "client",
        key: "client",
        width: 200,
        sortOrder: sortedInfo.columnKey === "client" && sortedInfo.order,
        sorter: (a, b) => {
          return (
            (a === null) - (b === null) ||
            +(a.client > b.client) ||
            -(a.client < b.client)
          );
        },
        ...this.getColumnSearchProps("client"),
      },
      {
        title: "Sector",
        dataIndex: "sector",
        key: "sector",
        width: 200,
        sortOrder: sortedInfo.columnKey === "sector" && sortedInfo.order,
        sorter: (a, b) => {
          return (
            (a === null) - (b === null) ||
            +(a.sector > b.sector) ||
            -(a.sector < b.sector)
          );
        },
      },
      {
        title: "Date Added",
        dataIndex: "date_added",
        key: "date_added",
        width: 100,
      },
    ];

    const isLoading = jobs.length && !loading ? false : true;

    return (
      <div
        style={{ padding: 24, background: "#fff", minHeight: 360 }}
        className="gutter-box"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Heading1>Projects</Heading1>
          <Search
            allowClear
            size="large"
            placeholder="Search for project..."
            style={{ width: 600 }}
            onChange={(e) => {
              const searchText = e.target.value.trim();
              this.setState({
                searchText: searchText,
                dataSource: this.props.jobs.filter((job) =>
                  `${job.job_no} ${job.title} ${job.location} ${job.client}`
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                ),
              });
            }}
          />
          {this.canEditJobBook(authUser) ? (
            <div>
              <CAAddJobModal
                buttonTitle={"Request Job No"}
                modalTitle={"Request Job No"}
                style={{
                  textShadow: "1px 1px 1px rgba(0,0,0,.004)",
                  marginRight: 10,
                }}
                staff={staff}
                sectors={sectors}
                stages={stages}
                requestJobs={this.handleRequestJobs}
                isAddingJob={loading}
                visible={addJobModalVisible}
                nextNumber={(Number(jobs[0].job_no) + 1).toString()}
                authUser={authUser}
              />
              <Button
                key="export-btn"
                loading={exporting}
                onClick={this.exportJobsToCSV}
                icon={<DownloadOutlined />}
              >
                Export Jobs
              </Button>
            </div>
          ) : (
            <span></span>
          )}
        </div>
        <CSVLink
          data={this.state.csvData}
          className="hidden"
          filename={filename}
          target="_blank"
          ref={this.csvLink}
        />
        <Divider />
        <Spin tip="Loading projects..." spinning={isLoading}>
          <Table
            bordered
            columns={columns}
            dataSource={this.state.dataSource}
            onChange={this.handleChange}
            onRow={(row) => ({
              onClick: () => {
                this.handleRowClick(row);
              },
            })}
            size="middle"
            className="jobs-table"
            rowClassName="job-row"
            pagination={{
              pageSize: 20,
              showSizeChanger: false,
            }}
          />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  jobs: state.jobs.jobs,
  jobOptions: formatJobs(state.jobs.jobs),
  staff: state.staff.staff,
  stages: sortStages(state.stages.stages),
  sectors: state.sectors.sectors,
});

const mapDispatchToProps = {
  getJobs: JobsActionCreators.getJobs,
};

const authCondition = (authUser) => !!authUser;

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(JobBook));
