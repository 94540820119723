import {
  REQUEST_JOBS_PENDING,
  REQUEST_JOBS_SUCCESS,
  REQUEST_JOBS_FAILED
} from 'actions/JobsActionTypes';

const INITIAL_JOBS_STATE = {
  isPending: false,
  jobs: [],
  error: ''
}

const jobsReducer = (state=INITIAL_JOBS_STATE, action={}) => {
  switch(action.type) {
    case REQUEST_JOBS_PENDING:
      return { ...state, isPending: true }
    case REQUEST_JOBS_SUCCESS:
      return { ...state, jobs: action.payload, isPending: false }
    case REQUEST_JOBS_FAILED:
      return { ...state, error: action.payload, isPending: false }
    default:
      return state;
  }
}

export default jobsReducer
