import * as IMPORT_ROUTES from "./routes";

export const ROUTES = IMPORT_ROUTES;

// Titles and subtitle constants
export const APP_TITLE = "Cassidy + Ashton";
export const APP_SUBTITLE = "Time Management System";

export const LOCAL_URL = "http://localhost:5000/api";
export const REMOTE_URL = "https://www.ca-tms.com/api";
export const BASE_URL =
  process.env.NODE_ENV === "development" ? LOCAL_URL : REMOTE_URL;
export const HOLIDAYS_ENDPOINT = "https://www.gov.uk/bank-holidays.json";
export const DATE_FORMAT = "DD/MM/YYYY";

export const CA_COLOR = 'rgb(118,169, 195)';

// Week ending of the week before deploying (i.e. 1 week before timesheets will be monitored)
export const TIMESHEET_START_WEEK_ENDING = new Date("2019-07-05").setHours(
  0,
  0,
  0,
  0
);
export const DAYS = ["weekend", "mon", "tue", "wed", "thu", "fri"];
export const ORDERED_DAYS = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Timesheet, expenses, resource sheet and job constants
export const NUM_TIMESHEET_START_ROWS = 8;
export const NUM_EXPENSES_START_ROWS = 8;
export const NUM_RESOURCE_SHEET_START_ROWS = 8;

export const STAGE_CODE_CATEGORIES = ["Chargeable", "Non-Chargeable"];
export const JOB_COLUMNS = [
  "Job No",
  "Job Title",
  "Client",
  "Location",
  "Address",
  "In Charge",
  "Agreed Fee",
  "Postcode",
  "Date Added",
];
export const TIMESHEET_CSV_OPTS = [
  "Record ID",
  "Firstname",
  "Surname",
  "Job No",
  "Job Title",
  "Stage/Code",
  "Description",
  "Hours",
  "Date",
  "Week Ending",
  "Charge Out Rate",
  "Comment\n",
];
export const EXPENSES_CSV_OPTS = [
  "Record ID",
  "Firstname",
  "Surname",
  "Job No",
  "Job Title",
  "Date",
  "Week Ending",
  "Code",
  "Mileage",
  "Cost (£)",
  "Comment\n",
];
export const TIMESHEET_TIME_COLS = [
  "Job Title",
  "Stage/Code",
  "Weekend",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Total",
  "",
];
export const TIMESHEET_EXPENSES_COLS = [
  { label: "Job Title", width: 642 },
  { label: "Code", width: 262 },
  { label: "Date", width: 210 },
  { label: "Mileage", width: 120 },
  { label: "Cost (£)", width: 120 },
  { label: "Receipts", width: 112 },
  { label: "Comment", width: 63 },
];

export const REPORT_FORMAT_OPTIONS = [
  { label: "Months", value: "months" },
  { label: "Years", value: "years" },
];

export const INITIAL_TIMESHEET = {
  time: new Array(NUM_TIMESHEET_START_ROWS)
    .fill()
    .map((u, i) => ({ id: i, tot: 0.0 })),
  expenses: new Array(NUM_EXPENSES_START_ROWS)
    .fill()
    .map((u, i) => ({ id: i })),
};

// export const INITIAL_RESOURCE_SHEET = new Array(NUM_RESOURCE_SHEET_START_ROWS).fill().map((u,i) => ({ id: i }))
export const INITIAL_RESOURCE_SHEET = {
  resourceSheet: [
    {
      id: 0,
      job: "8443 The Mill, St Catherine's Hospice, Lostock Hall",
      category: "Retail",
      projectLead: { name: "A. Baines", staff_id: "iwehiehioweiwi" },
      "0": { days: 0.5, stage_id: "0-3" },
    },
    {
      id: 1,
      job: "9179 Hewlett Court, Bury",
      category: "Healthcare",
      projectLead: { name: "A. Baines", staff_id: "iwehiehioweiwi" },
      "0": { days: 4, stage_id: "10" },
      "1": { days: 1.5, stage_id: "4" },
    },
    {
      id: 2,
      job: "P6399 Holy Cross Catholic High School, Chorley",
      category: "Ecclesiastical",
      projectLead: { name: "P. Gornall", staff_id: "klsdklpskdposkdos" },
      "1": { days: 2.5, stage_id: "5" },
    },
    {
      id: 3,
      job: "10181 Conference Centre, Blackpool",
      category: "Commercial",
      projectLead: { name: "L. McBurney", staff_id: "pwoepwjkdnwwd" },
      "2": { days: 1.5, stage_id: "6-7" },
    },
    {
      id: 4,
      job: "9341 Blackpool Enterprize Zone",
      category: "Commercial",
      projectLead: { name: "L. McBurney", staff_id: "pwoepwjkdnwwd" },
      "2": { days: 1.5, stage_id: "9" },
    },
  ],
};

// Resource sheet constants
export const RESOURCE_SHEET_WEEK_SPAN = 4;

export const RESOURCE_SHEET_COL_INDS = [];
for (var i = 0; i < RESOURCE_SHEET_WEEK_SPAN; i++) {
  RESOURCE_SHEET_COL_INDS.push(i.toString());
}

export const RESOURCE_SHEET_CATEGORIES = [
  "Commercial",
  "Ecclesiastical",
  "Education",
  "Healthcare",
  "Leisure",
  "Military",
  "Residential",
  "Retail",
  "Other",
];

export const RESOURCE_SHEET_COLS = [
  { label: "Job Title", width: 575 },
  { label: "Project Director", width: 200 },
  { label: "Category", width: 200 },
];
