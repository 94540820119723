import React from 'react';
import axios from 'axios';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { requestTimesheetData } from '../../actions';
import { withFirebase } from '../Firebase';

/**
 * Higher-order component to provide authentication for users.
 */
const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        checkingAuth: true
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (authUser) {
            return this.props.firebase.auth.currentUser.getIdToken()
              .then(idToken => {
                axios.defaults.headers.common['Authorization'] = idToken;
                this.props.onSetAuthUser(authUser)
                this.setState({ checkingAuth: false })
              })
          } else {
            this.props.onSetAuthUser(null)
            this.setState({ checkingAuth: false })
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }
    
    render() {
      return !this.state.checkingAuth && <Component />
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch(requestTimesheetData(authUser, true))
  });

  return compose(
    connect(null, mapDispatchToProps),
    withFirebase
  )(WithAuthentication);
}

export default withAuthentication;