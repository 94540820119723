import apiHandler from '../api/api';
import _ from 'lodash';

import {
  REQUEST_PROJECT_LEADS_PENDING,
  REQUEST_PROJECT_LEADS_SUCCESS,
  REQUEST_PROJECT_LEADS_FAILED
} from './ProjectLeadsActionTypes';

// Get all project leads from db
export const getProjectLeads = () => dispatch => {
  dispatch({ type: REQUEST_PROJECT_LEADS_PENDING });
  return apiHandler.endpoints.projectLeads.getAll()
    .then(projectLeads => dispatch({ type: REQUEST_PROJECT_LEADS_SUCCESS, payload: _.orderBy(projectLeads.data, 'surname') }))
    .catch(error => dispatch({ type: REQUEST_PROJECT_LEADS_FAILED, payload: error }))
}
