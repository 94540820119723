import apiHandler from '../api/api';
import {
  REQUEST_SECTORS_PENDING,
  REQUEST_SECTORS_SUCCESS,
  REQUEST_SECTORS_FAILED
} from './SectorsActionTypes';

export const getSectors = () => dispatch => {
  dispatch({ type: REQUEST_SECTORS_PENDING });
  return apiHandler.endpoints.sectors.getAll()
    .then(sectors => dispatch({ type: REQUEST_SECTORS_SUCCESS, payload: sectors.data }))
    .catch(error => dispatch({ type: REQUEST_SECTORS_FAILED, payload: error }))
}
