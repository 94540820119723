import * as UserActionCreators from 'actions/UserActionCreator';

import { ROUTES } from '../../constants';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';

/**
 * Higher-order component to authorise a user.
 * If the user isn't authorised to visit a particular route
 * or they have signed out, they are redirected to the sign in page.
 */
const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (authUser) {
            this.props.getUser(authUser.uid)
              .then(user => {
                if (!condition(user.payload)) {
                  this.props.history.push(ROUTES.SIGN_IN);
                }
              })
          } else {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({
    authUser: state.session.authUser
  });

  const mapDispatchToProps = {
    getUser: UserActionCreators.getUser
  }

  return compose(
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps),
  )(withRouter(WithAuthorization));
}

export default withAuthorization;
