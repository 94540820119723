import {
  ADD_WEEK_ENDING
} from 'actions/WeekEndingsActionTypes';

const weekEndingReducer = (state={}, action={}) => {
  switch(action.type) {
    case ADD_WEEK_ENDING:
      return { ...state, weekEnding: action.payload }
    default:
      return state
  }
}

export default weekEndingReducer
