import {
	REQUEST_RECENT_JOBS_PENDING,
	REQUEST_RECENT_JOBS_SUCCESS,
	REQUEST_RECENT_JOBS_FAILED
} from 'actions/RecentJobsActionTypes';

const INITIAL_RECENT_JOBS_STATE = {
	isPending: false,
	recentJobs: [],
	error: ''
}

const recentJobsReducer = (state=INITIAL_RECENT_JOBS_STATE, action={}) => {
	switch(action.type) {
		case REQUEST_RECENT_JOBS_PENDING:
			return { ...state, isPending: true }
		case REQUEST_RECENT_JOBS_SUCCESS:
			return { ...state, recentJobs: action.payload, isPending: false }
		case REQUEST_RECENT_JOBS_FAILED:
			return { ...state, error: action.payload, isPending: false }
		default:
			return state;
	}
}

export default recentJobsReducer
