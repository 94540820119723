import React from "react";
import {
    Form,
    InputNumber,
    Popconfirm,
    Table,
    Typography,
    DatePicker,
    Button,
    Spin,
} from "antd";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../constants";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    disabledDates,
    ...restProps
}) => {
    const inputNode =
        inputType === "date" ? (
            <Form.Item
                name={dataIndex}
                style={{ margin: 0 }}
                rules={[
                    {
                        required: true,
                        message: `Please input ${title}!`,
                    },
                ]}
            >
                <DatePicker
                    format={DATE_FORMAT}
                    disabledDate={(current) => {
                        return (
                            disabledDates !== undefined &&
                            disabledDates.some((date) =>
                                dayjs(date).isSame(current, "day")
                            )
                        );
                    }}
                    cellRender={(current) => {
                        const style = {};
                        if (current.date() === 1) {
                            style.border = "1px solid #1890ff";
                            style.borderRadius = "50%";
                        }
                        return (
                            <div
                                className="ant-picker-cell-inner"
                                style={style}
                            >
                                {current.date()}
                            </div>
                        );
                    }}
                />
            </Form.Item>
        ) : (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `Please Input ${title}!`,
                    },
                ]}
            >
                <InputNumber
                    min={0}
                    max={100000000}
                    formatter={(value) =>
                        `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\£\s?|(,*)/g, "")}
                />
            </Form.Item>
        );
    return <td {...restProps}>{editing ? inputNode : children}</td>;
};

const RatesTable = ({ record, handleAdd, handleDelete, loading }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(record.rates);
    const [count, setCount] = useState(record.rates.length);
    const [disabledDates, setDisabledDates] = useState();
    const [editingKey, setEditingKey] = useState("");

    useEffect(() => {
        setData(record.rates);
        setCount(record.rates.length);
    }, [record.rates]);

    const isEditing = (record) => record.key === editingKey;

    const cancel = () => {
        setEditingKey("");
    };

    const handleAddRow = () => {
        if (form.getFieldValue('startDate') === '') {
            return;
        }
        form.setFieldsValue({
            startDate: "",
            rate: 0,
        });
        setCount(count + 1);
        const newData = {
            key: count,
            rate: 0,
            startDate: null,
        };
        const disabledDates = record.rates
            .filter((row) => row.key !== count)
            .map((row) => row.startDate);
        setDisabledDates(disabledDates);
        setData([newData, ...data]);
        setEditingKey(count);
    };

    const handleEditRow = (cellRecord) => {
        const { startDate, rate } = cellRecord;
        form.setFieldsValue({
            startDate: startDate ? startDate : "",
            rate: rate || 0,
        });
        const disabledDates = record.rates
            .filter((row) => row.startDate !== cellRecord.startDate)
            .map((row) => row.startDate);
        setDisabledDates(disabledDates);
        setEditingKey(cellRecord.key);
    };

    const handleDeleteRow = (key) => {
        let row = data[key];
        const staff_id = record.person.staff_id;
        const rate = {
            start_date: row.startDate,
            staff_id: staff_id,
        };
        const dataSource = [...data];
        setData(dataSource.filter((item) => item.key !== key));
        handleDelete(rate);
        setEditingKey("");
    };

    function getAdjacentDates(list, specifiedDate) {
        const beforeDateObj = list
            .filter((obj) =>
                dayjs(obj.startDate).isBefore(dayjs(specifiedDate), "day")
            )
            .sort((a, b) => dayjs(b.startDate) - dayjs(a.startDate))[0];
        const afterDateObj = list
            .filter((obj) =>
                dayjs(obj.startDate).isAfter(dayjs(specifiedDate), "day")
            )
            .sort((a, b) => dayjs(a.startDate) - dayjs(b.startDate))[0];
        return {
            previous: beforeDateObj ? beforeDateObj : null,
            next: afterDateObj ? afterDateObj : null,
        };
    }

    const save = async () => {
        try {
            let dataSource = [...data];
            if (editingKey !== "") {
                dataSource = dataSource.filter(
                    (item) => item.key !== editingKey
                );
            }
            let row = await form.validateFields();
            const staff_id = record.person.staff_id;
            const adjacentDates = getAdjacentDates(dataSource, row.startDate);

            // Make end date of previous row equal new date - 1 day
            // Make end date of new row equal start date of next records start date - 1 day
            let rates = [];
            const newRate = {
                start_date: row.startDate,
                end_date: adjacentDates.next
                    ? adjacentDates.next.startDate.add(-1, "day").endOf("day")
                    : null,
                rate: row.rate,
                staff_id: staff_id,
            };
            rates.push(newRate);

            let prevRate = {
                start_date: null,
            };
            if (adjacentDates.previous) {
                prevRate = {
                    start_date: adjacentDates.previous.startDate,
                    end_date: row.startDate.add(-1, "day").endOf("day"),
                    rate: adjacentDates.previous.rate,
                    staff_id: staff_id,
                };
                rates.push(prevRate);
            }

            dataSource = dataSource.filter(
                (old) => old.startDate !== newRate.start_date
            );
            dataSource = dataSource.filter(
                (old) => old.startDate !== prevRate.start_date
            );

            let allRates = dataSource
                .map((rec) => {
                    return {
                        start_date: rec.startDate,
                        end_date: rec.endDate,
                        rate: rec.rate,
                        staff_id: staff_id,
                    };
                })
                .concat(rates)
                .sort((a, b) => b.start_date - a.start_date);

            // Ensure final date is null
            allRates[0].end_date = null;
            
            handleAdd(allRates);
            setEditingKey("");
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            title: "Start Date",
            dataIndex: "startDate",
            width: 200,
            editable: true,
            defaultSortOrder: "descend",
            sorter: (a, b) => {
                return a.startDate - b.startDate;
            },
            render: (_, record) => {
                const { startDate } = record;
                return startDate ? startDate.format(DATE_FORMAT) : "";
            },
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            width: 125,
            editable: true,
            render: (_, record) => {
                const { rate } = record;
                return `£${Number(rate).toFixed(2)}`;
            },
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={save}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Typography.Link
                            onClick={cancel}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Cancel
                        </Typography.Link>
                        {/* <Popconfirm
                            title="Sure to delete?"
                            onConfirm={() => handleDeleteRow(record.key)}
                        >
                            Delete
                        </Popconfirm> */}
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => handleEditRow(record)}
                    >
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (cellRecord) => {
                return {
                    cellRecord,
                    inputType:
                        col.dataIndex === "startDate" ? "date" : "number",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(cellRecord),
                };
            },
        };
    });

    return (
        <Form form={form} component={false}>
            <Button
                onClick={handleAddRow}
                type="primary"
                style={{
                    marginBottom: 12,
                }}
            >
                Add Rate
            </Button>
            <Table
                components={{
                    body: {
                        cell: (props) => (
                            <EditableCell
                                disabledDates={disabledDates}
                                {...props}
                            />
                        ),
                    },
                }}
                size="small"
                showSorterTooltip={false}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                loading={loading}
                pagination={false}
                style={{ minHeight: 250 }}
            />
        </Form>
    );
};
export default RatesTable;
