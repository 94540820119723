import {
  REQUEST_WEEK_ENDINGS_PENDING,
  REQUEST_WEEK_ENDINGS_SUCCESS,
  REQUEST_WEEK_ENDINGS_FAILED
} from 'actions/WeekEndingsActionTypes';

const INITIAL_WEEK_ENDINGS_STATE = {
  weekEndings: []
}

const weekEndingsReducer = (state=INITIAL_WEEK_ENDINGS_STATE, action={}) => {
  switch(action.type) {
    case REQUEST_WEEK_ENDINGS_PENDING:
      return { ...state }
    case REQUEST_WEEK_ENDINGS_SUCCESS:
      return { ...state, weekEndings: action.payload }
    case REQUEST_WEEK_ENDINGS_FAILED:
      return { ...state, error: action.payload }
    default:
      return state;
  }
}

export default weekEndingsReducer
