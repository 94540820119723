import "./Staff.css";

import * as ROLES from "../../../constants/roles";
import * as StaffActionCreators from "actions/StaffActionCreator";

import { Badge, message } from "antd";
import {
  DATE_FORMAT,
  EXPENSES_CSV_OPTS,
  TIMESHEET_CSV_OPTS,
} from "../../../constants";

import AddStaffMemberModal from "./AddStaffMemberModal"
import { CSVLink } from "react-csv";
import CSVModal from "./CSVModal";
import { Heading1 } from "theme/globalStyle";
import React from "react";
import StaffTable from "./StaffTable";
import apiHandler from "../../../api/api";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getNextFriday } from "../../../helpers";
import withAuthorization from "components/Session/withAuthorization";

import * as dayjs from 'dayjs'

class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffLoading: false,
      csvData: [],
      exporting: false,
      filename: null,
    };
    this.csvLink = React.createRef();
  }
  componentDidMount() {
    this.loadStaff()
  }

  loadStaff = () => {
    this.setState({
      staffLoading: true,
    });
    this.props
      .getStaff()
      .then((_) => {
        this.setState({
          staffLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          staffLoading: false,
        });
      });
  }

  exportTimeToCSV = (range) => {
    this.setState({
      exporting: true,
    });

    let start = range[0].format("MMMM DD, YYYY H:mm");
    let end = range[1].format("MMMM DD, YYYY H:mm");
    let filename = `Timerecords, ${range[0].format(
      DATE_FORMAT
    )} - ${range[1].format(DATE_FORMAT)}.csv`;

    // Get records by range and export to CSV
    apiHandler.endpoints.timerecordsByRange
      .getOne({ id: `${start}/${end}/${this.props.active}` })
      .then((res) => {
        let cols = TIMESHEET_CSV_OPTS.filter((arr) => arr);
        let csvData = [cols];

        res.data.forEach((r) => {
          csvData.push([
            r.id,
            r.firstname,
            r.surname,
            r.job_no,
            r.title,
            r.stage_id,
            r.description,
            r.hours,
            dayjs(r.date).format("DD/MM/YYYY"),
            dayjs(r.week_ending).format("DD/MM/YYYY"),
            r.charge_out_rate,
            r.comment ? r.comment : "",
          ]);
        });
        this.setState({ csvData, filename, exporting: false }, () => {
          if (csvData.length !== 1) {
            this.csvLink.current.link.click();
            message.success(`Created ${filename}`);
          } else {
            message.info("No data to export");
          }
        });
      })
      .catch((err) => {
        message.error("Error exporting to CSV");
        this.setState({
          exporting: false,
        });
      });
  };

  exportExpensesToCSV = (range) => {
    this.setState({
      exporting: true,
    });

    let start = range[0].format("MMMM DD, YYYY H:mm");
    let end = range[1].format("MMMM DD, YYYY H:mm");
    let filename = `Expenses, ${range[0].format(
      DATE_FORMAT
    )} - ${range[1].format(DATE_FORMAT)}.csv`;

    // Get records by range and export to CSV
    apiHandler.endpoints.expensesByRange
      .getOne({ id: `${start}/${end}/${this.props.active}` })
      .then((res) => {
        let cols = EXPENSES_CSV_OPTS.filter((arr) => arr);
        let csvData = [cols];

        res.data.forEach((r) => {
          csvData.push([
            r.id,
            r.firstname,
            r.surname,
            r.job_no,
            r.title,
            r.date ? dayjs(r.date).format("DD/MM/YYYY") : "",
            dayjs(r.week_ending).format("DD/MM/YYYY"),
            r.code,
            r.mileage,
            r.cost,
            r.comment ? r.comment : "",
          ]);
        });
        this.setState({ csvData, filename, exporting: false }, () => {
          if (csvData.length !== 1) {
            this.csvLink.current.link.click();
            message.success(`Created ${filename}`);
          } else {
            message.info("No data to export");
          }
        });
      })
      .catch((err) => {
        message.error("Error exporting to CSV");
        this.setState({
          exporting: false,
        });
      });
  };

  render() {
    const { staff, active, authUser } = this.props;
    const { staffLoading, filename } = this.state;

    // Start with all eligble staff (i.e. active staff members who aren't admins or job book users)
    let filteredStaff = staff.filter(
      (s) =>
        s.description.toUpperCase() !== "ADMIN" &&
        s.description.toUpperCase() !== "JOB BOOK USER" &&
        s.active === active
    );

    // If you're an admin or a job book, you can see all timesheets
    const isAdmin = authUser.description.toUpperCase() === "ADMIN" || authUser.description.toUpperCase() === "JOB BOOK USER";

    // If you're a team leader, you can only see you team's timesheets
    // (if team members haven't been allocated yet but you are a team leader,
    // for now you can still see all timesheets)
    const isTeamLeader = authUser.team_leader;
    if (isTeamLeader && !isAdmin) {
      let teamMembers = filteredStaff.filter(s => s.team_leader_staff_id === authUser.staff_id);
      if (teamMembers.length) {
        filteredStaff = teamMembers;
      }
    }

    const data = filteredStaff
      .map((s, i) => {
        let recent = [];
        let firstWeek = s.start_week_ending
        let weeksBehind = Math.abs(dayjs(firstWeek).startOf('day').add(s.weeks, 'week').diff(getNextFriday().startOf('day'), "week"));
        
        let timesheetsDesc = <span> Up to date </span>;
        if (weeksBehind === 1) {
          timesheetsDesc = <span>1 week behind</span>;
        } else if (weeksBehind > 1) {
          timesheetsDesc = <span>{weeksBehind} weeks behind</span>;
        }

        if (Number(s.weeks) === 0) {
          recent.push(<span key={i}>No Timesheets Entered</span>);
          timesheetsDesc = <span key={i}>{weeksBehind} weeks behind</span>;
        } else {
          if (s.weeks < 8) {
            let weeks = weeksBehind > 8 ? 8 : weeksBehind;
            for (var j = 0; j < 8 - weeks; j++) {
              recent.push(<Badge key={j} status="success" />);
            }
            for (var k = 0; k < weeks; k++) {
              recent.push(<Badge key={k} status="error" />);
            }
          } else {
            if (weeksBehind === 1) {
              for (let j = 0; j < 8 - weeksBehind; j++) {
                recent.push(<Badge key={j} status="success" />);
              }
              recent.push(<Badge key={j} status="error" />);
            } else if (weeksBehind > 1) {
              let weeks = weeksBehind > 8 ? 8 : weeksBehind;
              for (j = 0; j < 8 - weeks; j++) {
                recent.push(<Badge key={j} status="success" />);
              }
              for (j = 0; j < weeks; j++) {
                recent.push(<Badge key={j} status="error" />);
              }
            } else {
              for (j = 0; j < 8; j++) {
                recent.push(<Badge key={j} status="success" />);
              }
            }
          }
        }
        return {
          key: i,
          person: {
            firstname: s.firstname,
            surname: s.surname,
            staff_id: s.staff_id,
            photo: s.photo,
            description: s.description,
            weeksBehind: weeksBehind,
            email: s.email,
          },
          rates: s.rates.map((row, i) => {
            return {
              key: i,
              rate: Number(row.rate),
              startDate: dayjs(row.start_date),
              endDate: dayjs(row.end_date),
            }
          }),
          rate: s.charge_out_rate,
          status: timesheetsDesc,
          recent: recent,
          teamLeader: s.team_leader,
        };
      });
    return (
      <div
        style={{ padding: 24, background: "#fff", minHeight: 360 }}
        className="gutter-box"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Heading1>{this.props.heading}</Heading1>
          <div>
            {(authUser && (authUser.role === ROLES.ADUSER || authUser.role === ROLES.JBUSER)) && (
              <AddStaffMemberModal 
                loadStaff={this.loadStaff}
                staff={staff}
              />
            )}
            <CSVModal
              title={"Export Time"}
              label={"Export time records submitted between weeks:"}
              export={this.exportTimeToCSV}
              exporting={this.state.exporting}
            />
            <CSVModal
              title={"Export Expenses"}
              label={"Export expenses submitted between weeks:"}
              export={this.exportExpensesToCSV}
              exporting={this.state.exporting}
            />
          </div>
        </div>
        <CSVLink
          data={this.state.csvData}
          className="hidden"
          filename={filename}
          target="_blank"
          ref={this.csvLink}
        />
        <StaffTable
          authUser={authUser}
          staff={data} 
          loading={staffLoading} 
          active={active} 
          loadStaff={this.loadStaff} 
          canMarkLeavers={(authUser && (authUser.role === ROLES.ADUSER || authUser.role === ROLES.JBUSER))}
        />
      </div>
    );
  }
}

const authCondition = (authUser) =>
  !!authUser &&
  (authUser.role === ROLES.ADUSER ||
    authUser.role === ROLES.SUPUSER ||
    authUser.role === ROLES.JBUSER);

const mapStateToProps = (state) => ({
  authUser: state.session.authUser,
  staff: state.staff.staff,
});

const mapDispatchToProps = {
  getStaff: StaffActionCreators.getStaff,
};

export default compose(
  withAuthorization(authCondition),
  connect(mapStateToProps, mapDispatchToProps)
)(Staff);
