import { Select, Tag } from 'antd';

import React from 'react';

const formatSelectOption = (opt, isJustText) => {
  return opt && opt.label ? {
    value: opt.label,
    label: (
      isJustText ? (
        <div key={opt.label} value={opt.value} style={{ fontSize: 12 }}>
          {opt.label}
        </div>
      ) : (
        <div key={opt.label} value={opt.value} style={{ fontSize: 12 }}>
          <Tag color={opt.color} style={{ cursor: 'pointer', minWidth: 36, textAlign: 'center' }}>
            <span style={{ fontWeight: 700 }}>
              {opt.label.substr(0, opt.label.indexOf(' '))} 
            </span>
          </Tag>
          {opt.label.substr(opt.label.indexOf(' ')+1)}
        </div>
      )
    )
  } : {}
}

const CASelect = ({
  label,
  placeholder,
  categories, 
  options,
  value,
  onChange,
  isJustText,
  ...rest
}) => {
  let selectOptions;
  if (categories) {
    selectOptions = categories.map((cat, _) => {
      return options
        .filter(opt => opt.category === cat)
        .map(opt => formatSelectOption(opt, isJustText))
    })
  } else {
    selectOptions = options.map(opt => formatSelectOption(opt))
  }
  const selectedOption = options.find(opt => opt.label === value);
  const selectedOptionFormatted = formatSelectOption({
    label: value,
    value: value,
    color: selectedOption && selectedOption.color 
  }, isJustText)

  return (
    <Select
      value={selectedOptionFormatted}
      options={selectOptions[0]}
      showArrow={false}
      allowClear={true}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  )
}

CASelect.defaultProps = {
  categories: null,
  isJustText: false
}

export default CASelect;
