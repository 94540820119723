import {
  REQUEST_USER_FAILED,
  REQUEST_USER_PENDING,
  REQUEST_USER_SUCCESS
} from './UserActionTypes';

import apiHandler from '../api/api';

// Get user from db
export const getUser = staff_id => dispatch => {
  dispatch({ type: REQUEST_USER_PENDING });
  return apiHandler.endpoints.staff.getOne({ id: staff_id })
    .then(user => dispatch({ type: REQUEST_USER_SUCCESS, payload: user.data }))
    .catch(error => dispatch({ type: REQUEST_USER_FAILED, payload: error }))
}
