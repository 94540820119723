import React, { useState } from "react";
import { Form, Input, Button, Typography, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import tmsLogo from "../../assets/logo-light.png";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ROUTES } from "../../constants";

const { Title } = Typography;

const CASignIn = ({ firebase, history }) => {
  const [resetPassword, setResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm(); // Get access to the form instance

  const onFinish = (values) => {
    if (resetPassword) {
      setIsLoading(true);
      firebase.doPasswordReset(values.email).then((res) => {
        notification.info({
          message: "Reset Email Sent",
          description: "Click the link in the email to reset your password",
        });
      });
      setIsLoading(false);
      setResetPassword(false);
    } else {
      setIsLoading(true);
      firebase
        .doSignInWithEmailAndPassword(values.email, values.password)
        .then((res) => history.push(ROUTES.DASHBOARD))
        .catch((err) => {
          notification.error({
            message: "Error Signing In",
            description:
              "Please check your email and password",
          });
        });
    }
    setIsLoading(false);
  };

  const handleResetPassword = () => {
    setResetPassword(true);
  };

  const handleBackToLogin = () => {
    setResetPassword(false);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Left Column - Login Form */}
      <div
        style={{
          width: "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {resetPassword ? (
          <div style={{ width: "100%", maxWidth: 450, margin: "0 75px" }}>
            <Title level={2} style={{ marginBottom: 32 }}>
              Reset Password
            </Title>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Email"
                  size="large"
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 32 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                  loading={isLoading}
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <a href="#" onClick={handleBackToLogin}>
                  Back to Login
                </a>
              </div>
            </Form>
          </div>
        ) : (
          <div style={{ width: "100%", maxWidth: 450, margin: "0 75px" }}>
            <Title level={2} style={{ marginBottom: 24 }}>
              Sign In
            </Title>
            <Form onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Email"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  size="large"
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 32 }}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  size="large"
                  loading={isLoading}
                  htmlType="submit"
                >
                  Sign In
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                Forgot your password?{" "}
                <a href="#" onClick={handleResetPassword}>
                  Reset Password
                </a>
              </div>
            </Form>
          </div>
        )}
      </div>

      {/* Right Column - App Logo */}
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
        }}
      >
        <img
          src={tmsLogo}
          className="logo-about"
          alt="C+A"
          style={{ width: 225 }}
        />
      </div>
    </div>
  );
};

const WrappedCASignIn = compose(withRouter, withFirebase)(CASignIn);

export default WrappedCASignIn;
