import {
  REQUEST_STAFF_PENDING,
  REQUEST_STAFF_SUCCESS,
  REQUEST_STAFF_FAILED,
} from "actions/StaffActionTypes";

const INITIAL_STAFF_STATE = {
  isPending: false,
  staff: [],
};

const staffReducer = (state = INITIAL_STAFF_STATE, action = {}) => {
  switch (action.type) {
    case REQUEST_STAFF_PENDING:
      return { ...state, isPending: true };
    case REQUEST_STAFF_SUCCESS:
      return { ...state, staff: action.payload, isPending: false };
    case REQUEST_STAFF_FAILED:
      return { ...state, error: action.payload, isPending: false };
    default:
      return state;
  }
};

export default staffReducer;
