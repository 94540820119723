import apiHandler from '../api/api';
import _ from 'lodash';

import {
  REQUEST_STAFF_PENDING,
  REQUEST_STAFF_SUCCESS,
  REQUEST_STAFF_FAILED
} from './StaffActionTypes';

// Get all staff names from db
export const getStaff = () => dispatch => {
  dispatch({ type: REQUEST_STAFF_PENDING });
  return apiHandler.endpoints.staff.getAll()
    .then(staff => dispatch({ type: REQUEST_STAFF_SUCCESS, payload: _.orderBy(staff.data, 'surname') }))
    .catch(error => dispatch({ type: REQUEST_STAFF_FAILED, payload: error }))
}
