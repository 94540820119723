import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal, message } from "antd";
import {
  DATE_FORMAT,
  TIMESHEET_START_WEEK_ENDING,
} from "../../../../constants";

import React from "react";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const ALL_TIME = [
  dayjs(TIMESHEET_START_WEEK_ENDING).subtract(6, 'day'),
  dayjs().day(5),
];

const RANGE_PRESETS = [
  {
    label: 'This Week',
    value: [dayjs().day(0).add(-1, 'day'), dayjs().day(5)],
  },
  {
    label: 'Last Week',
    value: [dayjs().startOf('week').subtract(1, 'week'), dayjs().endOf('week').subtract(1, 'week')],
  },
  {
    label: 'This Month',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    label: 'This Year',
    value: [dayjs().startOf('year'), dayjs().endOf('year')],
  },
  {
    label: 'All Time',
    value: ALL_TIME,
  },
];

message.config({
  top: 12,
  duration: 5,
  maxCount: 1,
});

class CSVModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      date: ALL_TIME,
      dateString: "",
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleRangeChange = (date, dateString) => {
    this.setState({
      date,
      dateString,
    });
  };

  render() {
    const { title, label } = this.props;
    return (
      <div style={{ display: "inline", marginRight: 10 }}>
        <Button onClick={this.showModal} icon={<DownloadOutlined />}>
          {title}
        </Button>
        <Modal
          title={title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel-btn" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              key="export-btn"
              loading={this.props.exporting}
              onClick={() => this.props.export(this.state.date)}
              style={{ marginTop: 10 }}
              icon={<DownloadOutlined />}
            >
              Export
            </Button>,
          ]}
        >
          <p>{label}</p>
          <RangePicker
            presets={RANGE_PRESETS}
            defaultValue={ALL_TIME}
            onChange={this.handleRangeChange}
            format={DATE_FORMAT}
            style={{ width: '100%' }}
          />

        </Modal>
      </div>
    );
  }
}

export default CSVModal;
